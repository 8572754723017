import {
  LoginResponse,
  loginResponseZod,
  UserInfo,
  userInfoZod,
} from '../model';
import { get, post } from './BaseApi';

export async function login(
  account: string,
  password: string
): Promise<LoginResponse> {
  return post('/api/user/login', loginResponseZod, { account, password });
}

export async function refreshToken(schoolId?: string): Promise<LoginResponse> {
  return post('/api/token/refresh', loginResponseZod, { schoolId });
}

export async function getUserInfo(): Promise<UserInfo> {
  return get('/api/user', userInfoZod);
}
