import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeatBirth, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
type PageData = StudentGradeNoSeatBirth & Nullable<Sight> & Nullable<WH> & { diag: string } & { overW: string };
function boolRender(v: unknown) {
    return v ? '是' : '否';
}
const manageIDMap: Record<string, string> = {
  '1': '長效散瞳劑\n(阿托平 Atropine)',
  '2': '短效散瞳劑',
  '3': '其他藥物',
  '4': '配鏡矯治',
  '5': '更換鏡片',
  '6': '遮眼治療',
  '7': '配戴隱形眼鏡',
  '8': '角膜塑型片',
  '9': '視力保健衛教',
  '10': '其他',
  '11': '定期檢查',
};

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '統編', property: 'pid' },
        { display: '學號', property: 'sid' },
        { display: '年級', property: 'grade' },
        { display: '班級', property: 'no' },
        { display: '座號', property: 'seat' },
        { display: '姓名', property: 'name' },
        { display: '性別', property: 'sex' },
        {
            display: '生日',
            property: 'birth',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : null;
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : "";
            },
        },
        {
            display: '年齡',
            property: 'birth',
            onRender: (v) => {
                return calculateAge(v);
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? calculateAge(v) : "";
            },
        },
        { display: '身高不足標準', property: 'fitHeight' },
        { display: '身高', property: 'height' },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDate',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : "";
            },
        },
        { display: '體位', property: 'diag' },
        { display: '建議體重', property: 'fitWeight' },
        { display: '過重量', property: 'overW' },
        {
            display: 'BMI',
            property: 'bmi',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return Math.round(value * 10) / 10; // 四捨五入到小數點後一位
                }
                return value;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return Math.round(value * 10) / 10; // 四捨五入到小數點後一位
                }
                return value as string;
            },
        },
        { display: '建議BMI', property: 'fitBMI' },
        {
            display: '散瞳治療',
            property: 'isDilated',
            onRender: boolRender,
            onSheetRender: boolRender,
        },
        {
            display: '裸視右',
            property: 'sight0R',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '裸視左',
            property: 'sight0L',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '戴鏡右',
            property: 'sightR',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '戴鏡左',
            property: 'sightL',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return value % 1 === 0 ? value.toFixed(1) : value.toString();
                }
                return value as string;
            }
        },
        {
            display: '散瞳',
            property: 'isDilating',
            onRender: boolRender,
            onSheetRender: boolRender,
        },
        { display: '近視右', property: 'eNearR' },
        { display: '近視左', property: 'eNearL' },
        { display: '遠視右', property: 'eFarR' },
        { display: '遠視左', property: 'eFarL' },
        { display: '散光右', property: 'eSanR' },
        { display: '散光左', property: 'eSanL' },
        { display: '屈光右', property: 'diopR' },
        { display: '屈光左', property: 'diopL' },
        { display: '診斷', property: 'diag' },
        { display: '備註', property: 'eSight99State' },
        {
            display: '處置代號',
            property: 'manageID',
            onRender: (v) => (Array.isArray(v) ? v.join(',') : v),
            onSheetRender: (v) => (Array.isArray(v) ? v.join(',') : (v as string)),
        },
        {
            display: '醫師建議處置',
            property: 'manageID',
            onRender: (v) => {
                if (Array.isArray(v)) {
                    return v.map(id => manageIDMap[id] || '').join(', ');
                }
                return '';
            },
            onSheetRender: (v) => {
                if (Array.isArray(v)) {
                    return v.map(id => manageIDMap[id] || '').join(', ');
                }
                return '';
            },
        },
        {
            display: '定期檢查', property: 'periodical',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : "";
            },
        },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const wHSchoolSight: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<PageData[]>([]);

    useEffect(() => {
        if (yearSem) {
            apis
                .getWHClassSight(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ sight, wh, ...s }) => ({
                            ...sight,
                            ...wh,
                            ...s,
                            diag: whbmiDiag(wh?.bmiCode?.toString()),
                            overW: (wh?.bmiCode == "1" || wh?.bmiCode == "2") ? ((wh?.weight ?? 0) - (s.fitWeight ?? 0)).toString() : ""
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);
    console.log(students)
    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!students.length}
                        variant="info"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_全校體位判讀清單`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            {/* <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                />
            </Row> */}
        </AuthedLayout>
    );
};
function calculateAge(birthDate: DateTime): number {
    // 將 DateTime 轉換為原生 Date 對象
    const birthDateObj = new Date(birthDate.year, birthDate.month - 1, birthDate.day);

    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    // 如果出生日期的月份還沒到或當前月份和出生月份相同但日期還沒到，年齡需要減一
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
        age--;
    }

    return age;
}

export const WHSchoolSight = connector(wHSchoolSight);