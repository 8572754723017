import React, { FunctionComponent } from 'react';
import { Field } from 'react-final-form';
import { DropdownCheckList } from '../DropdownCheckList';

type Props = {
  property: string;
  content: Record<string, string>;
  validate?: (v: string[]) => string[] | undefined;
  deserialize?: (v: string) => string[];
  serialize?: (v: string[]) => string;
  sort?: (v: string[]) => string[];
};
export const DropdownCheckListField: FunctionComponent<Props> = ({
  property,
  content,
  validate,
  deserialize = (v) => v.split(''),
  serialize = (v) => v.join(''),
  sort = (v) => v.sort(),
}) => {
  return (
    <Field
      type="string"
      name={property}
      validate={validate}
      formatOnBlur
      format={(v) => {
        if (typeof v === 'string') {
          return deserialize(v as string).filter((v) => !!v);
        }
        return v;
      }}
    >
      {({ input, meta }) => {
        const checked =
          typeof input.value === 'string'
            ? deserialize(input.value as string)
            : input.value || [];
        console.log('checked', checked);
        return (
          <>
            <DropdownCheckList
              {...input}
              content={content}
              deserialize={deserialize}
              serialize={serialize}
              sort={sort}
              checked={checked}
            />
            {meta.error &&
              (meta.modified || meta.submitFailed) &&
              !meta.active && <span className="text-danger">{meta.error}</span>}
          </>
        );
      }}
    </Field>
  );
};
