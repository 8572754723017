import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, Col, Row as BRow, Table } from 'react-bootstrap';
import { Cell, Row, StatisticProps, downloadBlob, downloadSheets, generateSheet } from '../../../utils';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
} from '../../../components';
import { useDownloadChart, useDownloadSheetChart, useSemGrade } from '../../../hook';
import { StudentInjury, StudentGradeNoSeat, InjuryPlaceEnum } from '../../../model';
import apis from '../../../apis';
import useApexBarChart, { ChartState } from '../../../hook/useApexBarChart';
import ExcelJS from 'exceljs';
import html2canvas from 'html2canvas';
import { I18N } from '../../../i18n-raw';
type PageData = StudentInjury & StudentGradeNoSeat;

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

export interface MonthData {
    sex: { [key: string]: number };
    moment: { [key: string]: number };
    place: { [key: string]: number };
    observationMins: number,
    studentInjury: {
        head: number;
        eye: number;
        mouth: number;
        face: number;
        nose: number;
        chest: number;
        belly: number;
        back: number;
        shoulder: number;
        arm: number;
        waist: number;
        leg: number;
        neck: number;
        buttock: number;
        perineum: number;
        friction: number;
        slash: number;
        press: number;
        strick: number;
        twist: number;
        burn: number;
        sting: number;
        fracture: number;
        old: number;
        aOther: number;
        fever: number;
        dizzy: number;
        puke: number;
        headache: number;
        toothache: number;
        stomachache: number;
        bellyache: number;
        diarrhoea: number;
        menses: number;
        pant: number;
        noseBlood: number;
        rash: number;
        eyeache: number;
        hOther: number;
        woundCare: number;
        ice: number;
        hot: number;
        rest: number;
        noteParent: number;
        backHome: number;
        hospital: number;
        education: number;
        mOther: number;
    };
}
interface SemesterData {
    [key: string]: MonthData;
    total: MonthData
}
const initializeMonthData = (): MonthData => ({
    sex: { male: 0, female: 0, total: 0 },
    moment: { "上午": 0, "中午": 0, "下午": 0 },
    place: {},
    observationMins: 0,
    studentInjury: {
        head: 0,
        eye: 0,
        mouth: 0,
        face: 0,
        nose: 0,
        chest: 0,
        belly: 0,
        back: 0,
        shoulder: 0,
        arm: 0,
        waist: 0,
        leg: 0,
        neck: 0,
        buttock: 0,
        perineum: 0,
        friction: 0,
        slash: 0,
        press: 0,
        strick: 0,
        twist: 0,
        burn: 0,
        sting: 0,
        fracture: 0,
        old: 0,
        aOther: 0,
        fever: 0,
        dizzy: 0,
        puke: 0,
        headache: 0,
        toothache: 0,
        stomachache: 0,
        bellyache: 0,
        diarrhoea: 0,
        menses: 0,
        pant: 0,
        noseBlood: 0,
        rash: 0,
        eyeache: 0,
        hOther: 0,
        woundCare: 0,
        ice: 0,
        hot: 0,
        rest: 0,
        noteParent: 0,
        backHome: 0,
        hospital: 0,
        education: 0,
        mOther: 0,
    }
});

const injuryAccStaticChart: FunctionComponent<Props> = ({
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<PageData[]>([]);
    const chartRef = useRef<HTMLDivElement>(null);
    const chartRef1 = useRef<HTMLDivElement>(null);
    const chartRef2 = useRef<HTMLDivElement>(null);
    const chartRef3 = useRef<HTMLDivElement>(null);
    const chartRef4 = useRef<HTMLDivElement>(null);
    const chartRef5 = useRef<HTMLDivElement>(null);
    const chartRef6 = useRef<HTMLDivElement>(null);
    const chartRef7 = useRef<HTMLDivElement>(null);
    const monthlyTotal: { male: { [key: string]: number }; female: { [key: string]: number }; total: { [key: string]: number } } = {
        male: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 },
        female: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 },
        total: { '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0, '8': 0, '9': 0, '10': 0, '11': 0, '12': 0 }
    };
    useEffect(() => {
        if (yearSem) {
            apis
                .getInjuryAccStaticChart(yearSem.year)
                .then((r) =>
                    setStudents(r.map(({ studentInjury, ...s }) => ({
                        ...studentInjury,
                        ...s
                    })))
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem]);




    const addMonthData = (target: MonthData, source: MonthData) => {
        // Add sex counts
        Object.keys(source.sex).forEach(key => {
            if (!target.sex[key]) {
                target.sex[key] = 0;
            }
            target.sex[key] += source.sex[key];
        });

        // Add studentInjury counts
        Object.keys(source.studentInjury).forEach(key => {
            target.studentInjury[key as keyof typeof source.studentInjury] += source.studentInjury[key as keyof typeof source.studentInjury];
        });

        // Add moment counts
        Object.keys(source.moment).forEach(key => {
            if (!target.moment[key]) {
                target.moment[key] = 0;
            }
            target.moment[key] += source.moment[key];
        });

        // Add place counts
        Object.keys(source.place).forEach(key => {
            if (!target.place[key]) {
                target.place[key] = 0;
            }
            target.place[key] += source.place[key];
        });

        // Add observation minutes
        target.observationMins += source.observationMins;
    };


    const organizeDataBySemester = (data: PageData[]): { semdata: { firstsem?: SemesterData; secondsem?: SemesterData }, total: { [key: string]: MonthData; } } => {
        const firstsem: SemesterData = { total: initializeMonthData() };
        const secondsem: SemesterData = { total: initializeMonthData() };
        const monthsData: { [key: string]: MonthData } = {
            '8': initializeMonthData(),
            '9': initializeMonthData(),
            '10': initializeMonthData(),
            '11': initializeMonthData(),
            '12': initializeMonthData(),
            '1': initializeMonthData(),
            '2': initializeMonthData(),
            '3': initializeMonthData(),
            '4': initializeMonthData(),
            '5': initializeMonthData(),
            '6': initializeMonthData(),
            '7': initializeMonthData(),
        };
        data.forEach(record => {
            if (record.date) {
                const date = new Date(String(record.date));
                const month = date.getMonth() + 1; // JavaScript months are 0-based, so we add 1
                const semesterKey = (month >= 8 || month <= 1) ? 'firstsem' : 'secondsem';
                const monthKey = `${month}`;

                const semester = semesterKey === 'firstsem' ? firstsem : secondsem;

                if (!semester[monthKey]) {
                    semester[monthKey] = initializeMonthData();
                }

                const monthData = semester[monthKey];
                const totalData = monthsData[monthKey];
                // Count sex occurrences
                if (!monthData.sex[record.sex]) {
                    monthData.sex[record.sex] = 0;
                }
                monthData.sex[record.sex]++;
                monthData.sex["total"]++;

                // Count sex occurrences
                if (!totalData.sex[record.sex]) {
                    totalData.sex[record.sex] = 0;
                }
                totalData.sex[record.sex == "1" ? "male" : "female"]++;
                totalData.sex["total"]++;

                if (record.sex === '1') {
                    monthlyTotal.male[monthKey]++;
                } else if (record.sex === '2') {
                    monthlyTotal.female[monthKey]++;
                }
                monthlyTotal.total[monthKey]++;

                // Count moment occurrences
                if (record.moment) {
                    if (!monthData.moment[record.moment]) {
                        monthData.moment[record.moment] = 0;
                    }
                    monthData.moment[record.moment]++;
                    if (!totalData.moment[record.moment]) {
                        totalData.moment[record.moment] = 0;
                    }
                    totalData.moment[record.moment]++;
                }

                // Count place occurrences
                const placeKey = I18N.InjuryPlace[record.placeEnum as InjuryPlaceEnum ?? "其他"];
                if (!monthData.place[placeKey]) {
                    monthData.place[placeKey] = 0;
                }
                monthData.place[placeKey]++;

                if (!totalData.place[placeKey]) {
                    totalData.place[placeKey] = 0;
                }
                totalData.place[placeKey]++;

                // Sum observation minutes
                monthData.observationMins += record.observationMins;
                totalData.observationMins += record.observationMins;

                // Count studentInjury properties
                Object.keys(monthData.studentInjury).forEach(key => {
                    const recordKey = key as keyof typeof record;
                    if (record[recordKey]) {
                        monthData.studentInjury[key as keyof typeof monthData.studentInjury]++;
                        totalData.studentInjury[key as keyof typeof monthData.studentInjury]++;
                    }
                });
            }
        });

        // Aggregate data for each semester
        Object.keys(firstsem).forEach(monthKey => {
            if (monthKey !== "total") {
                addMonthData(firstsem.total, firstsem[monthKey]);
            }
        });

        Object.keys(secondsem).forEach(monthKey => {
            if (monthKey !== "total") {
                addMonthData(secondsem.total, secondsem[monthKey]);
            }
        });

        const result: { semdata: { firstsem?: SemesterData; secondsem?: SemesterData }, total: { [key: string]: MonthData; } } = { semdata: {}, total: {} }
        if (Object.keys(firstsem).length > 1) {
            result.semdata.firstsem = firstsem;
        }
        if (Object.keys(secondsem).length > 1) {
            result.semdata.secondsem = secondsem;
        }
        result.total = monthsData;

        return result;
    };
    const organizedData = organizeDataBySemester(students);
    const customOrder = [8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7];
    const contentRows: Row[] = Object.values(organizedData.semdata).flatMap(
        (semdata, gidx, allArray) => {
            console.log(gidx)
            return InjuryToRows(
                gidx,
                semdata
            )
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '項/學期',
                    merge: { column: 1, row: 3 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '月份',
                    merge: { column: 1, row: 3 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '性別',
                    merge: { column: 3 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '時間',
                    merge: { column: 3 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '地點',
                    merge: { column: 11 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '部位',
                    merge: { column: 15 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '受傷種類',
                    merge: { column: 24 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '處理方式',
                    merge: { column: 9 },
                    style: { border: fullBorderStyle },
                }, {
                    value: '觀察時間．分',
                    merge: { column: 1, row: 3 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        {
            cells: [
                {
                    value: '合計',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '男',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '女',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '上午',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '中午',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '下午',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '運動場',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '遊戲器材',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '普通教室',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '專科教室',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '走廊',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '樓梯',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '地下室',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '體育館或活動中心',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '廁所',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '校外',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '其他',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '頭',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '頸',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '肩',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '胸',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '腹',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '背',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '眼',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '顏面',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '口腔',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '耳鼻喉',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '上肢',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '腰',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '下肢',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '臀部',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '會陰部',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '意外傷害',
                    merge: { column: 10, row: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '症狀',
                    merge: { column: 14, row: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '傷口護理',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '冰敷',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '熱敷',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '休息觀察',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '通知家長',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '家長帶回',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '校方送醫',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '衛生教育',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '其它處理',
                    merge: { column: 1, row: 2 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        {
            cells: [
                {
                    value: '擦傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '裂割刺傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '夾壓傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '挫撞傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '扭傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '灼燙傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '叮咬傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '骨折',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '舊傷',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '外科其它',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '發燒',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '暈眩',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '噁心嘔吐',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '頭痛',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '牙痛',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '胃痛',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '腹痛',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '腹瀉',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '經痛',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '氣喘',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '流鼻血',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '疹癢',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '眼疾',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '內科其它',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
    ];

    const Monthstate: ChartState = {
        options: {
            chart: {
                id: 'monthchart'
            },
            xaxis: {
                title: { text: "月份" },
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: '',
                data: Object.values(organizedData.total).map(x => x.sex.total)
            }],
        charttype: 'bar'
    }
    const Sexstate: ChartState = {
        options: {
            chart: {
                id: 'sexchart'
            },
            xaxis: {
                title: { text: "月份" },
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: '男',
                data: Object.values(organizedData.total).map(x => x.sex.male)
            },
            {
                name: '女',
                data: Object.values(organizedData.total).map(x => x.sex.female)
            }],
        charttype: 'bar'
    }
    const Placestate: ChartState = {
        options: {
            chart: {
                id: 'placechart'
            },
            xaxis: {
                title: { text: "受傷地點" },
                categories: ["運動場", "遊戲器材", "普通教室", "專科教室", "走廊", "樓梯", "地下室", "體育館或活動中心", "廁所", "校外", "其它"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [{
            name: "",
            data: [calculatePlaceTotals(organizedData.total, "運動場")
                , calculatePlaceTotals(organizedData.total, "遊戲器材")
                , calculatePlaceTotals(organizedData.total, "普通教室")
                , calculatePlaceTotals(organizedData.total, "專科教室")
                , calculatePlaceTotals(organizedData.total, "走廊")
                , calculatePlaceTotals(organizedData.total, "樓梯")
                , calculatePlaceTotals(organizedData.total, "地下室")
                , calculatePlaceTotals(organizedData.total, "體育館或活動中心")
                , calculatePlaceTotals(organizedData.total, "廁所")
                , calculatePlaceTotals(organizedData.total, "校外")
                , calculatePlaceTotals(organizedData.total, "其它")]
        }],
        charttype: 'bar'
    }
    const Injurystate: ChartState = {
        options: {
            chart: {
                id: 'injurychart'
            },
            xaxis: {
                title: { text: "意外傷害" },
                categories: ["擦傷", "裂割刺傷", "夾壓傷", "挫撞傷", "扭傷", "灼燙傷", "叮咬傷", "骨折", "舊傷", "外科其它"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [{
            name: "",
            data: [calculatePropertyTotal(organizedData.total, "friction")
                , calculatePropertyTotal(organizedData.total, "slash")
                , calculatePropertyTotal(organizedData.total, "press")
                , calculatePropertyTotal(organizedData.total, "strick")
                , calculatePropertyTotal(organizedData.total, "twist")
                , calculatePropertyTotal(organizedData.total, "burn")
                , calculatePropertyTotal(organizedData.total, "sting")
                , calculatePropertyTotal(organizedData.total, "fracture")
                , calculatePropertyTotal(organizedData.total, "old")
                , calculatePropertyTotal(organizedData.total, "aOther")
            ]
        }],
        charttype: 'bar'
    }
    const Hurtstate: ChartState = {
        options: {
            chart: {
                id: 'hurtchart'
            },
            xaxis: {
                title: { text: "症狀" },
                categories: ["發燒", "暈眩", "噁心嘔吐", "頭痛", "牙痛", "胃痛", "腹痛", "腹瀉", "經痛", "氣喘", "流鼻血", "疹癢", "眼疾", "內科其它"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [{
            name: "",
            data: [calculatePropertyTotal(organizedData.total, "fever")
                , calculatePropertyTotal(organizedData.total, "dizzy")
                , calculatePropertyTotal(organizedData.total, "puke")
                , calculatePropertyTotal(organizedData.total, "headache")
                , calculatePropertyTotal(organizedData.total, "toothache")
                , calculatePropertyTotal(organizedData.total, "stomachache")
                , calculatePropertyTotal(organizedData.total, "bellyache")
                , calculatePropertyTotal(organizedData.total, "diarrhoea")
                , calculatePropertyTotal(organizedData.total, "menses")
                , calculatePropertyTotal(organizedData.total, "pant")
                , calculatePropertyTotal(organizedData.total, "noseBlood")
                , calculatePropertyTotal(organizedData.total, "rash")
                , calculatePropertyTotal(organizedData.total, "eyeache")
                , calculatePropertyTotal(organizedData.total, "hOther")]
        }],
        charttype: 'bar'
    }
    const Treatmentstate: ChartState = {
        options: {
            chart: {
                id: 'treatmentchart'
            },
            xaxis: {
                title: { text: "處理方式" },
                categories: ["傷口護理", "冰敷", "熱敷", "休息觀察", "通知家長", "家長帶回", "校方送醫", "衛生教育", "其它處理"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [{
            name: "",
            data: [calculatePropertyTotal(organizedData.total, "woundCare")
                , calculatePropertyTotal(organizedData.total, "ice")
                , calculatePropertyTotal(organizedData.total, "hot")
                , calculatePropertyTotal(organizedData.total, "rest")
                , calculatePropertyTotal(organizedData.total, "noteParent")
                , calculatePropertyTotal(organizedData.total, "backHome")
                , calculatePropertyTotal(organizedData.total, "hospital")
                , calculatePropertyTotal(organizedData.total, "education")
                , calculatePropertyTotal(organizedData.total, "mOther")]
        }],
        charttype: 'bar'
    }
    const Partstate: ChartState = {
        options: {
            chart: {
                id: 'partchart'
            },
            xaxis: {
                title: { text: "受傷部位" },
                categories: ["頭", "頸", "肩", "胸", "腹", "背", "眼", "顏面", "口腔", "耳鼻喉", "上肢", "腰", "下肢", "臀部", "會陰部"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [{
            name: "",
            data: [calculatePropertyTotal(organizedData.total, "head")
                , calculatePropertyTotal(organizedData.total, "neck")
                , calculatePropertyTotal(organizedData.total, "shoulder")
                , calculatePropertyTotal(organizedData.total, "chest")
                , calculatePropertyTotal(organizedData.total, "belly")
                , calculatePropertyTotal(organizedData.total, "back")
                , calculatePropertyTotal(organizedData.total, "eye")
                , calculatePropertyTotal(organizedData.total, "face")
                , calculatePropertyTotal(organizedData.total, "mouth")
                , calculatePropertyTotal(organizedData.total, "nose")
                , calculatePropertyTotal(organizedData.total, "arm")
                , calculatePropertyTotal(organizedData.total, "waist")
                , calculatePropertyTotal(organizedData.total, "leg")
                , calculatePropertyTotal(organizedData.total, "buttock")
                , calculatePropertyTotal(organizedData.total, "perineum")]
        }],
        charttype: 'bar'
    }
    const ObservationMinsstate: ChartState = {
        options: {
            chart: {
                id: 'observationmintchart'
            },
            xaxis: {
                title: { text: "月份" },
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            },
            yaxis: {
                title: { text: "時間(分鐘)" },
            },
        },
        series: [
            {
                name: '',
                data: Object.values(organizedData.total).map(x => x.observationMins)
            }],
        charttype: 'bar'
    }

    const tableworkSheet: Row[] = [
        {
            cells: [
                {
                    value: `${yearSem?.year}學年_學生傷病統計分析`,
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        ...content,
        {
            cells: [
                {
                    value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
    ]

    // const tableworkSheet = generateSheet(content, { alignment: cellAlignStyle });
    const chartRefs = [{ ref: "monthchart", sheetname: "學年統計圖" }, { ref: "sexchart", sheetname: "學年統計圖(分男女)" }, { ref: "partchart", sheetname: "受傷部位" }, { ref: "placechart", sheetname: "受傷地點" }, { ref: "injurychart", sheetname: "事故傷害" }, { ref: "hurtchart", sheetname: "症狀" }, { ref: "treatmentchart", sheetname: "處理方式" }, { ref: "observationminchart", sheetname: "觀察時間" }];
    const titles = [`${yearSem?.year}學年學生傷病統計分析`];
    const { element: downloadChart } = useDownloadSheetChart({ chartdata: Object.values(organizedData.total).flatMap(x => x.sex.total), chartRefs: chartRefs, titles: titles, tablesheets: tableworkSheet, tablesheetname: "學年報表" });

    const { element: monthbarchart } = useApexBarChart(Monthstate);
    const { element: sexchart } = useApexBarChart(Sexstate);
    const { element: partchart } = useApexBarChart(Partstate);
    const { element: placechart } = useApexBarChart(Placestate);
    const { element: injurychart } = useApexBarChart(Injurystate);
    const { element: hurtchart } = useApexBarChart(Hurtstate);
    const { element: treatmentchart } = useApexBarChart(Treatmentstate);
    const { element: observationminschart } = useApexBarChart(ObservationMinsstate);
    return (
        <AuthedLayout>
            <BRow className="justify-content-between">
                <Col xs={3} className="mr-3">
                    {semGradeElement}
                </Col>
                <Col xs={2} className="mr-2">
                    {downloadChart}
                </Col>
            </BRow>
            <hr />
            <BRow>
                <Col className="text-center">
                    {yearSem?.year}學年_學生傷病統計分析
                </Col>
            </BRow>
            <BRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BRow>
            <BRow className="justify-content-center">
                <div ref={chartRef}>
                    {monthbarchart}
                </div>
                <div ref={chartRef1}>
                    {sexchart}
                </div>
                <div ref={chartRef2}>
                    {partchart}
                </div>
                <div ref={chartRef3}>
                    {placechart}
                </div>
                <div ref={chartRef4}>
                    {injurychart}
                </div>
                <div ref={chartRef5}>
                    {hurtchart}
                </div>
                <div ref={chartRef6}>
                    {treatmentchart}
                </div>
                <div ref={chartRef7}>
                    {observationminschart}
                </div>
            </BRow>
        </AuthedLayout >
    );
};


function InjuryToRows(
    sem: number,
    semesterData: SemesterData,
): Row[] {
    console.log(sem)
    const monthOrder = sem == 0 ? [8, 9, 10, 11, 12, 1, 'total'] : [2, 3, 4, 5, 6, 7, 'total'];
    const semRows = monthOrder.map((month, idx) => {
    
        const monthdata = semesterData[month] || initializeMonthData();
        let returnrows;
        const sexRow = [{ value: monthdata.sex["total"], merge: {}, style: { border: { left: borderStyle } } }, { value: monthdata.sex["1"] ?? 0, merge: {}, style: { border: { left: borderStyle } } }, { value: monthdata.sex["2"] ?? 0, merge: {}, style: { border: { left: borderStyle } } }]
        const momentRow = Object.values(monthdata.moment).flatMap((momentdata, idx, allArray) => {
            return { value: momentdata, merge: {}, style: { border: { left: borderStyle } } }
        })
        const placeRow = [
            { value: monthdata.place["運動場"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["遊戲器材"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["普通教室"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["專科教室"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["走廊"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["樓梯"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["地下室"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["體育館或活動中心"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["廁所"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["校外"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.place["其它"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
        ]
        const injuryRow = [
            { value: monthdata.studentInjury["head"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["neck"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["shoulder"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["chest"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["belly"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["back"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["eye"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["face"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["mouth"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["nose"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["arm"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["waist"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["leg"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["buttock"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["perineum"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["friction"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["slash"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["press"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["strick"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["twist"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["burn"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["sting"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["fracture"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["old"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["aOther"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["fever"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["dizzy"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["puke"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["headache"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["toothache"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["stomachache"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["bellyache"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["diarrhoea"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["menses"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["pant"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["noseBlood"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["rash"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["eyeache"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["hOther"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["woundCare"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["ice"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["hot"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["rest"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["noteParent"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["backHome"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["hospital"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["education"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
            { value: monthdata.studentInjury["mOther"] ?? 0, merge: {}, style: { border: { left: borderStyle } } },
        ]
        if (idx == 0) {
            returnrows = {
                cells: [
                    {
                        value: [1, 7, 8, 9, 10, 11, 12].includes(Number(monthOrder[idx])) ? "上學期" : "下學期",
                        merge: { column: 1, row: 7 },
                        style: { border: { left: borderStyle } },
                    },
                    { value: monthOrder[idx], merge: {}, style: { border: { left: borderStyle } } },
                    ...sexRow,
                    ...momentRow,
                    ...placeRow,
                    ...injuryRow,
                    { value: monthdata.observationMins, merge: {}, style: { border: { left: borderStyle } } }
                ],
            }
        } else {
            returnrows = {
                cells: [
                    { value: monthOrder[idx] == "total" ? "合計" : Number(monthOrder[idx]), merge: {}, style: { border: { left: borderStyle } } },
                    ...sexRow,
                    ...momentRow,
                    ...placeRow,
                    ...injuryRow,
                    { value: monthdata.observationMins, merge: {}, style: { border: { left: borderStyle } } }
                ],
            }
        }

        return returnrows
    })

    return semRows
}


function calculatePropertyTotal(dataObject: { [key: string]: MonthData }, propertyName: keyof MonthData["studentInjury"]): number {
    return Object.values(dataObject).reduce((acc, obj) => {
        const value = obj.studentInjury[propertyName] || 0;
        return acc + Number(value);
    }, 0);
}
function calculatePlaceTotals(dataObject: { [key: string]: MonthData }, placeName: string): number {
    return Object.values(dataObject).reduce((acc, monthData) => {
        if (placeName in monthData.place) {
            return acc + monthData.place[placeName];
        }
        return acc;
    }, 0);
}



export const InjuryAccStaticChart = connector(injuryAccStaticChart);
