import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table, Col } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { StudentInjury, StudentGradeNoSeat } from '../../../model';
import { ChartState, useApexBarChart, useDownloadSheetChart, useMonth, useSemGrade } from '../../../hook';
import apis from '../../../apis';
import { I18N } from '../../../i18n-raw';
type PageData = StudentInjury & StudentGradeNoSeat;

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

interface CountData {
    male: number;
    female: number;
    total: number;
}

interface MonthData {
    "時間": { [key: string]: CountData };
    "地點": { [key: string]: CountData };
    "受傷部位": {
        head: CountData;
        eye: CountData;
        mouth: CountData;
        face: CountData;
        nose: CountData;
        chest: CountData;
        belly: CountData;
        back: CountData;
        shoulder: CountData;
        arm: CountData;
        waist: CountData;
        leg: CountData;
        neck: CountData;
        buttock: CountData;
        perineum: CountData;
    };
    "意外傷害": {
        friction: CountData;
        slash: CountData;
        press: CountData;
        strick: CountData;
        twist: CountData;
        burn: CountData;
        sting: CountData;
        fracture: CountData;
        old: CountData;
        aOther: CountData;
    };
    "症狀": {
        fever: CountData;
        dizzy: CountData;
        puke: CountData;
        headache: CountData;
        toothache: CountData;
        stomachache: CountData;
        bellyache: CountData;
        diarrhoea: CountData;
        menses: CountData;
        pant: CountData;
        noseBlood: CountData;
        rash: CountData;
        eyeache: CountData;
        hOther: CountData;
    };
    "處理方式": {
        woundCare: CountData;
        ice: CountData;
        hot: CountData;
        rest: CountData;
        noteParent: CountData;
        backHome: CountData;
        hospital: CountData;
        education: CountData;
        mOther: CountData;
    };
    "觀察時間-分": { time: CountData };
}
const injuryAccStaticMonthChart: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedmonth, element: element } = useMonth({
        yearSem,
    });
    const [datas, setStudents] = useState<PageData[]>([]);
    const chartRef2 = useRef<HTMLDivElement>(null);
    const chartRef3 = useRef<HTMLDivElement>(null);
    const chartRef4 = useRef<HTMLDivElement>(null);
    const chartRef5 = useRef<HTMLDivElement>(null);
    const chartRef6 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (yearSem && selectedmonth) {
            apis
                .getInjuryAccStaticMonthChart(yearSem.year, selectedmonth.id)
                .then((r) =>
                    setStudents(r.map(({ studentInjury, ...s }) => ({
                        ...studentInjury,
                        ...s
                    })))
                )
                .catch(catchErrorForModal);
        }
    }, [selectedmonth, yearSem?.year, yearSem?.sem]);
    const initializeCountData = (): CountData => ({
        male: 0,
        female: 0,
        total: 0
    });

    const initializeMonthData = (): MonthData => ({
        "時間": { "上午": initializeCountData(), "中午": initializeCountData(), "下午": initializeCountData() },
        "地點": {
            "運動場": initializeCountData(),
            "遊戲器材": initializeCountData(),
            "普通教室": initializeCountData(),
            "專科教室": initializeCountData(),
            "走廊": initializeCountData(),
            "樓梯": initializeCountData(),
            "地下室": initializeCountData(),
            "體育館或活動中心": initializeCountData(),
            "廁所": initializeCountData(),
            "校外": initializeCountData(),
            "其它": initializeCountData(),
        },
        "受傷部位": {
            head: initializeCountData(),
            eye: initializeCountData(),
            mouth: initializeCountData(),
            face: initializeCountData(),
            nose: initializeCountData(),
            chest: initializeCountData(),
            belly: initializeCountData(),
            back: initializeCountData(),
            shoulder: initializeCountData(),
            arm: initializeCountData(),
            waist: initializeCountData(),
            leg: initializeCountData(),
            neck: initializeCountData(),
            buttock: initializeCountData(),
            perineum: initializeCountData()
        },
        "意外傷害": {
            friction: initializeCountData(),
            slash: initializeCountData(),
            press: initializeCountData(),
            strick: initializeCountData(),
            twist: initializeCountData(),
            burn: initializeCountData(),
            sting: initializeCountData(),
            fracture: initializeCountData(),
            old: initializeCountData(),
            aOther: initializeCountData()
        },
        "症狀": {
            fever: initializeCountData(),
            dizzy: initializeCountData(),
            puke: initializeCountData(),
            headache: initializeCountData(),
            toothache: initializeCountData(),
            stomachache: initializeCountData(),
            bellyache: initializeCountData(),
            diarrhoea: initializeCountData(),
            menses: initializeCountData(),
            pant: initializeCountData(),
            noseBlood: initializeCountData(),
            rash: initializeCountData(),
            eyeache: initializeCountData(),
            hOther: initializeCountData()
        },
        "處理方式": {
            woundCare: initializeCountData(),
            ice: initializeCountData(),
            hot: initializeCountData(),
            rest: initializeCountData(),
            noteParent: initializeCountData(),
            backHome: initializeCountData(),
            hospital: initializeCountData(),
            education: initializeCountData(),
            mOther: initializeCountData()
        },
        "觀察時間-分": {
            time: initializeCountData(),
        }
    });

    function organizeDataBySex(data: PageData[]): MonthData {
        const monthData: MonthData = initializeMonthData();

        data.forEach(record => {
            const isMale = record.sex === '1';
            const sexKey = isMale ? 'male' : 'female';

            // Count moment occurrences
            if (record.moment) {
                const momentKey = record.moment;
                monthData["時間"][momentKey][sexKey]++;
                monthData["時間"][momentKey].total++;
            }

            // Count place occurrences
            const placeKey = record.place ?? "其他";
            if (!monthData["地點"][placeKey]) {
                monthData["地點"][placeKey] = initializeCountData();
            }
            monthData["地點"][placeKey][sexKey]++;
            monthData["地點"][placeKey].total++;

            // Sum observation minutes
            monthData["觀察時間-分"]["time"][sexKey] += record.observationMins;
            monthData["觀察時間-分"]["time"]["total"] += record.observationMins;
            // Function to count injuries for each category
            const countInjuries = (injuryCategory: keyof MonthData) => {
                const categoryData = monthData[injuryCategory];
                const injuryKeys = Object.keys(record) as (keyof MonthData['受傷部位'] | keyof MonthData['意外傷害'] | keyof MonthData['症狀'] | keyof MonthData['處理方式'])[];
                injuryKeys.forEach(key => {
                    if (Object.keys(categoryData).includes(key) && record[key]) {
                        categoryData[key as keyof typeof categoryData][sexKey]++;
                        categoryData[key as keyof typeof categoryData]["total"]++;
                    }
                });
            };

            countInjuries('受傷部位');
            countInjuries('意外傷害');
            countInjuries('症狀');
            countInjuries('處理方式');
        });

        return monthData;
    }
    const organizedData = organizeDataBySex(datas);


    const contentRows: Row[] = Object.values(organizedData).flatMap(
        (gs, idx, allArray) => {
            const itemrows = Object.values(gs).flatMap((g: any, id) => {
                return InjuryToRows(
                    Object.keys(organizedData)[idx],
                    Object.keys(I18N.Injury).includes(Object.keys(gs)[id]) ? I18N.Injury[Object.keys(gs)[id]] : Object.keys(gs)[id],
                    g,
                    id,
                    (Object.keys(gs).length)
                );
            });
            return itemrows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '性別',
                    merge: { column: 3 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '男',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '女',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '合計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
    ];

    const Placestate: ChartState = {
        options: {
            chart: {
                id: 'placechart'
            },
            xaxis: {
                title: { text: "受傷地點" },
                categories: ["運動場", "遊戲器材", "普通教室", "專科教室", "走廊", "樓梯", "地下室", "體育館或活動中心", "廁所", "校外", "其它"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [{
            name: "男",
            data: [organizedData["地點"]["運動場"]["male"]
                , organizedData["地點"]["遊戲器材"]["male"]
                , organizedData["地點"]["普通教室"]["male"]
                , organizedData["地點"]["專科教室"]["male"]
                , organizedData["地點"]["走廊"]["male"]
                , organizedData["地點"]["樓梯"]["male"]
                , organizedData["地點"]["地下室"]["male"]
                , organizedData["地點"]["體育館或活動中心"]["male"]
                , organizedData["地點"]["廁所"]["male"]
                , organizedData["地點"]["校外"]["male"]
                , organizedData["地點"]["其它"]["male"]]
        },
        {
            name: "女",
            data: [organizedData["地點"]["運動場"]["female"]
                , organizedData["地點"]["遊戲器材"]["female"]
                , organizedData["地點"]["普通教室"]["female"]
                , organizedData["地點"]["專科教室"]["female"]
                , organizedData["地點"]["走廊"]["female"]
                , organizedData["地點"]["樓梯"]["female"]
                , organizedData["地點"]["地下室"]["female"]
                , organizedData["地點"]["體育館或活動中心"]["female"]
                , organizedData["地點"]["廁所"]["female"]
                , organizedData["地點"]["校外"]["female"]
                , organizedData["地點"]["其它"]["female"]]
        }],
        charttype: 'bar'
    }
    const Partstate: ChartState = {
        options: {
            chart: {
                id: 'partchart'
            },
            xaxis: {
                title: { text: "受傷部位" },
                categories: ["頭", "頸", "肩", "胸", "腹", "背", "眼", "顏面", "口腔", "耳鼻喉", "上肢", "腰", "下肢", "臀部", "會陰部"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: "",
                data: [organizedData["受傷部位"]["head"]["male"]
                    , organizedData["受傷部位"]["neck"]["male"]
                    , organizedData["受傷部位"]["shoulder"]["male"]
                    , organizedData["受傷部位"]["chest"]["male"]
                    , organizedData["受傷部位"]["belly"]["male"]
                    , organizedData["受傷部位"]["back"]["male"]
                    , organizedData["受傷部位"]["eye"]["male"]
                    , organizedData["受傷部位"]["face"]["male"]
                    , organizedData["受傷部位"]["mouth"]["male"]
                    , organizedData["受傷部位"]["nose"]["male"]
                    , organizedData["受傷部位"]["arm"]["male"]
                    , organizedData["受傷部位"]["waist"]["male"]
                    , organizedData["受傷部位"]["leg"]["male"]
                    , organizedData["受傷部位"]["buttock"]["male"]
                    , organizedData["受傷部位"]["perineum"]["male"]]
            },
            {
                name: "女",
                data: [organizedData["受傷部位"]["head"]["female"]
                    , organizedData["受傷部位"]["neck"]["female"]
                    , organizedData["受傷部位"]["shoulder"]["female"]
                    , organizedData["受傷部位"]["chest"]["female"]
                    , organizedData["受傷部位"]["belly"]["female"]
                    , organizedData["受傷部位"]["back"]["female"]
                    , organizedData["受傷部位"]["eye"]["female"]
                    , organizedData["受傷部位"]["face"]["female"]
                    , organizedData["受傷部位"]["mouth"]["female"]
                    , organizedData["受傷部位"]["nose"]["female"]
                    , organizedData["受傷部位"]["arm"]["female"]
                    , organizedData["受傷部位"]["waist"]["female"]
                    , organizedData["受傷部位"]["leg"]["female"]
                    , organizedData["受傷部位"]["buttock"]["female"]
                    , organizedData["受傷部位"]["perineum"]["female"]]
            }],
        charttype: 'bar'
    }
    const Injurystate: ChartState = {
        options: {
            chart: {
                id: 'injurychart'
            },
            xaxis: {
                title: { text: "意外傷害" },
                categories: ["擦傷", "裂割刺傷", "夾壓傷", "挫撞傷", "扭傷", "灼燙傷", "叮咬傷", "骨折", "舊傷", "外科其它"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: "男",
                data: [
                    organizedData["意外傷害"]["friction"]["male"]
                    , organizedData["意外傷害"]["slash"]["male"]
                    , organizedData["意外傷害"]["press"]["male"]
                    , organizedData["意外傷害"]["strick"]["male"]
                    , organizedData["意外傷害"]["twist"]["male"]
                    , organizedData["意外傷害"]["burn"]["male"]
                    , organizedData["意外傷害"]["sting"]["male"]
                    , organizedData["意外傷害"]["fracture"]["male"]
                    , organizedData["意外傷害"]["old"]["male"]
                    , organizedData["意外傷害"]["aOther"]["male"]
                ]
            },
            {
                name: "女",
                data: [
                    organizedData["意外傷害"]["friction"]["female"]
                    , organizedData["意外傷害"]["slash"]["female"]
                    , organizedData["意外傷害"]["press"]["female"]
                    , organizedData["意外傷害"]["strick"]["female"]
                    , organizedData["意外傷害"]["twist"]["female"]
                    , organizedData["意外傷害"]["burn"]["female"]
                    , organizedData["意外傷害"]["sting"]["female"]
                    , organizedData["意外傷害"]["fracture"]["female"]
                    , organizedData["意外傷害"]["old"]["female"]
                    , organizedData["意外傷害"]["aOther"]["female"]
                ]
            }],
        charttype: 'bar'
    }
    const Hurtstate: ChartState = {
        options: {
            chart: {
                id: 'hurtchart'
            },
            xaxis: {
                title: { text: "症狀" },
                categories: ["發燒", "暈眩", "噁心嘔吐", "頭痛", "牙痛", "胃痛", "腹痛", "腹瀉", "經痛", "氣喘", "流鼻血", "疹癢", "眼疾", "內科其它"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: "男",
                data: [
                    organizedData["症狀"]["fever"]["male"]
                    , organizedData["症狀"]["dizzy"]["male"]
                    , organizedData["症狀"]["puke"]["male"]
                    , organizedData["症狀"]["headache"]["male"]
                    , organizedData["症狀"]["toothache"]["male"]
                    , organizedData["症狀"]["stomachache"]["male"]
                    , organizedData["症狀"]["bellyache"]["male"]
                    , organizedData["症狀"]["diarrhoea"]["male"]
                    , organizedData["症狀"]["menses"]["male"]
                    , organizedData["症狀"]["pant"]["male"]
                    , organizedData["症狀"]["noseBlood"]["male"]
                    , organizedData["症狀"]["rash"]["male"]
                    , organizedData["症狀"]["eyeache"]["male"]
                    , organizedData["症狀"]["hOther"]["male"]
                ]
            },
            {
                name: "女",
                data: [
                    organizedData["症狀"]["fever"]["female"]
                    , organizedData["症狀"]["dizzy"]["female"]
                    , organizedData["症狀"]["puke"]["female"]
                    , organizedData["症狀"]["headache"]["female"]
                    , organizedData["症狀"]["toothache"]["female"]
                    , organizedData["症狀"]["stomachache"]["female"]
                    , organizedData["症狀"]["bellyache"]["female"]
                    , organizedData["症狀"]["diarrhoea"]["female"]
                    , organizedData["症狀"]["menses"]["female"]
                    , organizedData["症狀"]["pant"]["female"]
                    , organizedData["症狀"]["noseBlood"]["female"]
                    , organizedData["症狀"]["rash"]["female"]
                    , organizedData["症狀"]["eyeache"]["female"]
                    , organizedData["症狀"]["hOther"]["female"]
                ]
            }],
        charttype: 'bar'
    }
    const Treatmentstate: ChartState = {
        options: {
            chart: {
                id: 'treamentchart'
            },
            xaxis: {
                title: { text: "處理方式" },
                categories: ["傷口護理", "冰敷", "熱敷", "休息觀察", "通知家長", "家長帶回", "校方送醫", "衛生教育", "其它處理"]
            },
            yaxis: {
                title: { text: "人數" },
            },
        },
        series: [
            {
                name: "男",
                data: [
                    organizedData["處理方式"]["woundCare"]["male"]
                    , organizedData["處理方式"]["ice"]["male"]
                    , organizedData["處理方式"]["hot"]["male"]
                    , organizedData["處理方式"]["rest"]["male"]
                    , organizedData["處理方式"]["noteParent"]["male"]
                    , organizedData["處理方式"]["backHome"]["male"]
                    , organizedData["處理方式"]["hospital"]["male"]
                    , organizedData["處理方式"]["education"]["male"]
                    , organizedData["處理方式"]["mOther"]["male"]
                ]
            },
            {
                name: "女",
                data: [
                    organizedData["處理方式"]["woundCare"]["female"]
                    , organizedData["處理方式"]["ice"]["female"]
                    , organizedData["處理方式"]["hot"]["female"]
                    , organizedData["處理方式"]["rest"]["female"]
                    , organizedData["處理方式"]["noteParent"]["female"]
                    , organizedData["處理方式"]["backHome"]["female"]
                    , organizedData["處理方式"]["hospital"]["female"]
                    , organizedData["處理方式"]["education"]["female"]
                    , organizedData["處理方式"]["mOther"]["female"]
                ]
            }],
        charttype: 'bar'
    }

    const tableworkSheet: Row[] = [
        {
            cells: [
                {
                    value: `${yearSem?.year}學年${selectedmonth?.name}學生傷病分析`,
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '性別',
                    merge: { column: 3 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '男',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '女',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
                {
                    value: '合計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows,
        {
            cells: [
                {
                    value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        }
    ];
    const { element: partchart } = useApexBarChart(Partstate);
    const { element: placechart } = useApexBarChart(Placestate);
    const { element: injurychart } = useApexBarChart(Injurystate);
    const { element: hurtchart } = useApexBarChart(Hurtstate);
    const { element: treatmentchart } = useApexBarChart(Treatmentstate);
    const titles = [`${yearSem?.year}學年${selectedmonth?.name}學生傷病分析`];
    const chartRefs = [{ ref: 'partchart', sheetname: "受傷部位" }, { ref: 'placechart', sheetname: "受傷地點" }, { ref: 'injurychart', sheetname: "意外傷害" }, { ref: "hurtchart", sheetname: "症狀" }, { ref: "treatmentchart", sheetname: "處理方式" }];
    const { element: downloadChart } = useDownloadSheetChart({ chartdata: [organizedData["時間"]["上午"]["total"], organizedData["時間"]["中午"]["total"], organizedData["時間"]["下午"]["total"]], chartRefs: chartRefs, titles: titles, tablesheets: tableworkSheet, tablesheetname: "學年報表" });
    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Col>
                    <BSRow>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={4} className="mr-4">
                            {element}
                        </Col>
                        <Col xs={4} className="mr-4">
                            {downloadChart}
                        </Col>
                    </BSRow>
                </Col>
                <div>

                </div>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
            <BSRow>
                <div ref={chartRef2} data-html2canvas-ignore>
                    {partchart}
                </div>
                <div ref={chartRef3} data-html2canvas-ignore>
                    {placechart}
                </div>
                <div ref={chartRef4} data-html2canvas-ignore>
                    {injurychart}
                </div>
                <div ref={chartRef5} data-html2canvas-ignore>
                    {hurtchart}
                </div>
                <div ref={chartRef6} data-html2canvas-ignore>
                    {treatmentchart}
                </div>
            </BSRow>
        </AuthedLayout>
    );
};

function InjuryToRows(
    type: string,
    item: string,
    g: { male: number, female: number, total: number },
    id: number,
    itemlength: number
): Row[] {
    const typeNo = [];

    if (id == 0 && type != "觀察時間-分" && type != "意外傷害" && type != "症狀") {
        typeNo.push({
            value: id == 0 ? type : undefined,
            merge: { row: itemlength, column: 1 },
            style: { border: fullBorderStyle },
        })
    }
    if (id == 0 && type == "觀察時間-分") {
        typeNo.push({
            value: id == 0 ? type : undefined,
            merge: { row: itemlength, column: 3 },
            style: { border: fullBorderStyle },
        })
    }
    if (type != "意外傷害" && type != "症狀" && type != "觀察時間-分") {
        typeNo.push({
            value: item,
            merge: { row: 1, column: 2 },
            style: { border: fullBorderStyle },
        });
    }
    if (type == "意外傷害" && id == 0) {
        typeNo.push({
            value: "受傷種類",
            merge: { row: 24, column: 1 },
            style: { border: fullBorderStyle },
        });
    }
    if ((type == "症狀" || type == "意外傷害") && id == 0) {
        typeNo.push(
            {
                value: type,
                merge: { row: itemlength, column: 1 },
                style: { border: fullBorderStyle },
            },
            {
                value: item,
                merge: { row: 1, column: 1 },
                style: { border: fullBorderStyle },
            });
    }
    if ((type == "意外傷害" || type == "症狀") && id > 0) {
        typeNo.push({
            value: item,
            merge: { row: 1, column: 1 },
            style: { border: fullBorderStyle },
        });
    }
    return [
        {
            cells: [
                ...typeNo,
                {
                    value: g.male ?? 0,
                    merge:{},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: g.female ?? 0,
                    merge:{},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: g.total,
                    merge:{},
                    style: { border: { right: borderStyle } },
                },

            ],
        },

    ];
}

export const InjuryAccStaticMonthChart = connector(injuryAccStaticMonthChart);
