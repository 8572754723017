import React, {
  ChangeEvent,
  FunctionComponent,
  ReactElement,
  useRef,
  useState,
} from 'react';
import { Button, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { InputDropdownGroup } from '.';
import { deserializeTeeSheet, serializeTeeSheet } from '../utils/tee';
import { useField } from 'react-final-form';
import {
  DefaultTeeContext,
  TEE_CONTEXT_MAP,
  TEE_CONTEXT_BABY_MAP,
  TEE_CONTEXT_SPECIAL_MAP,
  TEE_CONTEXT_SPECIAL_TEE,
} from '../utils/tee';

enum ToothPlace {
  topLeft = '1',
  topRight = '2',
  bottomRight = '3',
  bottomLeft = '4',
  childTopLeft = '5',
  childTopRight = '6',
  childBottomRight = '7',
  childBottomLeft = '8',
}
const childMaxTee = 5;
const teeFromTo = [1, 8];

type Props = {
  teeSheet: Record<string, string>;
  sheetMap?: Record<string, string>;
  babyMap?: Record<string, string>;
  specialMap?: Record<string, string>;
  specialTooth?: string[];
  onChange: (data: Record<string, string>) => void;
};
export const TeeSheet: FunctionComponent<Props> = ({
  teeSheet,
  sheetMap = DefaultTeeContext[TEE_CONTEXT_MAP],
  babyMap = DefaultTeeContext[TEE_CONTEXT_BABY_MAP],
  specialMap = DefaultTeeContext[TEE_CONTEXT_SPECIAL_MAP],
  specialTooth = DefaultTeeContext[TEE_CONTEXT_SPECIAL_TEE],
  onChange,
}) => {
  const topRow: ReactElement[] = [];
  const childTopRow: ReactElement[] = [];
  const childBottomRow: ReactElement[] = [];
  const bottomRow: ReactElement[] = [];

  const cleanSheet: Record<string, string> = {};
  function onToothChange(key: string, value: string) {
    if (
      sheetMap[value.toUpperCase()] ||
      specialMap[value.toUpperCase()] ||
      babyMap[value.toUpperCase()]
    ) {
      cleanSheet[key] = value;
    } else {
      delete cleanSheet[key];
    }
    onChange(cleanSheet);
  }
  for (let i = teeFromTo[0]; i <= teeFromTo[1]; i++) {
    let toothMap = specialTooth.some((t) => t === `${i}`)
      ? { ...sheetMap, ...specialMap }
      : { ...sheetMap };
    // add adult top teeth row
    // insert left tooth in the first place
    const boundCreation = createTooth.bind(null, (k: string, s: string) =>
      onToothChange(k, s.toUpperCase())
    );
    let toothKey = createToothKey(ToothPlace.topLeft, i);
    topRow.unshift(boundCreation(toothMap, toothKey, teeSheet[toothKey] || ''));
    if (teeSheet[toothKey]) {
      cleanSheet[toothKey] = teeSheet[toothKey];
    }
    // insert right tooth in the last place
    toothKey = createToothKey(ToothPlace.topRight, i);
    topRow.push(boundCreation(toothMap, toothKey, teeSheet[toothKey] || ''));
    if (teeSheet[toothKey]) {
      cleanSheet[toothKey] = teeSheet[toothKey];
    }
    if (i <= childMaxTee) {
      const boundChildCreation = createTooth.bind(
        null,
        (k: string, s: string) => onToothChange(k, s.toLowerCase())
      );
      toothMap = babyMap; // child teeth use babyMap
      const lowerToothMap = lowerKeyMap(babyMap);
      // add child top teeth row
      toothKey = createToothKey(ToothPlace.childTopLeft, i);
      childTopRow.unshift(
        boundChildCreation(lowerToothMap, toothKey, teeSheet[toothKey] || '')
      );
      if (teeSheet[toothKey]) {
        cleanSheet[toothKey] = teeSheet[toothKey];
      }
      toothKey = createToothKey(ToothPlace.childTopRight, i);
      childTopRow.push(
        boundChildCreation(lowerToothMap, toothKey, teeSheet[toothKey] || '')
      );
      if (teeSheet[toothKey]) {
        cleanSheet[toothKey] = teeSheet[toothKey];
      }
      // add child bottom teeth row
      toothKey = createToothKey(ToothPlace.childBottomLeft, i);
      childBottomRow.unshift(
        boundChildCreation(lowerToothMap, toothKey, teeSheet[toothKey] || '')
      );
      if (teeSheet[toothKey]) {
        cleanSheet[toothKey] = teeSheet[toothKey];
      }
      toothKey = createToothKey(ToothPlace.childBottomRight, i);
      childBottomRow.push(
        boundChildCreation(lowerToothMap, toothKey, teeSheet[toothKey] || '')
      );
      if (teeSheet[toothKey]) {
        cleanSheet[toothKey] = teeSheet[toothKey];
      }
    }
    // add adult bottom teeth row
    toothMap = specialTooth.some((t) => t === `${i}`)
      ? { ...sheetMap, ...specialMap }
      : { ...sheetMap };
    toothKey = createToothKey(ToothPlace.bottomLeft, i);
    bottomRow.unshift(
      boundCreation(toothMap, toothKey, teeSheet[toothKey] || '')
    );
    if (teeSheet[toothKey]) {
      cleanSheet[toothKey] = teeSheet[toothKey];
    }
    toothKey = createToothKey(ToothPlace.bottomRight, i);
    bottomRow.push(boundCreation(toothMap, toothKey, teeSheet[toothKey] || ''));
    if (teeSheet[toothKey]) {
      cleanSheet[toothKey] = teeSheet[toothKey];
    }
  }

  return (
    <React.Fragment>
      <Row className="justify-content-center">{topRow}</Row>
      <Row className="justify-content-center">{childTopRow}</Row>
      <Row className="justify-content-center">{childBottomRow}</Row>
      <Row className="justify-content-center">{bottomRow}</Row>
    </React.Fragment>
  );
};

type WithButtonProp = {
  property?: string;
  disabled?: boolean;
  // onValidChange: (v: boolean) => void;
  sheetMap?: Record<string, string>;
  babayMap?: Record<string, string>;
  specialMap?: Record<string, string>;
  specialTooth?: string[];
  // onChange: (data: Record<string, string>) => void;
};

export const TeeSheetWithButton: FunctionComponent<WithButtonProp> = ({
  property = 'tSheet',
  disabled,
  sheetMap = DefaultTeeContext[TEE_CONTEXT_MAP],
  babayMap = DefaultTeeContext[TEE_CONTEXT_BABY_MAP],
  specialMap = DefaultTeeContext[TEE_CONTEXT_SPECIAL_MAP],
  specialTooth = DefaultTeeContext[TEE_CONTEXT_SPECIAL_TEE],
}) => {
  const [show, setShow] = useState(false);
  const { input, meta } = useField(property, {
    validate: validate,
    // validate: validate2,
    formatOnBlur: true,
    format: (v) => {
      const d = typeof v === 'string' ? deserializeTeeSheet(v) : v || {};
      setTeeSheet(d);
      return d;
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const [teeSheet, setTeeSheet] = useState<Record<string, string>>(
    (typeof input.value === 'object' ? (input.value as any) : input.value) || {}
  );

  const [str, setStr] = useState<string>(
    typeof input.value === 'string'
      ? (input.value as any)
      : serializeTeeSheet(input.value)
  );

  function onHide(): void {
    setShow(false);
  }

  function internalOnChange(data: Record<string, string>): void {
    setTeeSheet(data);
  }

  function validate(dataStr: string | undefined): string | undefined {
    if (!dataStr) return undefined;
    const data =
      typeof dataStr === 'object' ? dataStr : deserializeTeeSheet(dataStr);
    console.log(data, dataStr);
    const keys = getTeeKeys();
    const values = Object.keys(sheetMap);
    const babayValues = Object.keys(babayMap);
    const specialValues = Object.keys(specialMap);
    const r = Object.entries(data).reduce((p, [k, v]) => {
      if (
        keys.indexOf(k) !== -1 &&
        (values.indexOf(v.toUpperCase()) !== -1 ||
          (specialTooth.some((s) => k.endsWith(s)) &&
            specialValues.indexOf(v.toUpperCase()) !== -1) ||
          (k >= '51' &&
            k <= '85' &&
            babayValues.indexOf(v.toUpperCase()) !== -1))
      ) {
        return p;
      }
      p.push(`${k}${v}`);
      return p;
    }, [] as string[]);
    return r.length ? `${r.join(',')}項資料錯誤` : undefined;
  }

  return (
    <>
      <FormControl
        className="d-none"
        ref={inputRef}
        {...input}
        value={
          typeof input.value === 'object'
            ? serializeTeeSheet(input.value)
            : typeof input.value === 'string'
            ? (input.value as string)
                .replaceAll('\uFF0C', ',')
                .replaceAll(',,', ',')
            : input.value || ''
        }
      />
      <InputGroup>
        <FormControl
          value={str}
          // ref={inputRef}
          type="string"
          disabled={disabled}
          onFocus={input.onFocus}
          onChange={(e) => {
            setStr(e.target.value);
            if (inputRef.current) {
              inputRef.current.value = e.target.value;
            }
            input.onChange(e);
          }}
          onBlur={input.onBlur}
        />
        <InputGroup.Append>
          <Button
            className="border-0 px-2 py-1 m-0 mr-1"
            size="sm"
            disabled={disabled}
            onClick={() => setShow(true)}
          >
            <span className="feather icon-eye" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
      {meta.error && meta.modified && !meta.active && (
        <span className="text-danger">
          {meta.error}
          <br />
          三個字元為一組,
          第一個字元1或5或8或4,第二個字元1-8,第三個字元M或D或F或E或0或S或h
        </span>
      )}
      <Modal
        show={show}
        aria-labelledby="tee-sheet-modal"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="tee-sheet-modal">口檢表</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TeeSheet
            teeSheet={teeSheet}
            sheetMap={sheetMap}
            babyMap={babayMap}
            specialMap={specialMap}
            specialTooth={specialTooth}
            onChange={internalOnChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            onClick={() => {
              if (inputRef.current) {
                const s = serializeTeeSheet(teeSheet);
                inputRef.current.value = s;
                setStr(s);

                const e = new Event('change', { bubbles: true });
                inputRef.current.dispatchEvent(e);
                input.onChange(e);
                const fe = new Event('blur', { bubbles: true }) as any;
                input.onBlur(fe);
              }
              onHide();
            }}
          >
            儲存
          </Button>
          <Button type="reset" variant="secondary" onClick={onHide}>
            關閉
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function createToothKey(tooPlace: ToothPlace, idx: number) {
  return `${tooPlace}${idx}`;
}
function createTooth(
  onChange: (key: string, value: string) => void,
  options: Record<string, string>,
  toothKey: string,
  value: string
) {
  return (
    <div key={toothKey} className="p-1" style={{ width: '3rem' }}>
      <div className={'text-dark text-center'}>{toothKey}</div>
      <InputDropdownGroup
        className={'p-1 text-center'}
        options={options}
        defaultShow="無異常"
        name={toothKey}
        value={value}
        onChange={(
          e: ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
          >
        ) => {
          onChange(toothKey, e.target.value);
        }}
        onBlur={() => {}}
        onFocus={() => {}}
      />
    </div>
  );
}

function lowerKeyMap(map: Record<string, string>): Record<string, string> {
  return Object.entries(map).reduce((des, [key, value]) => {
    des[key.toLowerCase()] = value;
    return des;
  }, {} as Record<string, string>);
}

export function getTeeKeys(): string[] {
  return Object.values(ToothPlace).flatMap((t) => {
    return Array.from({ length: teeFromTo[1] - teeFromTo[0] + 1 })
      .map((_, i) => {
        const p = i + 1;
        if (t >= ToothPlace.childTopLeft && p > childMaxTee) {
          return '';
        }
        return t + p;
      })
      .filter((s) => !!s);
  });
}
