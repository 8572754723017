import React, { Fragment, FunctionComponent, useEffect, useState, useRef, ReactElement, ReactNode, } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row } from '../../../utils';
import { 
  Button, 
  Row as BSRow, 
  Dropdown,
  DropdownButton,
  Col,
  InputGroup,
  Form,
  Modal,
  Container,
} from 'react-bootstrap';
import { AuthedLayout, EditableTableDiv, HeaderDisplayKeysWithType } from '../../../components';
import { PHISundryGroupByGradeClass, PHIWH, StudentPHIWH } from '../../../model';
import { toast } from 'react-toastify';
import apis from '../../../apis';
import { useHistory } from 'react-router';
import { ClassMapObj, Nullable } from '../../../types';
import { listToObject } from '../../../utils/transform';
import { SundryPropertyFields, CheckTempInputTableHeader } from '../../TableHeaders/CheckTempInputHeader';
import { semesterRange } from '../../../utils/date';

export interface GradeSem {
  grade?: number;
  gradeName: string;
  year: number;
  sem: number;
}

type ChildRender = (classSem: GradeSem) => ReactElement;
type OwnProps = {
  children: ReactNode | ChildRender;
  onGradesLoaded?: () => void;
  onGradeChanged?: (year: number, sem: number, classId?: string) => void;
};

const mapState = (state: ApplicationState, ownProps: OwnProps) => ({ 
  ...state.auth,  
  ...ownProps
});
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;
const checkKind = [
  {
    Id: 1,
    Checks: '心臟病篩檢'
  },
  {
    Id: 2,
    Checks: '胸部X光'
  },
  {
    Id: 3,
    Checks: '肝功能'
  },
  {
    Id: 4,
    Checks: '腎功能'
  },
  {
    Id: 5,
    Checks: '蛔蟲'
  },
  {
    Id: 6,
    Checks: '蟯蟲'
  },
{
    Id: 7,
    Checks: 'A型肝炎'
  },
  {
    Id: 8,
    Checks: 'B型肝炎'
  },
  {
    Id: 9,
    Checks: '結核菌素測驗'
  },
  {
    Id: 10,
    Checks: '頭蝨'
  },
  {
    Id: 11,
    Checks: '尿液'
  },
  {
    Id: 12,
    Checks: '心電圖'
  },
  {
    Id: 13,
    Checks: '心臟超音波'
  }
];
const monthsObj = {
  'sem1': [
    {
      id: 8,
      text: '8月'
    },
    {
      id: 9,
      text: '9月'
    },
    {
      id: 10,
      text: '10月'
    },
    {
      id: 11,
      text: '11月'
    },
    {
      id: 12,
      text: '12月'
    },
    {
      id: 1,
      text: '1月'
    },
  ],
  'sem2': [ 
    {
      id: 2,
      text: '2月'
    },
    {
      id: 3,
      text: '3月'
    },
    {
      id: 4,
      text: '4月'
    },
    {
      id: 5,
      text: '5月'
    },
    {
      id: 6,
      text: '6月'
    },
    {
      id: 7,
      text: '7月'
    },
  ]
};
const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const checkTempInput: FunctionComponent<Props> = ({ 
  user,
  loading,
  catchErrorForModal,
  onGradesLoaded = () => {},
  onGradeChanged = () => {},
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  type MetricPageData = PHISundryGroupByGradeClass;
  const { currentSemester = '', semesters = {} } = user || {};
  const [currentSem, setCurrentSem] = useState(currentSemester);
  const [month, setMonth] = useState<number>();
  const [monthText, setMonthText] = useState<string>('');
  const [dates, setDates] = useState <string[]>([]);
  const [date, setDate] = useState<number>(0);
  const [sundryId, setSundryId] = useState<number>();
  const [grades, setGrades] = useState<ClassMapObj>({ '': '無年級資料' });
  const [showGrade, setGrade] = useState<string>();
  const { year = -1, sem = -1 } = semesters[currentSem];
  const agencyInputRef = useRef<HTMLInputElement>(null);
  const semMonths = sem == 1 ? monthsObj['sem1'] : monthsObj['sem2'];
  const currentYear = new Date().getFullYear();

  const [students, setStudent] = useState([] as MetricPageData[]);  
  const [inputing, setInputing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<MetricPageData>();
  const displayHeader: HeaderDisplayKeysWithType<MetricPageData>[] = [
    { property: 'grade', display: '年級' },
    ...(CheckTempInputTableHeader as HeaderDisplayKeysWithType<MetricPageData>[]),
  ];

  // 年級清單
  useEffect(() => {
    if (year) {
      refreshCheckTempMetric();
    }
  }, [user, year, sem]);

  useEffect(() => {
    if(month) {
      const daysInMonth = new Date(currentYear, month, 0).getDate();
      setDates(Array.from({length: daysInMonth}, (v, i) => i + 1 + '日'));
    }
  }, [sundryId, month]);

  useEffect(() => {
    if (agencyInputRef.current) {
      checkField();
    }
  }, [agencyInputRef.current]);

  function refreshCheckTempMetric() {
    if (user && year && sem) {
      apis
      .GetGrades(year, sem)
      .then((grades) => {
        if (grades.length)
          setGrades(
            listToObject(
              grades,
              (c) => c.grade + '',
              (c) => c.name
            )
          );
        setGrade(undefined);
        onGradesLoaded();
      })
      .catch(catchErrorForModal);

      // 班級臨時性檢查清單
      apis
      .getSundryGroupByGradeClass(year, sem)
      .then((r) => {
        setStudent(r)
      })
      .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }
  
  // 檢查欄位是否填寫
  function checkField() {
    if(!showGrade) {
      return true
    } else if(!sundryId) {
      return true
    } else if(!month) {
      return true
    } else if(!date) {
      return true
    } else {
      return false
    }
  }

  async function getInsertData(grade: string, month: number, date: number,check: number, agency: string) {
    checkField();
    const examDate = new Date(currentYear, month - 1, date).toLocaleString('sv').slice(0, 10);
    const agencyInput = agencyInputRef.current?.value;
    // 新增臨時性檢查api
    if(agencyInput) {
      toast
        .promise(
          apis.insertCheckTemp(
            parseInt(grade),
            year,
            sem,
            agencyInput,
            check,
            examDate
          ),
          {
            pending: '資料新增中......',
            success: '新增成功！',
            error: '新增失敗！請查看錯誤資訊。',
          }
        )
        .then(() => {
          refreshCheckTempMetric();
          onHide();
          // 清空年級、檢查項目、檢查日期、檢查單位欄位
          setSundryId(0);
          setMonthText('');
          setDate(0);
          if(agencyInputRef.current) {
            agencyInputRef.current.value = '';
          }
        })
        .catch(catchErrorForModal);
    }
  }
  function onHide() {
    setInputing(false);
    setEditingStudent(undefined);
  }

  function onEditMetric(metric: MetricPageData) {
    setInputing(true);
    setEditingStudent(metric);
  }
  
  function deleteCheckTemp() {
    if(editingStudent) {
      const idArr:number[] = editingStudent.idsString.split(',').map(i => parseInt(i));
      toast
        .promise(
          apis.deleteCheckTemp(
            editingStudent.grade,
            year,
            sem,
            editingStudent.agencies,
            editingStudent.sundryId,
            editingStudent.examDate || null,
            idArr
          ),
          {
            pending: '資料刪除中......',
            success: '刪除成功！',
            error: '刪除失敗！請查看錯誤資訊。',
          }
        )
        .then(() => {
          onHide();
          refreshCheckTempMetric();
        })
        .catch(catchErrorForModal);
    }
  }
  
  return (
    <AuthedLayout>
      <BSRow className="align-items-center mb-3">
        <Col sm={2} className='d-flex align-items-center'>
          年級：
          <DropdownButton
            className=""
            title={(showGrade && grades[showGrade]) || '請選擇'}
            onSelect={(k: string | null) => {
              if (k == null || !grades[k] || !semesters[currentSem]) return;
              setGrade(k);
              const semester = semesters[currentSem];
              onGradeChanged(semester.year, semester.sem, k);
            }}
          >
           {Object.entries(grades).map(([i, n]) => (
              <Dropdown.Item key={`class-${i}`} eventKey={i}>
                {n}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
        <Col sm={3} className='d-flex align-items-center'>
          檢查項目：
          <DropdownButton
              className=""
              title={sundryId ? checkKind[sundryId - 1].Checks : '檢查項目'}
              onSelect={(c) => {
                if(c) {
                  setSundryId(parseInt(c));
                }
              }}
            >
              <Dropdown.Item key={`check-${1}`} eventKey={1}>心臟病篩檢</Dropdown.Item>
              <Dropdown.Item key={`check-${2}`} eventKey={2}>胸部X光</Dropdown.Item>
              <Dropdown.Item key={`check-${3}`} eventKey={3}>肝功能</Dropdown.Item>
              <Dropdown.Item key={`check-${4}`} eventKey={4}>腎功能</Dropdown.Item>
              <Dropdown.Item key={`check-${5}`} eventKey={5}>蛔蟲</Dropdown.Item>
              <Dropdown.Item key={`check-${6}`} eventKey={6}>蟯蟲</Dropdown.Item>
              <Dropdown.Item key={`check-${7}`} eventKey={7}>A型肝炎</Dropdown.Item>
              <Dropdown.Item key={`check-${8}`} eventKey={8}>B型肝炎</Dropdown.Item>
              <Dropdown.Item key={`check-${9}`} eventKey={9}>結核菌素測驗</Dropdown.Item>
              <Dropdown.Item key={`check-${10}`} eventKey={10}>頭蝨</Dropdown.Item>
              <Dropdown.Item key={`check-${11}`} eventKey={11}>尿液</Dropdown.Item>
              <Dropdown.Item key={`check-${12}`} eventKey={12}>心電圖</Dropdown.Item>
              <Dropdown.Item key={`check-${13}`} eventKey={13}>心臟超音波</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col sm={4} className='d-flex align-items-center'>
          檢查日期：
          <DropdownButton
            className="mr-2"
            title={monthText || '檢查月'}
            onSelect={(g) => {
              if(g) {
                const selectMonth = semMonths[parseInt(g)];
                setMonth(selectMonth.id);
                setMonthText(year + (selectMonth.id === 1 ? 1 : 0) + '年' + selectMonth.text);
              }
            }}
          >
          {semMonths.map((month, idx) => ( 
            <Dropdown.Item key={`month-${idx}`} eventKey={idx}>
              {year + (sem === 1 && month.id === 1 ? 1 : 0)}年{month.text} 
            </Dropdown.Item> 
          ))}
          </DropdownButton>
          <DropdownButton
            className=""
            title={date ? dates[date - 1] : '檢查日' }
            onSelect={(d) => {
              if(d) {
                setDate(parseInt(d));
              }
            }}
          >
          {dates.map((d, idx) => (
            <Dropdown.Item key={`date-${idx + 1}`} eventKey={idx + 1}>
              {d}
            </Dropdown.Item>
          ))}
          </DropdownButton>
        </Col>
        <Col sm={2}>
          <InputGroup>
            <Form.Control
              className='mr-2'
              placeholder="檢查單位"
              aria-label="Agency"
              aria-describedby="basic-addon1"
              ref={agencyInputRef}
            />
          </InputGroup>
        </Col>
        <Col sm={1} className='text-right'>
        <Button
          disabled={checkField() == true}
          onClick={() => getInsertData(showGrade || '1', month || 1, date || 1, sundryId || 1, '')}
        >
          新增
        </Button>
        </Col>
      </BSRow>
      <hr />
      <BSRow>
        <EditableTableDiv
          deleteable
          values={students}
          headers={displayHeader}
          onDelete={onEditMetric}
        />
        {/* <EditableTableDiv
          editable
          values={students}
          headers={displayHeader}
          onEdit={onEditMetric}
        /> */}
      </BSRow>
      <Modal
        show={inputing}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="contained-modal-title-vcenter">
              是否刪除臨時性檢查
            </Modal.Title>
        </Modal.Header>
          <React.Fragment>
            <Modal.Body className="text-center">
              <Container>
                <BSRow>
                  <Col>
                    <BSRow className="pb-3 justify-content-center align-items-center">
                      <Col sm="4">年級:</Col>
                      <Col sm="8">{editingStudent?.grade}</Col>
                    </BSRow>
                    <BSRow className="pb-3 justify-content-center align-items-center">
                      <Col sm="4">檢查項目:</Col>
                      <Col sm="8">{editingStudent?.sundryId ? checkKind[editingStudent?.sundryId - 1].Checks : '無檢查項目'}</Col>
                    </BSRow>
                    <BSRow className="pb-3 justify-content-center align-items-center">
                      <Col sm="4">檢查日期:</Col>
                      <Col sm="8">{editingStudent?.examDate?.toFormat('yyyy/MM/dd')}</Col>
                    </BSRow>
                    <BSRow className="pb-3 justify-content-center align-items-center">
                      <Col sm="4">檢查單位:</Col>
                      <Col sm="8">{editingStudent?.agencies}</Col>
                    </BSRow>
                  </Col>
                </BSRow>
              </Container>
            </Modal.Body>
          </React.Fragment>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onHide}>
            取消
          </Button>
          <Button variant="danger" onClick={deleteCheckTemp}>
            刪除
          </Button>
        </Modal.Footer>
      </Modal>
    </AuthedLayout>
  );
};



export const CheckTempInput = connector(checkTempInput);
