import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';
import { SightMatrix } from '../../../apis/NoticeApi';

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;

const ratefontsize = {
  sz: 11
} as const

const cellAlignStyle = {
  horizontal: 'center',
  vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;

const sightStaticRate2: FunctionComponent<Props> = ({ catchErrorForModal }) => {
  const { yearSem, element } = useSemGrade();
  const [datas, setDatas] = useState<SightMatrix>([]);

  useEffect(() => {
    if (yearSem) {
      apis.getSightMatrix(yearSem.year, yearSem.sem).then((s) => {
        setDatas(s);
      });
    }
  }, [yearSem?.year, yearSem?.sem]);

  // const gradeBase = datas.reduce((grades, d) => {
  //     return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
  // }, {} as Record<number, SightMatrix[]>);

  const contentRows: Row[] = Object.values(datas).flatMap((d) => {
    return [
      {
        cells: [
          {
            value: d.grade === 0 ? '合計' : d.grade,
            style: { border: fullBorderStyle },
          },
          {
            value: d.allSum0 === 0 ? '0' : d.allSum0,
            style: { border: fullBorderStyle },
          },
          {
            value: d.maleSum0 === 0 ? '0' : d.maleSum0,
            style: { border: fullBorderStyle },
          },
          {
            value: d.femaleSum0 === 0 ? '0' : d.femaleSum0,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male10 === 0 ? '0' : d.male10,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female10 === 0 ? '0' : d.female10,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male20 === 0 ? '0' : d.male20,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female20 === 0 ? '0' : d.female20,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male30 === 0 ? '0' : d.male30,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female30 === 0 ? '0' : d.female30,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male40 === 0 ? '0' : d.male40,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female40 === 0 ? '0' : d.female40,
            style: { border: fullBorderStyle },
          },
          {
            value: '0',
            style: { border: fullBorderStyle },
          },
          {
            value: '0',
            style: { border: fullBorderStyle },
          },
          {
            value: d.allSum01 === 0 ? '0' : d.allSum01,
            style: { border: fullBorderStyle },
          },
          {
            value: d.maleSum01 === 0 ? '0' : d.maleSum01,
            style: { border: fullBorderStyle },
          },
          {
            value: d.femaleSum01 === 0 ? '0' : d.femaleSum01,
            style: { border: fullBorderStyle },
          },
          {
            value: d.allSum1 === 0 ? '0' : d.allSum1,
            style: { border: fullBorderStyle },
          },
          {
            value: d.maleSum1 === 0 ? '0' : d.maleSum1,
            style: { border: fullBorderStyle },
          },
          {
            value: d.femaleSum1 === 0 ? '0' : d.femaleSum1,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male11 === 0 ? '0' : d.male11,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female11 === 0 ? '0' : d.female11,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male21 === 0 ? '0' : d.male21,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female21 === 0 ? '0' : d.female21,
            style: { border: fullBorderStyle },
          },
          {
            value: d.male31 === 0 ? '0' : d.male31,
            style: { border: fullBorderStyle },
          },
          {
            value: d.female31 === 0 ? '0' : d.female31,
            style: { border: fullBorderStyle },
          },
        ],
      },
      {
        cells: [
          {
            value: '比率',
            style: { border: fullBorderStyle },
          },
          {
            value: percent(d.allSum0, d.allSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.maleSum0, d.allSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.femaleSum0, d.allSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male10, d.maleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female10, d.femaleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male20, d.maleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female20, d.femaleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male30, d.maleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female30, d.femaleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male40, d.maleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(0, 1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(0, 1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female40, d.femaleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.allSum01, d.allSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.maleSum01, d.maleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.femaleSum01, d.femaleSum0),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.allSum1, d.allSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.maleSum1, d.allSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.femaleSum1, d.allSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male11, d.maleSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female11, d.femaleSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male21, d.maleSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female21, d.femaleSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.male31, d.maleSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
          {
            value: percent(d.female31, d.femaleSum1),
            style: { border: fullBorderStyle, font: ratefontsize },
          },
        ],
      },
    ];
  });

  const content: Row[] = [
    {
      cells: [
        {
          value: '一、合計人數不含視力之未測量（1 筆）及無法測量（0 筆）。',
          merge: { column: 26 },
          style: {
            border: { top: borderStyle, left: borderStyle, right: borderStyle },
            alignment: { horizontal: 'left' },
          },
        },
      ],
    },
    {
      cells: [
        {
          value:
            '二、表內【無法取得】意指裸眼視力欄位輸入為 -6（雷射治療）、-7（角膜塑型）、-8（戴隱形眼鏡）之代碼。',
          merge: { column: 26 },
          style: {
            border: { left: borderStyle, right: borderStyle },
            alignment: { horizontal: 'left' },
          },
        },
      ],
    },
    {
      cells: [
        {
          value: '三、以分組人數（分男女）為比率之母數。',
          merge: { column: 26 },
          style: {
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
            alignment: { horizontal: 'left' },
          },
        },
      ],
    },
    {
      cells: [
        {
          value: '年級',
          merge: { row: 4 },
          style: { border: fullBorderStyle },
        },
        {
          value: '裸視視力檢查人數',
          merge: { column: 16 },
          style: { border: fullBorderStyle },
        },
        {
          value: '戴鏡視力檢查人數',
          merge: { column: 9 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    {
      cells: [
        { style: { border: fullBorderStyle } },
        {
          value: '',
          merge: { column: 3 },
          style: {
            border: { top: borderStyle, left: borderStyle, right: borderStyle },
          },
        },
        {
          value: '兩眼',
          merge: { column: 2 },
          style: {
            border: { top: borderStyle, left: borderStyle, right: borderStyle },
          },
        },
        {
          value: '裸視視力不良人數',
          merge: { column: 11 },
          style: { border: fullBorderStyle },
        },
        {
          value: '',
          merge: { column: 3 },
          style: {
            border: { top: borderStyle, left: borderStyle, right: borderStyle },
          },
        },
        {
          value: '兩眼',
          merge: { column: 2 },
          style: {
            border: { top: borderStyle, left: borderStyle, right: borderStyle },
          },
        },
        {
          value: '矯正視力不良',
          merge: { column: 4 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    {
      cells: [
        { style: { border: fullBorderStyle } },
        {
          value: '合計',
          merge: { column: 3 },
          style: {
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
          },
        },
        {
          value: '均達0.9',
          merge: { column: 2 },
          style: {
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
          },
        },
        {
          value: '0.5 ~ 0.8',
          merge: { column: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '0.1 ~ 0.4',
          merge: { column: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '0.1 以下',
          merge: { column: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '無法取得',
          merge: { column: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '合計',
          merge: { column: 3 },
          style: { border: fullBorderStyle },
        },
        {
          value: '合計',
          merge: { column: 3 },
          style: {
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
          },
        },
        {
          value: '均達0.5',
          merge: { column: 2 },
          style: {
            border: {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            },
          },
        },
        {
          value: '0.1 ~ 0.4',
          merge: { column: 2 },
          style: { border: fullBorderStyle },
        },
        {
          value: '0.1 以下',
          merge: { column: 2 },
          style: { border: fullBorderStyle },
        },
      ],
    },
    {
      cells: [
        { style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '計', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
        { value: '男', style: { border: fullBorderStyle } },
        { value: '女', style: { border: fullBorderStyle } },
      ],
    },

    ...contentRows,
  ];

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!datas.length}
          onClick={() => {
            const workSheet = generateSheet(
              [
                {
                  cells: [
                    {
                      value: `${yearSem?.year}學年第${yearSem?.sem}學期_視力統計表(含比率)`,
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
                ...content,
                {
                  cells: [
                    {
                      value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                      style: { alignment: { horizontal: 'left' } },
                    },
                  ],
                },
              ],
              {
                alignment: cellAlignStyle,
              }
            );
            downloadSheets(
              `${yearSem?.year}學年第${yearSem?.sem}學期_視力統計表(含比率)`,
              { sheet: workSheet, name: 'SightMatrix' }
            );
          }}
        >
          Excel 下載
        </Button>
      </BSRow>
      <hr />
      <BSRow>
        <Table
          striped
          bordered
          className="text-center"
          style={{ tableLayout: 'fixed' }}
        >
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '6%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
            <col style={{ width: '7%' }} />
          </colgroup>
          <tbody>
            {content.map((r, i) => {
              const cells = r.cells.map((c, j) =>
                c.value != undefined ? (
                  <td
                    colSpan={c.merge?.column}
                    rowSpan={c.merge?.row}
                    key={`${i}-${j}`}
                    style={{ fontSize: c.style?.font?.sz }}
                  >
                    {c.value}
                  </td>
                ) : (
                  <Fragment key={`${i}-${j}`}></Fragment>
                )
              );
              return <tr key={i}>{cells}</tr>;
            })}
          </tbody>
        </Table>
      </BSRow>
    </AuthedLayout >
  );
};

function percent(value: number, denominator: number): string {
  if (!denominator) return '0.00%';
  return ((value / denominator) * 100).toFixed(1) + '%';
}

export const SightStaticRate2 = connector(sightStaticRate2);
