import React, { Fragment, FunctionComponent, useEffect, useState, useRef, ReactNode } from 'react';
import { ApplicationState } from '../../redux/States';
import { ErrorDispatches } from '../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { downloadNameListSheets} from '../../utils';
import { Button, FormControl, Col, Row as BSRow, FormCheck, Dropdown, DropdownButton } from 'react-bootstrap';
import { 
  AuthedLayout, 
  OverflowWithTooltip,
 } from '../../components';
import { ClassSetting, StudentName } from '../../model';
import apis from '../../apis';
import { useHistory } from 'react-router';
import XLSX from 'xlsx-js-style';
import { DateTime } from 'luxon';
import { I18N } from '../../i18n-raw';

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;
interface CheckedClasses {
    [key: string]: boolean;
}
const nameList: FunctionComponent<Props> = ({ 
  user,
  loading,
  catchErrorForModal 
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  type Student = { 
    seat?: number | null;
    name?: string | null;
    year?: number | null; 
    sex?: string | null;
    cSex?: string | null;
    pid?: string | null;
    dad?: string | null;
    mom?: string | null;
    zip?: string | null;
    address?: string | null;
    erTel?: string | null;
    tel?: string | null;
    realAge?: string | null;
    birth?: DateTime | null;
    sid?: string | null;
    gradeNoSeat?: string | null;
    hospital?: string | null;
    [key: string]: any; // Index signature to allow dynamic keys 
  };
  const { year, sem } = user.semesters[user.currentSemester];
  const excelNameRef = useRef<HTMLInputElement>(null);
  const [excelName, setExcelName] = useState<string>('');
  const footerRef = useRef<HTMLInputElement>(null);
  const otherColumnRef = useRef<HTMLInputElement>(null);
  const otherColumn2Ref = useRef<HTMLInputElement>(null);
  const otherColumn3Ref = useRef<HTMLInputElement>(null);
  const [footer, setFooter] = useState<string>('教師：');
  const [otherColumn, setOtherColumn] = useState<string>('');
  const [otherColumn2, setOtherColumn2] = useState<string>('');
  const [otherColumn3, setOtherColumn3] = useState<string>('');
  const years = useRef<HTMLInputElement>(null);
  const sex = useRef<HTMLInputElement>(null);
  const cSex = useRef<HTMLInputElement>(null);
  const pid = useRef<HTMLInputElement>(null);
  const dad = useRef<HTMLInputElement>(null);
  const mom = useRef<HTMLInputElement>(null);
  const guardian = useRef<HTMLInputElement>(null);
  const zip = useRef<HTMLInputElement>(null);
  const address = useRef<HTMLInputElement>(null);
  const erTel = useRef<HTMLInputElement>(null);
  const tel = useRef<HTMLInputElement>(null);
  const realAge = useRef<HTMLInputElement>(null);
  const birth = useRef<HTMLInputElement>(null);
  const sid = useRef<HTMLInputElement>(null);
  const gradeNoSeat = useRef<HTMLInputElement>(null);
  const hospital = useRef<HTMLInputElement>(null);
  const [classSettings, setClassSettings] = useState<ClassSetting[]>([]);
  const [checkedClasses, setCheckedClasses] = useState<CheckedClasses>({});
  const [selectAll, setSelectAll] = useState(false);
  const [showBirthFormat, setShowBirthFormat] = useState<boolean>(false);
  const [birthFormat, setBirthFormat] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<string | null>('西元年: 2004/03/03');
  function wrapOverflow(content: ReactNode) {
    return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
  }
  const handleSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    const newCheckedClasses: CheckedClasses = {};
    classSettings.forEach((item) => {
      newCheckedClasses[item.id] = checked;
    });
    setCheckedClasses(newCheckedClasses);
  };
  const handleClassCheckChange = (id: string, checked: boolean) => {
    setCheckedClasses((prevState) => ({
      ...prevState,
      [id]: checked
    }));

    // 如果有一個 checkbox 被取消勾選，全選也應取消
    if (!checked) {
      setSelectAll(false);
    } else {
      const allChecked = classSettings.every(
        (item) => checkedClasses[item.id] || item.id === id
      );
      if (allChecked) {
        setSelectAll(true);
      }
    }
  };
 
  useEffect(() => {
    apis.getClassSettings(year, sem)
      .then((data) => {
        setClassSettings(data);
      })
      .catch();
  }, [user]);

  function downloadExcel() {
    const classes = classSettings.filter((item) => checkedClasses[item.id]); // 勾選班級
    apis.getStudentNameList(
      classes,
      year,
      sem
    )
    .then((r) => {
      const studentData = r.map(value => ({ ...value, realAge: calculateAge(value.birth)}));
      generateExcel(studentData, classes);
    })
    .catch()
  }
  const fieldsToCheck = [ 
    { key: 'year', ref: years }, 
    { key: 'sex', ref: sex }, 
    { key: 'cSex', ref: cSex }, 
    { key: 'pid', ref: pid },
    { key: 'dad', ref: dad },
    { key: 'mom', ref: mom },
    { key: 'guardian', ref: guardian },
    { key: 'zip', ref: zip },
    { key: 'address', ref: address },
    { key: 'erTel', ref: erTel },
    { key: 'tel', ref: tel },
    { key: 'realAge', ref: realAge },
    { key: 'birth', ref: birth },
    { key: 'sid', ref: sid },
    { key: 'gradeNoSeat', ref: gradeNoSeat },
    { key: 'hospital', ref: hospital }
  ];
  function generateExcel(data: StudentName[], classes: ClassSetting[]) {
    const customColumns = [
      '座號',
      '姓名', 
      years.current?.checked ? '入學年' : '', 
      sex.current?.checked ? '性別' : '', 
      cSex.current?.checked ? '性別' : '', 
      pid.current?.checked ? '統編' : '', 
      dad.current?.checked ? '父親' : '', 
      mom.current?.checked ? '母親' : '', 
      guardian.current?.checked ? '監護人' : '', 
      zip.current?.checked ? '郵區' : '', 
      address.current?.checked ? '住址' : '', 
      erTel.current?.checked ? '緊急聯絡人' : '', 
      tel.current?.checked ? '電話' : '', 
      realAge.current?.checked ? '實歲' : '', 
      birth.current?.checked ? '生日' : '', 
      sid.current?.checked ? '學號' : '', 
      gradeNoSeat.current?.checked ? '年級班級座號' : '',
      hospital.current?.checked ? '護送醫院' : '',
      otherColumn, 
      otherColumn2, 
      otherColumn3,
      '備註'
    ]; 
    const headArr = customColumns.filter(m => !!m);
    const firstRow = ['', ''];
    const emptyColumns = [''];
    const footerArr = [footer, ''];
    headArr.forEach((v, i) => {
      if(i > 1) {
        footerArr.push('');
        firstRow.push('');
        emptyColumns.push('');
      }
    })
    const sheets = classes.map((item, index) => {
      firstRow[0] = `${I18N.Grades[item.grade]}${item.no}班`;
      const students = data.filter((s) => s.classId == item.id);
      const studentData = students.map((student: Student) => {
        const baseData = [student.seat, student.name]; 
        // 已勾選欄位
        fieldsToCheck.forEach(field => { 
          if (field.ref.current?.checked) {
            if(field.key == 'birth') {
              if(birthFormat === '2') {
                baseData.push(student.birth ? getROCYear(student.birth.toJSDate()): '');
              } else if(birthFormat === '3') {
                baseData.push(student.birth ? getROCYearString(student.birth.toJSDate()): '');
              } else {
                baseData.push(student.birth?.toFormat('yyyy/MM/dd'));
              }
            } else {
              baseData.push(student[field.key] || ''); 
            }
          }
        });
        // 自訂欄位帶入空值
        [otherColumn, otherColumn2, otherColumn3, '備註'].forEach(c => {
          if(c) {
            baseData.push('');
          }
        });
        return baseData;
      });
      const sheetData = [
        [], // 第一行 空白行
        [`${I18N.Grades[item.grade]}${item.no}班`].concat(emptyColumns), // 第二行標題
        headArr, // 第三行 表頭
        ...studentData, // 學生資料,
        [footer].concat(emptyColumns)
      ];
      // 轉換為工作表並設定合併儲存格
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

      return {
        name: `${item.grade}-${item.no}`, // 工作表名稱
        sheet: worksheet,
      };
    });
    
    downloadNameListSheets(excelName || '學生名條', ...sheets);
  }

  function getROCYear(datetime: Date): string { 
    const rocYear = datetime.getFullYear() - 1911; 
    const month = (datetime.getMonth() + 1).toString().padStart(2, '0'); 
    const day = datetime.getDate().toString().padStart(2, '0');
    return `${rocYear}/${month}/${day}`; 
  }
  
  function getROCYearString(birth: Date): string { 
    const rocYear = (birth.getFullYear() - 1911).toString().padStart(3, '0');
    const month = (birth.getMonth() + 1).toString().padStart(2, '0');
    const day = birth.getDate().toString().padStart(2, '0'); 
    return `${rocYear}${month}${day}`;
  }

  function calculateAge(birthDate: DateTime): number {
    const birthDateObj = new Date(
      birthDate.year,
      birthDate.month - 1,
      birthDate.day
    );
  
    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
  
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }
  
    return age;
  }

  return (
    <AuthedLayout>
        <BSRow className="mb-4">
        清單標題：
        <Col sm={6} className='mr-2'>
          <FormControl 
            type="string"
            aria-label="excelName"
            aria-describedby="basic-addon1"
            value={excelName}
            ref={excelNameRef}
            onChange={(e) => {
              setExcelName(e.target.value)
            }}
          />
        </Col>
        </BSRow>
        <BSRow>
          <Col sm={12}>
              <FormCheck
                  key='allClass'
                  checked={selectAll}
                  label='全選'
                  onChange={(e) => handleSelectAll(e.currentTarget.checked)}
              />
          </Col>
        </BSRow>
        <BSRow className='mb-4'>
          {classSettings.map((item) => (
            <Col sm={2} key={item.id}>
              <FormCheck
                key={item.id}
                checked={checkedClasses[item.id] || false}
                label={`${item.grade}年${item.name}班`}
                onChange={(e) => {
                  handleClassCheckChange(item.id, e.currentTarget.checked);
                }}
              />
            </Col>
          ))}
        </BSRow>
        <BSRow className='mb-4'>
            <Col sm={2}>
              <FormCheck
                ref={years}
                label="入學年Years"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={sex}
                label="性別SexID"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={cSex}
                label="性別CSex"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={pid}
                label="統編PID"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={dad}
                label="父親Dad"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
                <FormCheck
                    ref={mom}
                    label="母親Mom"
                    onChange={(e) => {}}
                />
            </Col>
            <Col sm={2}>
                <FormCheck
                    ref={guardian}
                    label="監護人Guardian"
                    onChange={(e) => {}}
                />
            </Col>
            <Col sm={2}>
                <FormCheck
                  ref={zip}
                  label="郵區Zip"
                  onChange={(e) => {}}
                />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={address}
                label="住址Address"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={erTel}
                label="緊急聯絡EmergencyCall"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={tel}
                label="電話Tel"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={realAge}
                label="實歲RealAgeSt"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={birth}
                label="生日Birthday"
                onChange={(e) => {
                  setShowBirthFormat(e.currentTarget.checked);
                }}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={sid}
                label="學號GuyID"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={gradeNoSeat}
                label="年級班級座號"
                onChange={(e) => {}}
              />
            </Col>
            <Col sm={2}>
              <FormCheck
                ref={hospital}
                label="護送醫院Hospital	"
                onChange={(e) => {}}
              />
            </Col>
        </BSRow>
        <BSRow className={showBirthFormat ? 'mb-4' : 'mb-4 d-none'}>
          生日格式：
          <DropdownButton
            title={selectedOption}
            onSelect={(eventKey) => {
              if (eventKey) {
                const [key, value] = eventKey.split("|");
                console.log('key',key)
                setBirthFormat(key || '1');
                setSelectedOption(value);
              }
            }}
          >
            <Dropdown.Item key="1" eventKey="1|西元年: 2004/03/03">西元年: 2004/03/03</Dropdown.Item>
            <Dropdown.Item key="2" eventKey="2|民國年: 93/03/03">民國年: 93/03/03</Dropdown.Item>
            <Dropdown.Item key="3" eventKey="3|民國年: 0930303">民國年: 0930303</Dropdown.Item>
          </DropdownButton>
        </BSRow>
        <BSRow className='mb-4'>
        自訂欄位：
        <Col className='mr-2'>
          <FormControl 
            type="string"
            aria-label="excelFooter"
            aria-describedby="basic-addon1"
            value={otherColumn}
            ref={otherColumnRef}
            onChange={(e) => {
              setOtherColumn(e.target.value)
            }}
          />
        </Col>
        <Col className='mr-2'>
            <FormControl 
              type="string"
              aria-label="excelFooter"
              aria-describedby="basic-addon1"
              value={otherColumn2}
              ref={otherColumn2Ref}
              onChange={(e) => {
                setOtherColumn2(e.target.value)
              }}
            />
        </Col>
        <Col className='mr-2'>
          <FormControl 
            type="string"
            aria-label="excelFooter"
            aria-describedby="basic-addon1"
            value={otherColumn3}
            ref={otherColumn3Ref}
            onChange={(e) => {
              setOtherColumn3(e.target.value)
            }}
          />
        </Col>
        </BSRow>
        <BSRow>
        頁尾簽章：
        <Col sm={6} className='mr-2'>
          <FormControl 
            type="string"
            aria-label="excelFooter"
            aria-describedby="basic-addon1"
            value={footer}
            ref={footerRef}
            onChange={(e) => {
              setFooter(e.target.value)
            }}
          />
        </Col>
        </BSRow>
        <BSRow>
        <Col sm={12}>
          <Button
            disabled={classSettings.filter((item) => checkedClasses[item.id]).length ? false : true}
            onClick={downloadExcel}
          >
            確定
          </Button>
        </Col>
        </BSRow>
    </AuthedLayout>
  );
};

export const NameList = connector(nameList);
