import React, { ReactNode, Fragment, FunctionComponent, useEffect, useState, useRef, useMemo } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet, listToObject, SheetHeaderDisplay } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { 
  Button, 
  Row as BSRow, 
  Table,
  Dropdown,
  DropdownButton,
  FormControl,
  Col,
  InputGroup,
  Form,
  Modal,
  Container,
} from 'react-bootstrap';
import { 
  AuthedLayout ,
  CheckBoxField,
  TextField,
  OverflowWithTooltip,
  HeaderDisplayKeysWithType,
  InlineEditableTable,
  ErrorModal,
  EditableTableDiv,
  EditableProperties,
 } from '../../../components';
import apis from '../../../apis';
import { Fluor, NeverFluor } from '../../../apis/FluorApi';
import { ClassMapObj, Nullable } from '../../../types';
import { toast } from 'react-toastify';
import { StudentGradeNoSeat, TeeFluorLack, NeverFluorStudent, StudentFluor, Class } from '../../../model';

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const cellAlignStyle = {
  horizontal: 'center',
  vertical: 'center',
} as const;
const monthsObj = {
    'sem1': [
      {
        id: 8,
        text: '8月'
      },
      {
        id: 9,
        text: '9月'
      },
      {
        id: 10,
        text: '10月'
      },
      {
        id: 11,
        text: '11月'
      },
      {
        id: 12,
        text: '12月'
      },
      {
        id: 1,
        text: '1月'
      },
    ],
    'sem2': [ 
      {
        id: 2,
        text: '2月'
      },
      {
        id: 3,
        text: '3月'
      },
      {
        id: 4,
        text: '4月'
      },
      {
        id: 5,
        text: '5月'
      },
      {
        id: 6,
        text: '6月'
      },
      {
        id: 7,
        text: '7月'
      },
    ]
  };
const neverfluorheader: Row[] = [
    {
      cells: [
        // {
        //   value: '',
        //   style: { border: fullBorderStyle },
        // },
        {
          value: '年級',
          style: { border: fullBorderStyle },
        },
        {
          value: '班級',
          style: { border: fullBorderStyle },
        },
        {
          value: '座號',
          style: { border: fullBorderStyle },
        },
        {
          value: '學生',
          style: { border: fullBorderStyle },
        },
      ],
    },
  ]; 
const fluorheader: Row[] = [
    {
      cells: [
        // {
        //   value: '',
        //   style: { border: fullBorderStyle },
        // },
        {
          value: '次',
          style: { border: fullBorderStyle },
        },
        {
          value: '實施日期',
          style: { border: fullBorderStyle },
        },
      ],
    },
  ];  
type classFluorCount = {
  className: string;
  times: number;
};
  
type classFluorSetting = Nullable<classFluorCount> & EditableProperties;
const classheader: HeaderDisplayKeysWithType<classFluorSetting>[] = [
  { property: 'className', display: '班級' },
  { property: 'times', display: '實施次數' },
  ];
const transformTextInput = (
  property: string,
  v: unknown,
  i: number,
  e?: boolean
) => (e ? <TextField property={property} /> : wrapOverflow(v as string));
const TeeFluorLackHeader: (SheetHeaderDisplay<MetricPageData> & 
  HeaderDisplayKeysWithType<MetricPageData>)[] = [
  { property: 'year', display: '學年' },
  { property: 'sem', display: '學期' },
  { property: 'pid', display: '統編' },
  { property: 'name', display: '學生' },
  { property: 'grade', display: '年級' },
  { property: 'no', display: '班級' },
  { property: 'seat', display: '座號' },
  { 
    property: 'lackCount',
    display: '未實施次數',
    onRender: transformTextInput.bind(null, 'lackCount'),
  }
];
const TeeFluorNerverParticipateHeader: (SheetHeaderDisplay<NeverFluorStudent> & 
  HeaderDisplayKeysWithType<NeverFluorStudent>)[] = [
  { property: 'grade', display: '年級' },
  { property: 'no', display: '班級' },
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
];
const TeeFluorHeader: (SheetHeaderDisplay<StudentFluor> & 
  HeaderDisplayKeysWithType<StudentFluor>)[] = [
  { property: 'grade', display: '年級' },
  { property: 'no', display: '班級' },
  { property: 'seat', display: '座號' },
  { property: 'name', display: '學生' },
];
function wrapOverflow(content: ReactNode) {
  return <OverflowWithTooltip>{content}</OverflowWithTooltip>;
}
const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;
type MetricPageData = StudentGradeNoSeat & TeeFluorLack;

const fluorSetting: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
  }) => {
    const [month, setMonth] = useState<number>();
    const [monthText, setMonthText] = useState<string>('');
    const [dates, setDates] = useState <string[]>([]);
    const [date, setDate] = useState<number>();
    const [count, setCount] = useState<number>();
    const currentSem = user.semesters[user.currentSemester];
    const semMonths = currentSem.sem == 1 ? monthsObj['sem1'] : monthsObj['sem2'];
    const [fluordatas, setFluorDatas] = useState<Fluor>([]);
    const [classes, setClasses] = useState<ClassMapObj>({ '': '無年級資料' });
    const [classList, setClassList] = useState([] as Class[]);
    const [isClick, setIsClick] = useState<boolean>();
    const [isClickNever, setIsClickNever] = useState<boolean>();
    const [isClickSFluor, setIsClickSFluor] = useState<boolean>();
    const [neverfluordatas, setNeverfluorDatas] = useState([] as NeverFluorStudent[]);
    const [studentfluordatas, setStudentfluorDatas] = useState([] as StudentFluor[]);
    const [studentsFluorLack, setStudentFluorLack] = useState([] as MetricPageData[]);  
    const studentInputRef = useRef<HTMLInputElement>(null);
    const teeLackInputRef = useRef<HTMLInputElement>(null);
    const [insertBtn, setInsertBtn] = useState<boolean>(true);
    const [sid, setSid] = useState<string>('');
    const [pid, setPid] = useState<string>('');
    const [grade, setGrade] = useState<number>();
    const [no, setNo] = useState<number>();
    const [seat, setSeat] = useState<number>();
    const [checkField, setCheckField] = useState<number>(0);
    const ref = useRef<HTMLInputElement>(null);
    const [neverFluorDeleting, setNeverFluorDeleting] = useState(false);
    const [deletingNeverFluorStudent, setDeletingNeverFluorStudent] = useState<NeverFluorStudent>();
    const [studentFluorDeleting, setStudentFluorDeleting] = useState(false);
    const [deletingStudentFluor, setDeletingStudentFluor] = useState<StudentFluor>();
    const [fluorSettings, setFluorSettings] = useState<classFluorCount[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<Set<number>>(
      new Set<number>()
    );
    const [pageName, setPageName] = useState('settings');
    const fluorSettingData = useMemo(refreshSettings, [
      selectedIndex, fluorSettings
    ]);
   
    useEffect(() => {
        if(month) {
          const currentYear = new Date().getFullYear();
          const daysInMonth = new Date(currentYear, month, 0).getDate();
          setDates(Array.from({length: daysInMonth}, (v, i) => i + 1 + '日'));
        }
      }, [month]);

    useEffect(() => {
        apis.getFluorList().then((s) => {
            setFluorDatas(s);
          });
        apis
          .getClasses(currentSem.year, currentSem.sem)
          .then((classes) => {
            if (classes.length)
              setClassList(classes);
              setClasses(
                listToObject(
                  classes,
                  (c) => c.id,
                  (c) => c.name
                )
              );
          })
          .catch(catchErrorForModal);
    }, [isClick]); 

    useEffect(() => {
      if (classList.length) {
        const tableData = classList.map(x => ({
          className: x.name,
          times: fluordatas.length ? fluordatas[0].dates.length : 0,
        }));
        setFluorSettings(tableData);
      }
    }, [classList, fluordatas]);
    useEffect(() => {
      apis.getNeverFluorList(
        currentSem.year,
        currentSem.sem
      ).then((s) => {
          setNeverfluorDatas(s);
        });
    }, [isClickNever]); 
    useEffect(() => {
      apis.getStudentFluorList(
        currentSem.year,
        currentSem.sem
      ).then((s) => {
          const result = s.filter(t => t.lackCount === 0); // 本學期不參加者清單 不顯示含氟漱口水未實施者
          setStudentfluorDatas(result);
        });

      getStudentFluorLack();
    }, [isClickSFluor]); 
    function refreshSettings() {
      return fluorSettings.map((s, i) => ({
        ...s,
        selected: selectedIndex.has(i),
      }));
    }

    function getInsertData(year:number, month: number, date: number, count: number) {
      apis.updateFluorDate(year, month, date, count).then(
        (x) =>  {    
          setIsClick(!isClick);
      });
    }

    function CheckInsertBtn() {
      setInsertBtn(true);
      if (teeLackInputRef.current?.value) {
        if(studentInputRef.current?.value) {
            setInsertBtn(false); 
            CheckInput();
        }
      }
    }

    function CheckInput() {
      const inputStudent = studentInputRef.current?.value;
      
      // 檢查輸入身分證、學號或班級座號
      if(inputStudent) {
        if(inputStudent.substring(0, 1) == '=') {
          setSid(inputStudent.substring(1));
          setCheckField(2);
        } else {
          switch(inputStudent.length) {
            case 5:
              setGrade(parseInt(inputStudent.substring(0, 1)));
              setNo(parseInt(inputStudent.substring(1, 3)));
              setSeat(parseInt(inputStudent.substring(3, 5)));
              setCheckField(3);
              break;
            case 6:
              // 代入年級、班級、座號
              setGrade(parseInt(inputStudent.substring(0, 2)));
              setNo(parseInt(inputStudent.substring(2, 4)));
              setSeat(parseInt(inputStudent.substring(2, 4)));
              setCheckField(3);
              break;
            case 10:
            case 11:
            case 12:
              setPid(inputStudent);
              setCheckField(1);
              break;
          }
        }
      }
    }
    
    function insertTeeLack() {
      if(studentInputRef.current?.value) {
        toast
        .promise(
          apis.createStudentFlourLack(
              currentSem.year,
              currentSem.sem,
              sid,
              seat || 0,
              no || 0,
              grade || 0,
              pid,
              false, 
              teeLackInputRef.current?.value ? parseInt(teeLackInputRef.current?.value) : 0,
              checkField
          ),
          {
            pending: '資料新增中......',
            success: '新增成功！',
          }
        )
        .then((r) => {
          // 清空學號、未實施次數
          if(studentInputRef.current) {  
            studentInputRef.current.value = '';
          }
          if(teeLackInputRef.current) {  
            teeLackInputRef.current.value = '';
          }
          CheckInsertBtn(); // 按鈕disabled
          getStudentFluorLack();
        })
        .catch(catchErrorForModal);
      }
    }
    
    function onValueUpdate(value: TeeFluorLack) {
      apis
        .updateStudentFluorLack(
          value?.id,
          value?.participate,
          value?.lackCount,
          value?.fluorId,
          value?.studentId,
        )
        .then(getStudentFluorLack)
        .catch(catchErrorForModal);
    }

    function onValueDelete(value: TeeFluorLack) {
      apis
        .deleteStudentFluorLack(
          value?.id,
          value?.participate,
          value?.lackCount,
          value?.fluorId,
          value?.studentId,
        )
        .then(getStudentFluorLack)
        .catch(catchErrorForModal);
    }

    function onValueUpdateNeverFlour(value: NeverFluorStudent) {}

    function onValueDeleteNeverFlour(value: NeverFluorStudent) {
      setNeverFluorDeleting(true);
      setDeletingNeverFluorStudent(value);
    }

    function deleteNeverFluorStudent() {
      if(deletingNeverFluorStudent) {
        toast
        .promise(
          apis
            .deleteNeverFluor(
              deletingNeverFluorStudent?.id,
              deletingNeverFluorStudent?.studentId,
              deletingNeverFluorStudent?.schoolId,
            ),
            {
              pending: '資料刪除中......',
              success: '刪除成功！',
              error: '刪除失敗！請查看錯誤資訊。',
            }
          )
          .then(() => {
            onHideDeletingNeverFluorStudent();
            setIsClickNever(!isClickNever);
          })
          .catch(catchErrorForModal);
      }
    }

    function onHideDeletingNeverFluorStudent() {
      setNeverFluorDeleting(false);
      setDeletingNeverFluorStudent(undefined);
    }
    
    function onValueDeletStudentFlour(value: StudentFluor) {
      setStudentFluorDeleting(true);
      setDeletingStudentFluor(value);
    }

    function deleteStudentFlour() {
      if(deletingStudentFluor) {
        toast
        .promise(
          apis
          .deleteStudentFluorLack(
            deletingStudentFluor?.id,
            deletingStudentFluor?.participate,
            deletingStudentFluor?.lackCount,
            deletingStudentFluor?.fluorId,
            deletingStudentFluor?.studentId,
          ),
          {
            pending: '資料刪除中......',
            success: '刪除成功！',
            error: '刪除失敗！請查看錯誤資訊。',
          }
        )
        .then(() => {
          setIsClickSFluor(!isClickSFluor);
          onHideDeletingStudentFlour();
        })
        .catch(catchErrorForModal);
      }
    }

    function onHideDeletingStudentFlour() {
      setStudentFluorDeleting(false);
      setDeletingStudentFluor(undefined);
    }

    function getStudentFluorLack() {
      apis.getStudentFluorLackList(currentSem.year, currentSem.sem)
      .then((s) => {
        const result = s.filter(t => t.studentFluor.lackCount !== 0); // 含氟漱口水未實施清單 不顯示本學期不參加者
        setStudentFluorLack(result.map(({ studentFluor, ...m }) => ({
          ...studentFluor,
          ...m
        })));
      })
      .catch(catchErrorForModal);
    }

    function generateFluorSheet() {
      const classObj = [...selectedIndex].map(index => classList[index]);
      toast
        .promise(
          apis.downloadExcel(
            user.schools[user.currentSchool].name,
            fluordatas.length ? fluordatas[0].dates : [],
            classObj,
            currentSem.year, 
            currentSem.sem
          ), {
          pending: '下載中...',
          success: '下載成功！',
          error: '下載失敗！請查看錯誤資訊。',
        })
        .then((blob) => {
          const objlink = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = objlink;
          a.setAttribute('download', `含氟漱口水記錄表.xls`);
          document.body.appendChild(a);
          a.click();

          a.parentNode?.removeChild(a);
        })
        .catch(catchErrorForModal);
    }

    return (
      <AuthedLayout>
        <ul className="nav nav-tabs">
          <li>
            <summary
              className={`text-dark font-weight-bold wid-100 nav-link ${
                pageName == 'settings' ? 'active' : ''
              }`}
              onClick={() => setPageName('settings')}
            >
              實施設定
            </summary>
          </li>
          <li>
            <summary
              className={`text-dark font-weight-bold wid-100 nav-link ${
                pageName == 'noParticipant' ? 'active' : ''
              }`}
              onClick={() => setPageName('noParticipant')}
            >
              不參加
            </summary>
          </li>
          <li>
            <summary
              className={`text-dark font-weight-bold wid-100 nav-link ${
                pageName == 'lack' ? 'active' : ''
              }`}
              onClick={() => setPageName('lack')}
            >
              未實施
            </summary>
          </li>
        </ul>
        <div className="border-right border-left border-bottom p-1 py-2">
          {pageName == 'settings' ? (
            <>
              <BSRow noGutters className="align-items-center mt-3 mb-4">
                <Col sm={6} className='d-flex align-items-center'>
                    第一次實施日期：
                    <DropdownButton
                      className="mr-2"
                      title={monthText || '實施月'}
                      onSelect={(g) => {
                      if(g) {
                          const selectMonth = semMonths[parseInt(g)];
                          setMonth(selectMonth.id);
                          setMonthText(currentSem.year + (selectMonth.id == 1 ? 1 : 0) + '年' + selectMonth.text);
                      }
                      }}
                    >
                      {semMonths.map((month, idx) => (
                      <Dropdown.Item key={`month-${idx}`} eventKey={idx}>
                          {currentSem.year + (month.id == 1 ? 1 : 0)}年{month.text}
                      </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    <DropdownButton
                      className=""
                      title={date ? dates[date - 1] : '實施日' }
                      onSelect={(d) => {
                      if(d) {
                          setDate(parseInt(d));
                      }
                      }}
                    >
                      {dates.map((d, idx) => (
                          <Dropdown.Item key={`date-${idx + 1}`} eventKey={idx + 1}>
                          {d}
                          </Dropdown.Item>
                      ))}
                    </DropdownButton>
                </Col>
                <Col sm={6} className='d-flex align-items-center'>
                  實施次數：
                  <DropdownButton
                      className="mr-2"
                      title={count ? count : '1'}
                      onSelect={(g) => {
                      if(g) {
                          setCount(parseInt(g));
                      }
                      }}
                  >
                      {Array.from({length: 23}, (v, i) => i+1).map((d, idx) => (
                              <Dropdown.Item key={`date-${idx + 1}`} eventKey={idx + 1}>
                              {d}
                              </Dropdown.Item>
                      ))}
                  </DropdownButton>
                  <Button
                    disabled={!date}
                    onClick={() => getInsertData(currentSem.year + (month == 1 ? 1 : 0), month || 1, date || 1, count || 1)}
                  >
                    設定日期及週數
                  </Button>
                </Col>
                <Col>
                  <Button
                    className='mt-2'
                    disabled={!selectedIndex.size}
                    onClick={generateFluorSheet}
                  >
                    產生記錄表
                  </Button>
                </Col>
              </BSRow>
              <BSRow noGutters className='align-items-start'>
                <Col sm={6}>
                  <EditableTableDiv
                    headers={classheader}
                    values={fluorSettingData}
                    onSelected={(s) => {
                      setSelectedIndex((last) => {
                        if (s.index !== undefined) {
                          if (s.checked) last.add(s.index);
                          else last.delete(s.index);
                          return new Set(last);
                        } else {
                          if (s.checked) return new Set(fluorSettings.keys());
                          else return new Set();
                        }
                      });
                    }}
                  />
                </Col>
                <Col sm={5} className='ml-2'>
                    <Table
                      striped
                      bordered
                      className="text-center"
                      style={{ tableLayout: 'fixed' }}
                    >
                      <tbody>
                          {fluorheader.map((r, i) => {
                          const cells = r.cells.map((c, j) =>
                              c.value != undefined ? (
                              <td
                                  colSpan={c.merge?.column}
                                  rowSpan={c.merge?.row}
                                  key={`${i}-${j}`}
                              >
                                  {c.value}
                              </td>
                              ) : (
                              <Fragment key={`${i}-${j}`}></Fragment>
                              )
                          );
                          return <tr key={i}>{cells}</tr>;
                          })}

                          {fluordatas.map((fluor) => (
                              fluor.dates.map((r,index)=>
                                  <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{r.split('T')[0]}</td>
                                  </tr>
                              )
                          ))}

                      </tbody>
                    </Table>
                </Col>
              </BSRow>
            </>
          ) : (
            pageName == 'noParticipant' ? (
              <>
                <BSRow noGutters className="mt-3 mb-4">
                  學生身份證：
                  <Col xs={2} >
                    <FormControl ref={ref} />
                  </Col>
                  <Col xs={7} className="ml-2">
                    <Button
                      onClick={() => {
                          if (ref.current && ref.current?.value.length > 5)
                          {
                            apis.createNeverFluor(ref.current?.value)
                              .then((x) =>  {
                                getStudentFluorLack();
                                setIsClickNever(!isClickNever);
                                if(ref.current) {
                                  ref.current.value = "";
                                }
                              })
                              .catch(catchErrorForModal);
                          }
                      }}
                    >
                      新增永不參加名單
                    </Button>
                    <Button className="ml-2"
                      onClick={() => {
                          if (ref.current && ref.current?.value.length > 5)
                          {
                            apis.createStudentFluor(ref.current?.value,false,0).then(
                            (x) =>  {    
                              setIsClickSFluor(!isClickSFluor);
                          }).catch(catchErrorForModal);
                          }
                      }}
                    >
                      新增本學期不參加名單
                    </Button>
                  </Col>
                </BSRow>
                <BSRow noGutters className='align-items-start'>
                  <Col xs={5}>
                    永不同意參加者
                    <EditableTableDiv
                      deleteable
                      headers={TeeFluorNerverParticipateHeader}
                      values={neverfluordatas}
                      onDelete={onValueDeleteNeverFlour}
                    />
                  </Col>
                  <Col xs={5} className='ml-2'>
                    本學期不參加者
                    <EditableTableDiv
                      deleteable
                      headers={TeeFluorHeader}
                      values={studentfluordatas}
                      onDelete={onValueDeletStudentFlour}
                    />
                  </Col>
                </BSRow>
              </>
            ) :
            (
              <>
                <BSRow noGutters className='mt-3'>
                  <div>
                    <span>請輸入年級班級座號或身分證或學號(學號前請加=)：</span><br />
                    <span className="text-primary">例如:國小三年級1班3號，應寫作「30103」</span>
                  </div>
                  <Col xs={2} className="mr-2">
                    <FormControl 
                      type="string"
                      aria-label="studentInput"
                      aria-describedby="basic-addon1"
                      ref={studentInputRef}
                      onChange={CheckInsertBtn}
                    />
                  </Col>
                  未實施次數：
                  <Col xs={2} className="mr-2">
                    <FormControl 
                    type="number"
                    aria-label="teeUnCheckInput"
                    aria-describedby="basic-addon1"
                    ref={teeLackInputRef}
                    onChange={CheckInsertBtn}
                    />
                  </Col>
                  <Col xs={1}>
                    <Button
                      disabled={insertBtn}
                      onClick={insertTeeLack}
                    >
                      新增
                    </Button>
                  </Col>
                  <Col xs={12} className='mt-4'>
                    含氟漱口水未實施
                    <InlineEditableTable
                      values={studentsFluorLack}
                      headers={TeeFluorLackHeader}
                      onValueUpdate={onValueUpdate}
                      onValueDelete={onValueDelete}
                      onRowRender={(v) => ({
                        editable: true,
                        deleteable: true,
                      })}
                    />
                  </Col>
                </BSRow>
              </>
            )
          )}
        </div>
        <Modal
          show={neverFluorDeleting}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          >
          <Modal.Header closeButton onHide={onHideDeletingNeverFluorStudent}>
              <Modal.Title id="contained-modal-title-vcenter">刪除</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Container>
                  <BSRow className='mb-3'>
                      <Col>您確定要刪除嗎?</Col>
                  </BSRow>
              </Container>
          </Modal.Body>
          <Modal.Footer>
              <Button type="submit" variant="danger" onClick={deleteNeverFluorStudent}>
              確認
              </Button>
              <Button type="reset" variant="secondary" onClick={onHideDeletingNeverFluorStudent}>
              取消
              </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={studentFluorDeleting}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered
          >
          <Modal.Header closeButton onHide={onHideDeletingStudentFlour}>
              <Modal.Title id="contained-modal-title-vcenter">刪除</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Container>
                  <BSRow className='mb-3'>
                      <Col>您確定要刪除嗎?</Col>
                  </BSRow>
              </Container>
          </Modal.Body>
          <Modal.Footer>
              <Button type="submit" variant="danger" onClick={deleteStudentFlour}>
              確認
              </Button>
              <Button type="reset" variant="secondary" onClick={onHideDeletingStudentFlour}>
              取消
              </Button>
          </Modal.Footer>
        </Modal>
      </AuthedLayout>
    );
  };


   
  export const FluorSetting = connector(fluorSetting);
