import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { AuthedLayout } from '../../../components';
import { useSemGrade } from '../../../hook';
import { ClassSightDiagrate2Statistic, School } from '../../../model';
import apis from '../../../apis';
import {
  Cell,
  Row,
  downloadSheets,
  generateSheet,
  listToObject,
} from '../../../utils';
import XLSX from 'xlsx-js-style';
type sightdiagrate2 = {
  grade: number;
  classNo: number;
  allCount: number;
  badCount: number;
  badRate?: number; // badCount / allCount
  referralCount: number;
  referralRate?: number; // referralCount / badCount
};

const mockData: sightdiagrate2[] = [
  {
    grade: 1,
    classNo: 1,
    allCount: 66,
    badCount: 40,
    referralCount: 39,
  },
  {
    grade: 4,
    classNo: 5,
    allCount: 40,
    badCount: 24,
    referralCount: 23,
  },
  {
    grade: 4,
    classNo: 9,
    allCount: 71,
    badCount: 38,
    referralCount: 37,
  },
];
const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as const;
const bottomBorderStyle = {
  bottom: borderStyle,
};
const topBottomBorderStyle = {
  top: borderStyle,
  bottom: borderStyle,
};
const cellAlignStyle = {
  horizontal: 'left',
  vertical: 'center',
  wrapText: true,
} as const;

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sightdiagrate2: FunctionComponent<Props> = ({ catchErrorForModal }) => {
  const { yearSem, element } = useSemGrade();
  const [datas, setSightDiagrate2] = useState<ClassSightDiagrate2Statistic[]>(
    []
  );
  const [school, setSchool] = useState<School>();
  useEffect(() => {
    if (yearSem) {
      apis
        .getSightDiagrate2ClassStatistic(yearSem.year, yearSem.sem)
        .then((r) => {
          setSightDiagrate2(r);
        })
        .catch(catchErrorForModal);
      apis.getCurrentSchool()
        .then((school) => {
          setSchool(school);
        })
        .catch(catchErrorForModal);
    }
  }, [yearSem?.year, yearSem?.sem]);

  // 計算年級小計、全校合計
  let schoolAllCount = 0;
  let schoolBadCount = 0;
  let schoolReferralCount = 0;
  let schoolBadRate = 0;
  let schoolReferralRate = 0;

  const gradeBase = datas.reduce((grades, d) => {
    // 計算不良率、就醫率
    d.badRate = (d.badCount == 0 || d.allCount == 0 ? 0 : ((d.badCount / d.allCount) * 100))
    d.referralRate = d.referralCount == 0 || d.badCount == 0 ? 0 : ((d.referralCount / d.badCount) * 100)
    return { ...grades, [d.grade]: [...(grades[d.grade] || []), d] };
  }, {} as Record<number, sightdiagrate2[]>);

  // 組成table cell
  let countRow = 1;
  const contentRows: Row[] = Object.values(gradeBase).flatMap(
    (gs, idx, allArray) => {
      const classSight = {
        grade: 0,
        classNo: 0,
        allCount: 0,
        badCount: 0,
        badRate: 0,
        referralCount: 0,
        referralRate: 0,
      };
      // 計算資料筆數
      countRow++;

      // 同一年級下 loop每個班級
      const rows = gs.flatMap((g, gidx) => {
        // 年級小計
        classSight.grade = g.grade;
        classSight.allCount += g.allCount;
        classSight.badCount += g.badCount;
        classSight.referralCount += g.referralCount;

        // 全校合計
        schoolAllCount += g.allCount;
        schoolBadCount += g.badCount;
        schoolReferralCount += g.referralCount!;
        schoolBadRate = (schoolBadCount / schoolAllCount) * 100;
        schoolReferralRate = (schoolReferralCount / schoolBadCount) * 100;

        return sightToRow(
          g.grade,
          g.classNo,
          g.allCount,
          g.badCount,
          g.badRate!,
          g.referralCount,
          g.referralRate!
        );
      });

      // 插入年級小計
      classSight.badRate! = classSight.badCount == 0 || classSight.allCount == 0 ? 0 : (classSight.badCount! / classSight.allCount) * 100;
      classSight.referralRate! = classSight.referralCount == 0 || classSight.badCount == 0 ? 0 : (classSight.referralCount! / classSight.badCount!) * 100;
      rows.push(
        ...sightToRow(
          classSight.grade,
          classSight.classNo,
          classSight.allCount,
          classSight.badCount,
          classSight.badRate!,
          classSight.referralCount,
          classSight.referralRate!
        )
      );

      // 若為最後一筆資料 插入全校小計
      if (countRow == datas.length + 1) {
        rows.push(
          ...sightToRow(
            0,
            0,
            schoolAllCount,
            schoolBadCount,
            schoolBadRate,
            schoolReferralCount,
            schoolReferralRate
          )
        );
      }

      return rows;
    }
  );
  const content: Row[] = [
    {
      cells: [
        {
          value: `${school?.name}學校${yearSem?.year}學年第${yearSem?.sem}學期_裸視視力不良就醫率統計表(含戴鏡正常)`,
          merge: { column: 7 },
        },
      ],
    },
    {
      cells: [
        {
          value: '一、合計人數不含視力之未測量（663 筆）及無法測量（0 筆）。',
          merge: { column: 7 },
        },
      ],
    },
    {
      cells: [
        {
          value:
            '二、C：裸眼視力受檢人數　B：裸眼視力不良人數(含戴鏡視力正常)　Ａ：分母中有診斷的人數',
          merge: { column: 7 },
        },
      ],
    },
    {
      cells: [
        {
          value: '年級',
          style: { border: bottomBorderStyle },
        },
        {
          value: '班級',
          style: { border: bottomBorderStyle },
        },
        {
          value: '裸眼視力受檢人數C',
          style: { border: bottomBorderStyle },
        },
        {
          value: '裸眼視力不良人數B',
          style: { border: bottomBorderStyle },
        },
        {
          value: '不良率(B/C)',
          style: { border: bottomBorderStyle },
        },
        {
          value: '就診人數(A)',
          style: { border: bottomBorderStyle },
        },
        {
          value: '就診率(A/B)',
          style: { border: bottomBorderStyle },
        },
      ],
    },
    ...contentRows,
    {
      cells: [
        {
          value: '承辦人:　　　　',
        },
        {
          value: '組長:　　　　',
        },
        {
          value: '主任:　　　　',
        },
        {
          value: '校長:　　　　',
        },
      ],
    },
  ];

  const workSheet = generateSheet(content, { alignment: cellAlignStyle });

  return (
    <AuthedLayout>
      <BSRow className="justify-content-between">
        <span className="mr-3">{element}</span>
        <Button
          disabled={!datas.length}
          variant="success"
          className="text-dark"
          onClick={() => {
            downloadSheets('裸視視力不良就醫率統計表', {
              sheet: workSheet,
              name: '工作表名稱',
            });
          }}
        >
          Excel下載
        </Button>
      </BSRow>
      <BSRow>
        <Table>
          <tbody>
            <tr className="border-bottom border-top-0">
              <td>年級</td>
              <td>班級</td>
              <td>裸眼視力受檢人數C</td>
              <td>裸眼視力不良人數B</td>
              <td>不良率(B/C)</td>
              <td>就診人數A</td>
              <td>就診率(A/B)</td>
            </tr>
            {contentRows.map((r, i) => {
              const cells = r.cells.map((c, j) =>
                c.value != undefined ? (
                  <td
                    colSpan={c.merge?.column}
                    key={`${i}-${j}`}
                    className="border-0"
                  >
                    {c.value}
                  </td>
                ) : (
                  <Fragment key={`${i}-${j}`}></Fragment>
                )
              );
              const hasBorder = r.cells[0].merge?.column || 0;
              return (
                <tr
                  key={i}
                  className={
                    hasBorder ? 'border-bottom border-top-0' : 'border-0'
                  }
                >
                  {cells}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </BSRow>
    </AuthedLayout>
  );
};

function sightToRow(
  grade: number,
  classNo: number,
  allCount: number,
  badCount: number,
  badRate: number, // badCount / allCount
  referralCount: number,
  referralRate: number // referralCount / badCount
): Row[] {
  let gradeClass = [
    {
      value: grade,
    },
    {
      value: classNo,
    },
  ] as Cell[];
  let styleCss = {};

  if (grade == 0) {
    // 全校合計
    styleCss = { border: bottomBorderStyle };
    gradeClass = [
      {
        value: '全校合計',
        merge: { column: 2 },
        style: { border: bottomBorderStyle },
      },
    ];
  } else if (classNo == 0) {
    // 年級小計
    styleCss = { border: topBottomBorderStyle };
    gradeClass = [
      {
        value: grade + '年級小計',
        merge: { column: 2 },
        style: styleCss,
      },
    ];
  }

  return [
    {
      cells: [
        ...gradeClass,
        {
          value: allCount,
          style: styleCss,
        },
        {
          value: badCount,
          style: styleCss,
        },
        {
          value: badRate.toFixed(2) + '%',
          style: styleCss,
        },
        {
          value: referralCount,
          style: styleCss,
        },
        {
          value: referralRate.toFixed(2) + '%',
          style: styleCss,
        },
      ],
    },
  ];
}

export const SightDiagrate2 = connector(sightdiagrate2);
