import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import { Button, Form, FormCheck, Row } from 'react-bootstrap';
import XLSX from 'xlsx';
import { z } from 'zod';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  EditableTable,
  EditableTableDiv,
  EnchanceLink,
  HeaderDisplayKeysWithType,
} from '../components';
import Apis from '../apis';
import {
  PhiPhysicalFile,
  PhiPhysicalFileZod,
  SchoolRank,
  CheckField,
  PhiPhysical,
} from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { toast } from 'react-toastify';
import { I18N } from '../i18n-raw';
import apis from '../apis';
import { getPhysicalGradeByRank } from '../utils/grade';
import {
  CheckShouldUseIdentify,
  assignExists,
  takeClassStudent,
} from '../utils';
import { useActiveSems } from '../hook';

type BodySetting = {
  year: number;
  grade: number;
  setup: boolean;
  body: boolean;
  bodyAdditional?: string[];
};

const physicalSettingHeader: HeaderDisplayKeysWithType<BodySetting>[] = [
  { display: '學年', property: 'year', style: { width: '10%' } },
  { display: '年級', property: 'grade', style: { width: '10%' } },
  {
    display: '身體診察受檢',
    property: 'body',
    style: { width: '20%' },
    onRender: (v) => <FormCheck checked={!!v} readOnly type="checkbox" />,
  },
  {
    display: '身體診察追加',
    property: 'bodyAdditional',
    style: { width: '60%' },
    onRender: (v) => {
      if (v === undefined)
        return (
          <>
            尚未設定，
            <EnchanceLink to="/settings/physical">
              點擊此處進行設定
            </EnchanceLink>
          </>
        );
      if (!Array.isArray(v)) return v;
      if (v.length === 0) return '無追加診察';
      return v.map((s) => I18N.PhysicalBodyAdditional[s] ?? '').join(', ');
    },
  },
];

const inputFileParser = z.array(PhiPhysicalFileZod);
const headerDisplayKeys: HeaderDisplayKeysWithType<PhiPhysicalFile>[] = [
  {
    display: '備註',
    property: 'remark',
    onRender: (value, idx, editing, context) => {
      let warn = context.allValues.warn;
      if (!value) {
        warn = warn.slice(0, -1);
      }
      return warn + value;
    },
  },
  { display: '身份證字號', property: 'pid' },
  { display: '學號', property: 'sid' },
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { property: 'sbp', display: '收縮壓' },
  { property: 'dbp', display: '舒張壓' },
  { property: 'bpResult', display: '血壓判讀' },
  { property: 'waistline', display: '腰圍' },
  { property: 'pulse', display: '脈搏' },
  { property: 'pulseResult', display: '脈搏判讀' },
  { property: 'colorBlind', display: '辨色力異常' },
  { property: 'e99', display: '眼其他' },
  { property: 'e99State', display: '眼其他陳述' },
  { property: 'hearing', display: '聽力異常' },
  { property: 'hearingLR', display: '聽力異常左右' },
  { property: 'earMisshapen', display: '耳道畸型' },
  { property: 'earMisshapenLR', display: '耳道畸型左右' },
  { property: 'eardrum', display: '耳膜破損' },
  { property: 'eardrumLR', display: '耳膜破損左右' },
  { property: 'cerumen', display: '耵聹栓塞' },
  { property: 'cerumenLR', display: '耵聹栓塞左右' },
  { property: 'tonsillitis', display: '扁桃腺腫大' },
  { property: 'o99', display: '耳鼻喉其他' },
  { property: 'o99State', display: '耳鼻喉其他' },
  { property: 'torticollis', display: '斜頸' },
  { property: 'mass', display: '異常腫塊' },
  { property: 'goiter', display: '甲狀腺腫' },
  { property: 'lymphadenectasis', display: '淋巴腺腫大' },
  { property: 'mass99', display: '其他異常腫塊' },
  { property: 'n99', display: '頭頸其他' },
  { property: 'n99State', display: '頭頸其他陳述' },
  { property: 'cardiopulmonary', display: '心肺疾病' },
  { property: 'heartMurmur', display: '心雜音' },
  { property: 'arrhythmia', display: '心律不整' },
  { property: 'wheeze', display: '呼吸聲異常' },
  { property: 'cardiopulmonary99', display: '其他心肺疾病' },
  { property: 'thorax', display: '胸廓異常' },
  { property: 'c99', display: '胸部其他' },
  { property: 'c99State', display: '胸部其他陳述' },
  { property: 'abdomen', display: '腹部異常腫大' },
  { property: 'a99', display: '腹部其他' },
  { property: 'a99State', display: '腹部其他陳述' },
  { property: 'scoliosis', display: '脊柱側彎' },
  { property: 'dysmelia', display: '肢體畸形' },
  { property: 'polydactyly', display: '多併指 趾' },
  { property: 'dysarthrosis', display: '關節變形' },
  { property: 'dysmelia99', display: '其他肢體畸形' },
  { property: 'squatting', display: '蹲踞困難' },
  { property: 'l99', display: '脊柱四肢其他' },
  { property: 'l99State', display: '脊柱四肢其他陳述' },
  { property: 'cryptorchidism', display: '隱睪' },
  { property: 'prepuce', display: '包皮異常' },
  { property: 'varicocele', display: '精索靜脈曲張' },
  { property: 'u99', display: '泌尿生殖其他' },
  { property: 'u99State', display: '泌尿生殖其他陳述' },
  { property: 'epidermophytosis', display: '癬' },
  { property: 'scabies', display: '疥瘡' },
  { property: 'wart', display: '疣' },
  { property: 'atopicDermatitis', display: '異位性皮膚炎' },
  { property: 'eczema', display: '溼疹' },
  { property: 'd99', display: '皮膚其他' },
  { property: 'd99State', display: '皮膚其他陳述' },
  { property: 't01', display: '未治療齲齒' },
  { property: 't02', display: '缺牙' },
  { property: 't03', display: '待拔牙' },
  { property: 't04', display: '口腔衛生不良' },
  { property: 't05', display: '牙結石' },
  // { property: 't06', display: '牙週炎' },
  { property: 't07', display: '咬合不正' },
  { property: 't08', display: '牙齦炎' },
  { property: 't99', display: '口腔其他' },
  { property: 't99state', display: '口腔其他描述' },
  { property: 'tSheet', display: '口檢表' },
  { property: 't09', display: '口腔黏膜異常' },
  { property: 't11', display: '已治療齲齒' },
  { property: 't12', display: '上顎恆牙第一大臼齒齲齒經驗' },
  { property: 't13', display: '下顎恆牙第一大臼齒齲齒經驗' },
  { property: 't15', display: '恆牙臼齒窩溝封填' },
  { property: 't16', display: '牙周病' },
  { property: 't17', display: '乳牙待拔牙' },
  { property: 't18', display: '贅生牙' },
  { property: 't19', display: '阻生牙' },
  { property: 'ret99state', display: '複檢口腔其他描述' },
  { property: 'retSheet', display: '複檢口檢表' },
  { property: 'strabismus', display: '斜視' },
  { property: 'strabismusType', display: '斜視類型' },
  { property: 'trichiasis', display: '睫毛倒插' },
  { property: 'nystagmus', display: '眼球震顫' },
  { property: 'blepharoptosis', display: '眼瞼下垂' },
  { property: 'clp', display: '唇顎裂' },
  { property: 'articulationDisorders', display: '構音異常' },
  { property: 'preauricularFistula', display: '耳前瘻管' },
  { property: 'preauricularFistulaLR', display: '耳前瘻管左右' },
  { property: 'rhinitis', display: '慢性鼻炎' },
  { property: 'allergicRhinitis', display: '過敏性鼻炎' },
  { property: 'hernia', display: '疝氣' },
  { property: 'edema', display: '水腫' },
  { property: 'scrotalSwelling', display: '陰囊腫大' },
  { property: 'purpura', display: '紫斑' },
  // {
  //   display: '檢查日期',
  //   property: 'examDate',
  //   onRender: (v) => {
  //     return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
  //   },
  // },
];
const validHeaders = ['身分證', '學號', '年級班級座號', '收縮壓', '舒張壓', '血壓判讀', '腰圍', '脈搏', '脈搏判讀', 
  '辨色力異常', '眼其他', '眼其他陳述', '聽力異常', '聽力異常左右', '耳道畸型', '耳道畸型左右', 
  '耳膜破損', '耳膜破損左右', '耵聹栓塞', '耵聹栓塞左右', '扁桃腺腫大', '耳鼻喉其他', '耳鼻喉其他陳述', 
  '斜頸', '異常腫塊', '甲狀腺腫', '淋巴腺腫大', '其他異常腫塊', '頭頸其他', '頭頸其他陳述', 
  '心肺疾病', '心雜音', '心律不整', '呼吸聲異常', '其他心肺疾病', '胸廓異常', '胸部其他', 
  '胸部其他陳述', '腹部異常腫大', '腹部其他', '腹部其他陳述', '脊柱側彎', '肢體畸形', '多併指（趾）', 
  '關節變形', '其他肢體畸形', '蹲踞困難', '脊柱四肢其他', '脊柱四肢其他陳述', '隱睪', '包皮異常', 
  '精索靜脈曲張', '泌尿生殖其他', '泌尿生殖其他陳述', '癬', '疥瘡', '疣', '異位性皮膚炎', 
  '溼疹', '皮膚其他', '皮膚其他陳述', '未治療齲齒', '已治療齲齒', '上顎恆牙第一大臼齒齲齒經驗', 
  '下顎恆牙第一大臼齒齲齒經驗', '恆牙臼齒窩溝封填', '口腔衛生不良', '牙結石', '牙齦炎', '咬合不正', 
  '口腔黏膜異常', '牙周病', '乳牙待拔牙', '待拔牙', '贅生牙', '缺牙', '阻生牙', '口腔其他', 
  '口腔其他陳述', '口檢表', '複檢口腔其他陳述', '複檢口檢表', '斜視', '斜視類型', '睫毛倒插', 
  '眼球震顫', '眼瞼下垂', '唇顎裂', '構音異常', '耳前瘻管', '耳前瘻管左右', '慢性鼻炎', 
  '過敏性鼻炎', '疝氣', '水腫', '陰囊腫大', '紫斑'];

const AdditionalEffectFields: Record<string, Readonly<string[]>> = {
  '0': ['strabismus', 'strabismusType'] as const,
  '1': ['trichiasis'] as const,
  '2': ['nystagmus'] as const,
  '3': ['blepharoptosis'] as const,
  '4': ['clp'] as const,
  '5': ['articulationDisorders'] as const,
  '6': ['preauricularFistula', 'preauricularFistulaLR'] as const,
  '7': ['rhinitis'] as const,
  '8': ['allergicRhinitis'] as const,
  '9': ['edema'] as const,
  a: ['hernia'] as const,
  b: ['scrotalSwelling'] as const,
  c: ['purpura'] as const,
  d: ['cryptorchidism'] as const,
  e: ['t09'] as const,
  f: ['earMisshapen', 'earMisshapenLR'] as const,
  g: ['eardrum', 'eardrumLR'] as const,
  h: ['cerumen', 'cerumenLR'] as const,
  i: ['tonsillitis'] as const,
  j: ['t15'] as const,
} as const;

function filterOutNonPhiPhysicalField({
  remark,
  ...phiPhysical
}: PhiPhysicalFile): PhiPhysical {
  return phiPhysical;
}

function applyDefaultValue(
  data: PhiPhysicalFile[],
  gradeAdditional: Record<string, string[]>
): PhiPhysicalFile[] {
  const gradeNotAdditional = Object.entries(gradeAdditional).reduce(
    (pv, [key, values]) => {
      pv[key] = Object.values(AdditionalEffectFields)
        .flatMap((v) => v)
        .filter((v) => values.indexOf(v) === -1);
      return pv;
    },
    {} as Record<string, string[]>
  );
  data.forEach((physicalItem) => {
    gradeAdditional[physicalItem.grade ?? '']?.forEach((s) => {
      // @ts-ignore
      physicalItem[s] = physicalItem[s] ?? '0';
    });
    gradeNotAdditional[physicalItem.grade ?? '']?.forEach((s) => {
      // @ts-ignore
      physicalItem[s] = physicalItem[s] ?? '9';
    });
    return physicalItem;
  });

  return data;
}

function checkOkToUpload(okUpload: boolean, data: PhiPhysicalFile[]): boolean {
  if (okUpload) {
    const a = data.every(function (item) {
      return (
        item.remark === '' ||
        item.remark === '請確認【收縮壓】是否為正常值' ||
        item.remark === '請確認【舒張壓】是否為正常值' ||
        item.remark ===
          '請確認【收縮壓】是否為正常值，請確認【舒張壓】是否為正常值'
      );
    });
    return a;
  } else {
    return false;
  }
}

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatch = ErrorDispatches;
const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const importPhiPhysicalPage: FunctionComponent<Props> = ({
  user: { semesters, currentSemester, schools, currentSchool },
  catchErrorForModal,
  showError,
}) => {
  const physicalGradeRange = getPhysicalGradeByRank(
    schools[currentSchool].rank ?? SchoolRank.Junior
  );
  const [fileName, setFileName] = useState('檔案');
  const [fileData, setFileData] = useState([] as PhiPhysicalFile[]);
  const [physicalSettings, setPhysicalSettings] = useState<BodySetting[]>([]);
  const [gradeEffectedFields, setGradeEffectedFields] = useState<
    Record<number, string[]>
  >({});
  const [okToUpload, setOkToUpload] = useState(false);
  const [checkField, setCheckField] = useState(CheckField.None);

  const { selectedSemester, yearSemElement: yearSemElement } = useActiveSems(
    semesters,
    currentSemester
  );

  useEffect(() => {
    apis.getPhysicalSettings().then((s) => {
      const { year = -1 } = selectedSemester;
      const { rank } = schools[currentSchool || ''] ?? {};
      const grades = getPhysicalGradeByRank(rank || SchoolRank.Junior);
      const settings = grades.map((g): BodySetting => {
        const v = s.find((d) => d.grade === g);
        return {
          year,
          grade: g,
          setup: !!v,
          body: v?.body ?? false,
          bodyAdditional: v?.bodyAdditional,
        };
      });
      setPhysicalSettings(settings);
      setGradeEffectedFields(
        settings.reduce((pv, v) => {
          pv[v.grade] =
            v.bodyAdditional?.flatMap((b) => AdditionalEffectFields[b]) || [];
          return pv;
        }, {} as Record<number, string[]>)
      );
    });
  }, []);

  return (
    <AuthedLayout>
      <Row className="mb-2">匯入學期：{yearSemElement}</Row>
      <Row className="mb-2">
        <EditableTableDiv
          values={physicalSettings}
          headers={physicalSettingHeader}
          context={gradeEffectedFields}
        />
      </Row>
      <Row className="mb-2">
        下載匯入格式：
        <a
          href="/Doc/身體診察(含健檢口腔)20.xls"
          style={{ textDecoration: 'underline' }}
        >
          身體診察匯入格式
        </a>
      </Row>
      <Row className="mb-2">
        <Form.File
          type="file"
          className="my-file-label"
          id="inputGroupFile01"
          label={fileName}
          accept=".xlsx, .csv, .xls, .ods, .ots"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            e.persist();
            const { files } = e.target;
            try {
              if (files?.length === 1) {
                setFileName(files[0].name);
                const buf = await files[0].arrayBuffer();
                const workShop = XLSX.read(buf, {
                  type: 'buffer',
                  sheets: 0,
                });
                if (workShop.SheetNames[0]) {
                  const jsonData = XLSX.utils.sheet_to_json(
                    workShop.Sheets[workShop.SheetNames[0]],
                    { raw: false, rawNumbers: false }
                  );
                  const headerData = XLSX.utils.sheet_to_json(workShop.Sheets[workShop.SheetNames[0]], { header: 1 });
                  const headers = headerData[0] as string[];
                  const isCorrectFile = validHeaders.every((h: string, i: number) => h === headers[i]);
                  // 檢查表頭是否正確
                  if(!isCorrectFile) {
                    showError('Excel中的表頭欄位不正確，請確認是否選擇了正確的檔案。');
                    return;
                  }
                  const ss = await inputFileParser.parseAsync(jsonData);
                  if (ss) {
                    const [cf, matchFunc] = CheckShouldUseIdentify(ss);
                    setCheckField(cf);
                    if (cf == CheckField.None) {
                      showError(
                        '匯入之檔案應有唯一使用"身份證"或"學號"或"班級座號"'
                      );
                      return;
                    }
                    await Apis.checkClassesStudentExists(
                      takeClassStudent(ss),
                      cf,
                      selectedSemester
                    )
                      .then((result) => {
                        // setFileData (merge result&ss)
                        const students = ss.map((x) => {
                          if (x.tSheet != null) {
                            const matchArr = x.tSheet.match(/(\d+[A-Za-z]+)/g);
                            x.tSheet = matchArr ? matchArr.join(',') : x.tSheet;
                          }
                          if (x.retSheet != null) {
                            const matchArr =
                              x.retSheet.match(/(\d+[A-Za-z]+)/g);
                            x.retSheet = matchArr
                              ? matchArr.join(',')
                              : x.retSheet;
                          }
                          return x;
                        });
                        // 檢查是否為全身健檢年級的學生
                        ss.map((v, i) => {
                          if(v.grade && (!physicalGradeRange.includes(v.grade))) {
                            v.remark += `非全身健檢年級的學生	`;
                          }
                          return v;
                        })
                        setFileData(
                          assignExists(
                            matchFunc,
                            applyDefaultValue(students, gradeEffectedFields),
                            result
                          )
                        );
                        //check ss is okToUpload ,then setOkToUpload(true);
                        setOkToUpload(checkOkToUpload(true, students));
                      })
                      .catch((e) => {
                        catchErrorForModal(e);
                        setOkToUpload(false);
                      });
                  } else {
                    console.log(ss);
                  }
                }
              }
            } catch (e) {
              console.log('importPhiPhysical.ts #117', e);
              setFileName('檔案');
              setFileData([]);
              setOkToUpload(false);
              showError('檔案內容不正確，請檢查資料內容格式');
            }
            e.target.value = '';
          }}
          custom
        />
        <Button
          className="mr-0"
          disabled={!okToUpload}
          onClick={() => {
            if (fileData.length > 0 && checkField != CheckField.None)
              toast
                .promise(
                  Apis.insertPhiPhysical(
                    fileData.map(filterOutNonPhiPhysicalField),
                    checkField,
                    selectedSemester
                  ),
                  {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                  }
                )
                .then(() => {
                  setOkToUpload(false);
                })
                .catch(catchErrorForModal);
          }}
        >
          匯入資料
        </Button>
      </Row>
      <Row>
        <EditableTable
          headers={headerDisplayKeys}
          values={fileData}
          scrollable={true}
          onRowRender={({ examDate, remark, ...rest }) => ({
            rowClassName: remark ? 'bg-danger text-white' : '',
          })}
        />
      </Row>
    </AuthedLayout>
  );
};

export const ImportPhiPhysicalPage = connector(importPhiPhysicalPage);
