import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { FormCheck, FormControl, Overlay, Popover } from 'react-bootstrap';

type Props = {
  content: Record<string, string>;
  checked: string[];
  // onChange: (e: string[]) => void;
  onFocus: (e: FocusEvent<HTMLElement>) => void;
  onBlur: (e: FocusEvent<HTMLElement>) => void;
  onChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => void;
  deserialize?: (v: string) => string[];
  serialize?: (v: string[]) => string;
  sort?: (v: string[]) => string[];
};
export const DropdownCheckList: FunctionComponent<Props> = (
  {
    onChange,
    onBlur,
    onFocus,
    content,
    checked,
    deserialize = (v) => v.split(''),
    serialize = (v) => v.join(''),
    sort = (v) => v.sort(),
  } //   ...props
) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fadeTimerRef = useRef<NodeJS.Timeout | null>(null);

  function checkboxChange(key: string, c: boolean) {
    if (inputRef.current) {
      const checkedKeys = deserialize(inputRef.current.value);
      const keySet = new Set(checkedKeys);
      if (c) {
        keySet.add(key);
      } else if (!c) {
        keySet.delete(key);
      }
      const newDatas = sort([...keySet.values()]);
      const serialized = serialize(newDatas);

      inputRef.current.value = serialized;
      const e = new Event('change', { bubbles: true });
      inputRef.current.dispatchEvent(e);
      onChange(e as any);
    }
  }

  function filterValidChecked(contents: string[]) {
    return contents.filter((v) => !!content[v]);
  }

  function showOverlay() {
    if (fadeTimerRef.current) clearTimeout(fadeTimerRef.current);
    setShow(true);
  }

  function hideOverlay() {
    if (fadeTimerRef.current) clearTimeout(fadeTimerRef.current);
    fadeTimerRef.current = setTimeout(() => {
      setShow(false);
    }, 300);
  }

  const checkboxList = Object.entries(content).map(([k, v]) => (
    <FormCheck
      key={k}
      name={k}
      checked={checked?.some((s) => s === k)}
      label={`${k} - ${v}`}
      onChange={(e) => checkboxChange(k, e.target.checked)}
    />
  ));

  return (
    <React.Fragment>
      <div ref={containerRef}>
        <FormControl
          type="text"
          ref={inputRef}
          value={serialize(checked)}
          onChange={(e) => {
            // setInputDatas(e.target.value);
            onChange(e);
          }}
          onFocus={(e: any) => {
            showOverlay();
            onFocus(e);
          }}
          onBlur={(e: any) => {
            hideOverlay();
            onBlur(e);
          }}
        />
        <Overlay
          container={containerRef}
          target={containerRef.current}
          show={show}
          placement="bottom"
        >
          <Popover id={`input-dropdown-popover`}>
            <Popover.Content
              style={{ minWidth: '12.5rem' }}
              onBlur={() => hideOverlay()}
              onFocus={() => showOverlay()}
            >
              {checkboxList}
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </React.Fragment>
  );
};
