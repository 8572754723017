import React, { FunctionComponent, useMemo } from 'react';
import { DateTime } from 'luxon';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

export const DATETIME_FORMAT_WITH_TIME = 'YYYY/MM/DD HH:mm';
export const DATETIME_FORMAT = 'YYYY/MM/DD';

type Props = {
  className?: string;
  containerClass?: string;
  format?: typeof DATETIME_FORMAT_WITH_TIME | typeof DATETIME_FORMAT;
  start?: DateTime;
  end?: DateTime;
  disabled?: boolean;
  showTimeSelect?: boolean;
  value?: DateTime;
  onChange: (v: DateTime) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};
export const DateTimePicker: FunctionComponent<Props> = ({
  className,
  containerClass,
  format = DATETIME_FORMAT,
  start,
  end,
  disabled,
  showTimeSelect = false,
  value,
  onChange,
  onFocus,
  onBlur,
}) => {
  const current = useMemo(() => ToDateObject(value ?? DateTime.now()), []);
  const plugins = useMemo(
    () =>
      showTimeSelect
        ? [[<TimePicker key={'time'} format="HH:mm" position="bottom" />]]
        : undefined,
    [showTimeSelect]
  );
  return (
    <DatePicker
      disabled={disabled}
      containerClassName={containerClass}
      inputClass={['form-control', className].join(' ')}
      format={format}
      value={ToDateObject(value)}
      // minDate={start?.toJSDate()}
      // maxDate={end?.toJSDate()}
      locale={custom_locale_zh}
      currentDate={current}
      plugins={plugins}
      onChange={(d) => {
        if (d) {
          const dt = ToDateTime(d, showTimeSelect);
          if (dt.isValid) onChange(dt);
        }
      }}
      onOpen={() => {
        onFocus && onFocus();
      }}
      onClose={() => {
        onBlur && onBlur();
      }}
    />
  );
};

const custom_locale_zh = {
  name: 'custom_locale_zh',
  months: [
    ['一月', '一月'],
    ['二月', '二月'],
    ['三月', '三月'],
    ['四月', '四月'],
    ['五月', '五月'],
    ['六月', '六月'],
    ['七月', '七月'],
    ['八月', '八月'],
    ['九月', '九月'],
    ['十月', '十月'],
    ['十一月', '十一月'],
    ['十二月', '十二月'],
  ],
  weekDays: [
    ['六', '六'],
    ['日', '日'],
    ['一', '一'],
    ['二', '二'],
    ['三', '三'],
    ['四', '四'],
    ['五', '五'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['上午', '上午'],
    ['下午', '下午'],
  ],
};

function ToDateTime(date: DateObject, showTimeSelect: boolean): DateTime {
  return date
    ? DateTime.fromObject({
        year: date.year,
        month: date.month?.number,
        day: date.day,
        ...(showTimeSelect
          ? {
              hour: date.hour,
              minute: date.minute,
              second: date.second,
              millisecond: date.millisecond,
            }
          : {}),
      })
    : date;
}

function ToDateObject(date?: DateTime): DateObject | undefined {
  return date
    ? new DateObject({
        year: date.year,
        month: date.month,
        day: date.day,
        hour: date.hour,
        minute: date.minute,
        second: date.second,
        millisecond: date.millisecond,
      })
    : date;
}
