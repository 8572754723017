import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { Button, Row as BSRow, Table } from 'react-bootstrap';
import { AuthedLayout } from '../../../components';
import { Sight, SightNoDiags, StudentGradeNoSeat, TeeGradeClassStatistic } from '../../../model';
import { useSemGrade } from '../../../hook';
import apis from '../../../apis';
import { Nullable } from '../../../types';
import { sightDiag } from '../../../utils/sight';



const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;

const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;
type PageData = StudentGradeNoSeat & Nullable<Sight> & { diag: string };


interface Classification {
    [key: string]: {
        [key: string]: {
            [key: string]: {
                [key: string]: number;
            };
        };
    };
}
let diagarr: string[];
const sightDiagMatrix: FunctionComponent<Props> = ({ catchErrorForModal }) => {
    const { yearSem, element } = useSemGrade();
    const [datas, setDatas] = useState<PageData[]>([]);

    useEffect(() => {
        if (yearSem) {
            apis.getSightDiagMatrix(yearSem.year, yearSem.sem).then((s) => {
                setDatas(
                    s.map(({ sight, ...s }) => ({
                        ...sight,
                        ...s,
                        diag: sightDiag(sight),
                    }))
                );
            });
        }
    }, [yearSem?.year, yearSem?.sem]);

    diagarr = datas.flatMap((gs, idx, allArray) => {
        return gs.diag
    })
    diagarr = diagarr.filter((value, index, array) => array.indexOf(value) === index)
    console.log(diagarr)
    const countData = (studentData: PageData[]): Classification => {
        const organized: Classification = {};
        diagarr.sort();
        studentData.forEach(student => {
            student.diag = student.diag == "" ? "未就醫" : student.diag;
            if (!organized["schooltotal"]) {
                organized["schooltotal"] = {};
                organized["schooltotal"][0] = { 0: {} }
                diagarr.flatMap((gs, idx, allArray) => {
                    gs = gs == "" ? "未就醫" : gs;
                    organized["schooltotal"][0][0][gs] = 0
                    organized["schooltotal"][0][0]["total"] = 0;
                })
            }
            if (!organized[student.grade]) {
                organized[student.grade] = {};
                organized[student.grade]["gradetotal"] = { 0: {} }
                diagarr.flatMap((gs, idx, allArray) => {
                    gs = gs == "" ? "未就醫" : gs;
                    organized[student.grade]["gradetotal"][0][gs] = 0
                    organized[student.grade]["gradetotal"][0]["total"] = 0;
                })
            }
            if (!organized[student.grade][student.no]) {
                organized[student.grade][student.no] = { 1: {}, 2: {}, "classtotal": {} };
                diagarr.flatMap((gs, idx, allArray) => {
                    gs = gs == "" ? "未就醫" : gs;
                    organized[student.grade][student.no][1][gs] = 0;
                    organized[student.grade][student.no][1]["total"] = 0;
                    organized[student.grade][student.no][2][gs] = 0;
                    organized[student.grade][student.no][2]["total"] = 0;
                    organized[student.grade][student.no]["classtotal"][gs] = 0;
                    organized[student.grade][student.no]["classtotal"]["total"] = 0;
                })
            }
            // 班級男女統計
            organized[student.grade][student.no][student.sex][student.diag]++;
            organized[student.grade][student.no][student.sex]["total"]++;
            // 班級統計
            organized[student.grade][student.no]["classtotal"][student.diag]++;
            organized[student.grade][student.no]["classtotal"]["total"]++;
            // 年級統計
            organized[student.grade]["gradetotal"][0][student.diag]++;
            organized[student.grade]["gradetotal"][0]["total"]++;
            // 全校統計
            organized["schooltotal"][0][0][student.diag]++;
            organized["schooltotal"][0][0]["total"]++;
        });

        return organized;
    }

    const organizedData = countData(datas);

    console.log(organizedData)
    const diagRow = diagarr.flatMap((gs, idx, allArray) => {
        gs = gs == "" ? "未就醫" : gs;
        return {
            value: gs,
            merge: { column: 1 },
            style: { border: fullBorderStyle },
        }
    })

    const contentRows: Row[] = Object.values(organizedData).flatMap(
        (gradedata, gidx, allArray) => {
            const graderows = Object.values(gradedata).flatMap(
                (classdata, cidx, allclassArray) => {
                    const rows = Object.keys(gradedata)[cidx] != "gradetotal" ?
                        SightToRows(
                            Object.keys(organizedData)[gidx] ?? "total",
                            Object.keys(gradedata)[cidx] ?? "total",
                            classdata,
                            cidx === 0 ? { row: (((Object.values(gradedata).length) - 1) * 3 + 1) } : undefined
                        ) :
                        SightToRows("", "小計", classdata, { row: 1, column: 2 });
                    return rows
                })
            return graderows;
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: '年級/班級/性別',
                    merge: { column: 3 },
                    style: { border: fullBorderStyle },
                },
                ...diagRow,
                {
                    value: '合計',
                    merge: { column: 1 },
                    style: { border: fullBorderStyle },
                },
            ],
        },
        ...contentRows
    ];


    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <span className="mr-3">{element}</span>
                <Button
                    disabled={!datas.length}
                    onClick={() => {
                        const workSheet = generateSheet(
                            [
                                {
                                    cells: [
                                        {
                                            value: `${yearSem?.year}學年第${yearSem?.sem}學期_視力檢查結果分類統計`,
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                                {
                                    cells: [
                                        {
                                            value: '未就醫包含視力矯正正常但未有診斷的學生',
                                            merge: { column: 7 },
                                            style: { alignment: { horizontal: 'left' } },
                                        }
                                    ]
                                },
                                ...content,
                                {
                                    cells: [
                                        {
                                            value:
                                                '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                            style: { alignment: { horizontal: 'left' } },
                                        },
                                    ],
                                },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets(`${yearSem?.year}學年第${yearSem?.sem}學期_視力檢查結果分類統計`, { sheet: workSheet, name: 'st' });
                    }}
                >
                    Excel 下載
                </Button>
            </BSRow>
            <hr />
            <BSRow>
                <div>未就醫包含視力矯正正常但未有診斷的學生</div>
            </BSRow>
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function SightToRows(
    grade: number | string,
    classNo: number | string,
    classdata: { [key: string]: { [key: string]: number; } },
    gradeMerge?: { row?: number; column?: number }
): Row[] {
    const gradeNo = [
        {
            value: gradeMerge ? grade : undefined,
            merge: gradeMerge,
            style: { border: fullBorderStyle },
        },
    ];
    if (!gradeMerge?.column) {
        if (!isNaN(Number(classNo))) {
            gradeNo.push({
                value: classNo,
                merge: { row: 3, column: 1 },
                style: { border: fullBorderStyle },
            });
        }
    }

    const rows = Object.values(classdata).flatMap((sexdata, idx, allArray) => {
        let classitem;
        const sexrow = Object.values(diagarr).map((diag, idx) => {
           
            return {
                value: sexdata[diag == '' ? "未就醫": diag] ?? 0,
                style: { border: { left: borderStyle } },
            }
        })
        sexrow.push(
            {
                value: sexdata['total'] ?? 0,
                style: { border: { left: borderStyle } },
            }
        )

        const returnrows = [];
        if (grade != "schooltotal") {
            switch (Object.keys(classdata)[idx]) {
                case '0':
                    // 年級小計
                    returnrows.push({
                        cells: [
                            {
                                value: "小計",
                                merge: gradeMerge,
                                style: { border: { left: borderStyle } },
                            },
                            ...sexrow
                        ]
                    })
                    break;
                case '1':
                    classitem = "男";
                    returnrows.push({
                        cells: [
                            ...gradeNo,
                            {
                                value: classitem,
                                style: { border: { left: borderStyle } },
                            },
                            ...sexrow
                        ]
                    })
                    break;
                case '2':
                    classitem = "女";
                    returnrows.push({
                        cells: [
                            {
                                value: classitem,
                                style: { border: { left: borderStyle } },
                            },
                            ...sexrow
                        ]
                    })
                    break;
                default:
                    classitem = "小計";
                    returnrows.push({
                        cells: [
                            {
                                value: classitem,
                                style: { border: { left: borderStyle } },
                            },
                            ...sexrow
                        ]
                    })
                    break;
            }
        } else {
            // 全校小計
            returnrows.push({
                cells: [
                    {
                        value: "總計",
                        merge: { rows: 1, column: 3 },
                        style: { border: { left: borderStyle } },
                    },
                    ...sexrow
                ]
            })
        }
        return returnrows

    })
    return rows;
}

export const SightDiagMatrix = connector(sightDiagMatrix);
