import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row as BSRow, FormCheck, Table } from 'react-bootstrap';
import { Cell, Row, downloadSheets, generateSheet } from '../../../utils';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { I18N } from '../../../i18n-raw';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useCheckGrade, useClassGrade, useDownloadSheetChart, useSemGrade } from '../../../hook';
import { UnpivotedBloodCheck, CheckTotal } from '../../../model';
import apis from '../../../apis';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
    top: borderStyle,
    bottom: borderStyle,
    left: borderStyle,
    right: borderStyle,
} as const;
const cellAlignStyle = {
    horizontal: 'center',
    vertical: 'center',
} as const;
const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;
const checkStastic: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const [radioAction, setRadioAction] = useState<boolean>(true);
    const { selectedYearGrade, element: selectgradeElement } = useCheckGrade({ year: year, isNowGrade: radioAction })
    const [students, setStudents] = useState<UnpivotedBloodCheck[]>([]);
    const [checktotal, setCheckTotal] = useState<CheckTotal>();
    const handleRadioChange = (isNowGrade: boolean) => {
        setRadioAction(isNowGrade);
    };

    useEffect(() => {
        if (selectedYearGrade) {
            apis
                .getCheckStatistic(selectedYearGrade.year, selectedYearGrade?.grade)
                .then((r) => {
                    setStudents(r.checkData);
                    setCheckTotal(r.checkTotal);
                })
                .catch(catchErrorForModal);
        }
    }, [selectedYearGrade?.year, selectedYearGrade?.grade]);

    const CategoryBase: Record<string, UnpivotedBloodCheck[]> = students.reduce((categories, d) => {
        return { ...categories, [d.category ?? ""]: [...(categories[d.category ?? ""] || []), d] };
    }, {} as Record<string, UnpivotedBloodCheck[]>);

    const contentRows: Row[] = Object.values(CategoryBase).flatMap(
        (gs, idx, allArray) => {
            // 同一年級下 loop每個班級
            const rows = gs.flatMap((g, id) => {
                return CategoryToRows(
                    Object.keys(CategoryBase)[idx] == "血壓" || Object.keys(CategoryBase)[idx] == "脈搏" ? "" : Object.keys(CategoryBase)[idx],
                    g.items != "尿液篩檢" ? I18N.Physical[g.items] : "尿液篩檢",
                    g.m1,
                    g.m2,
                    g.m3,
                    g.m9,
                    g.f1,
                    g.f2,
                    g.f3,
                    g.f9,
                    checktotal?.mTotal ?? 0,
                    checktotal?.fTotal ?? 0,
                    id === 0 ? { row: (gs.length) } : undefined
                );
            });
            return rows
        }
    );

    const content: Row[] = [
        {
            cells: [
                {
                    value: `${selectedYearGrade?.year == null ? "" : selectedYearGrade?.year}學年${selectedYearGrade?.grade == null ? "" : selectedYearGrade?.grade}年級身體診察結果暨矯治追蹤統計表`,
                    merge: { column: 18 },
                },
            ],
        },
        {
            cells: [
                {
                    value: `類別`,
                    merge: { column: 6 },
                },
                {
                    value: `男`,
                    merge: { column: 4 },
                },
                {
                    value: `女`,
                    merge: { column: 4 },
                }, {
                    value: `合計`,
                    merge: { column: 4 },
                },
            ],
        },
        {
            cells: [
                {
                    value: `受檢學生總數`,
                    merge: { column: 6 },
                },
                {
                    value: checktotal?.mTotal ?? 0,
                    merge: { column: 4 },
                },
                {
                    value: checktotal?.fTotal ?? 0,
                    merge: { column: 4 },
                }, {
                    value: (checktotal?.fTotal ?? 0) + (checktotal?.mTotal ?? 0),
                    merge: { column: 4 },
                },
            ],
        },
        {
            cells: [
                {
                    value: `健檢無異狀學生數`,
                    merge: { column: 6 },
                },
                {
                    value: checktotal?.noProblemM ?? 0,
                    merge: { column: 4 },
                },
                {
                    value: checktotal?.noProblemF ?? 0,
                    merge: { column: 4 },
                }, {
                    value: (checktotal?.noProblemM ?? 0) + (checktotal?.noProblemF ?? 0),
                    merge: { column: 4 },
                },
            ],
        },
        {
            cells: [
                {
                    value: '科別',
                    merge: { column: 1, row: 3 },
                },
                {
                    value: '檢查名稱/統計',
                    merge: { column: 1, row: 3 },
                },
                {
                    value: '未實施人數',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '檢查項目結果發現異常',
                    merge: { column: 6, row: 1 },
                },
                {
                    value: '複檢就醫矯治追蹤情形',
                    merge: { column: 8, row: 1 },
                }
            ],
        },
        {
            cells: [
                {
                    value: '男',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '女',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '男',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '女',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '合計*',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '複檢正常☆',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '複檢異常△',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '未就醫×',
                    merge: { column: 2, row: 1 },
                },
                {
                    value: '就醫率',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '備註﹝及其他異常項目﹞',
                    merge: { column: 1, row: 1 },
                }
            ],
        },
        {
            cells: [
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '人數',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '%',
                    merge: { column: 1, row: 1 },
                },
                {
                    value: '',
                    merge: { column: 1, row: 1 },
                },
            ],
        },
        ...contentRows,
    ];

    const tableworkSheet: Row[] = [
        ...content,
        {
            cells: [
                {
                    value:
                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '說明：',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '一、本表置於教育部網站體育司業務介紹之學校衛生項之健康檢查，請依「班級健康檢查結果暨矯治追蹤名冊」一、四、七年級分開統計。',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '二、異狀百分比=異狀人數÷受檢總人數，就醫率=﹝☆+○﹞÷﹝＊﹞',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '三、健康檢查於上學期執行者本表於二月底前上傳完成,並製作一式兩份，一份留存學校備查，一份送教育局體健課。',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '四、視力不良是指E字視力檢查表篩檢值任一眼低於0.8以下(含0.8), 近視、遠視、散光、斜弱視的判讀是指經眼科醫師診斷後的判讀結果 ;「辨色力異常」指以色盲檢查卡檢出辨色異狀人數。 ',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '五、蟯蟲陽性的統計，請填寫與健康檢查同學期之第一次蟯蟲檢查陽性的人數。',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
        {
            cells: [
                {
                    value: '六 、近視﹑遠視﹑散光為視力不良分類診斷,故不計算矯治率。',
                    style: { alignment: { horizontal: 'left' } },
                },
            ],
        },
    ];
    const { element: downloadChart } = useDownloadSheetChart({ chartdata: [checktotal?.mTotal ?? 0, checktotal?.fTotal ?? 0], chartRefs: [], titles: [`${selectedYearGrade?.year == null ? "" : selectedYearGrade?.year}學年${selectedYearGrade?.year == null ? "" : selectedYearGrade?.gradeName}年級身體診察結果暨矯治追蹤統計表`], tablesheets: tableworkSheet, tablesheetname: "身體診察結果暨矯治追蹤統計表" });
    return (
        <AuthedLayout>
            <BSRow className="justify-content-between">
                <Col className='d-flex mr-3' xs={3} >
                    <FormCheck
                        className="mr-2"
                        value={'在學'}
                        type="radio"
                        label={`在學`}
                        name="list-action"
                        checked={radioAction === true}
                        onChange={() => handleRadioChange(true)}
                    />
                    <FormCheck
                        className="mr-2"
                        value={`歷屆年級`}
                        type="radio"
                        label={`歷屆年級`}
                        name="list-action"
                        checked={radioAction === false}
                        onChange={() => handleRadioChange(false)}
                    />
                </Col>
                <Col xs={3} className="mr-3">
                    {selectgradeElement}
                </Col>
                <div>
                    {downloadChart}
                </div>
            </BSRow>
            <hr />
            <BSRow>
                <Table
                    striped
                    bordered
                    className="text-center"
                    style={{ tableLayout: 'fixed' }}
                >
                    <tbody>
                        {content.map((r, i) => {
                            const cells = r.cells.map((c, j) =>
                                c.value != undefined ? (
                                    <td
                                        colSpan={c.merge?.column}
                                        rowSpan={c.merge?.row}
                                        key={`${i}-${j}`}
                                    >
                                        {c.value}
                                    </td>
                                ) : (
                                    <Fragment key={`${i}-${j}`}></Fragment>
                                )
                            );
                            return <tr key={i}>{cells}</tr>;
                        })}
                    </tbody>
                </Table>
            </BSRow>
        </AuthedLayout>
    );
};

function CategoryToRows(
    category: string,
    item: string,
    m1: number,
    m2: number,
    m3: number,
    m9: number,
    f1: number,
    f2: number,
    f3: number,
    f9: number,
    mtotal: number,
    ftotal: number,
    categoryMerge?: { row?: number; column?: number },
): Row[] {
    const gradeNo = [
        {
            value: categoryMerge ? category : undefined,
            merge: categoryMerge,
            style: { border: fullBorderStyle },
        },
    ];
    if (!categoryMerge?.column) {
        gradeNo.push({
            value: item,
            merge: {},
            style: { border: fullBorderStyle },
        });
    }
    return [
        {
            cells: [
                ...gradeNo,
                {
                    value: m9 ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: f9 ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m1 + m2 + m3 ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent((m1 + m2 + m3), mtotal) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: f1 + f2 + f3 ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent((f1 + f2 + f3), ftotal) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: (m1 + m2 + m3) + (f1 + f2 + f3) ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent((m1 + m2 + m3) + (f1 + f2 + f3), ftotal + mtotal) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m2 + f2 ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent(m2 + f2, (m1 + m2 + m3) + (f1 + f2 + f3)) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: m3 + f3 ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent(m3 + f3, (m1 + m2 + m3) + (f1 + f2 + f3)) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: (m1 + m2 + m3) + (f1 + f2 + f3) - (m3 + f3 + m2 + f2) ?? 0,
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent((m1 + m2 + m3) + (f1 + f2 + f3) - (m3 + f3 + m2 + f2), (m1 + m2 + m3) + (f1 + f2 + f3)) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: percent(m3 + f3 + m2 + f2, (m1 + m2 + m3) + (f1 + f2 + f3)) ?? "0%",
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
                {
                    value: '',
                    merge: {},
                    style: { border: { left: borderStyle } },
                },
            ],
        },

    ];
}

function percent(value: number, denominator: number): string {
    if (!denominator) return '0.00%';
    return ((value / denominator) * 100).toFixed(2) + '%';
}
export const CheckStastic = connector(checkStastic);