import React, { Fragment, FunctionComponent, useEffect, useState, useRef, ReactElement, ReactNode, } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { downloadDataAsExcel, SheetHeaderDisplay} from '../../../utils';
import { 
  Button, 
  Row as BSRow, 
  Dropdown,
  DropdownButton,
  Col,
  FormCheck,
} from 'react-bootstrap';
import { AuthedLayout, EditableTableDiv, HeaderDisplayKeysWithType } from '../../../components';
import { 
  StudentGradeNoSeat, 
  SundryExamDate, 
  PHISundry 
} from '../../../model';
import apis from '../../../apis';
import { useHistory } from 'react-router';
import { semesterRange } from '../../../utils/date';
import { DateTime } from 'luxon';
import { GradeSem, WrapGradeBaseLayout } from '../../../layouts';

type ChildRender = (classSem: GradeSem) => ReactElement;
type OwnProps = {
  children: ReactNode | ChildRender;
  onGradesLoaded?: () => void;
  onGradeChanged?: (year: number, sem: number, classId?: string) => void;
};

const mapState = (state: ApplicationState, ownProps: OwnProps) => ({ 
  ...state.auth,  
  ...ownProps
});
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;
const checkKind = [
  {
    Id: 1,
    Checks: '心臟病篩檢'
  },
  {
    Id: 2,
    Checks: '胸部X光'
  },
  {
    Id: 3,
    Checks: '肝功能'
  },
  {
    Id: 4,
    Checks: '腎功能'
  },
  {
    Id: 5,
    Checks: '蛔蟲'
  },
  {
    Id: 6,
    Checks: '蟯蟲'
  },
{
    Id: 7,
    Checks: 'A型肝炎'
  },
  {
    Id: 8,
    Checks: 'B型肝炎'
  },
  {
    Id: 9,
    Checks: '結核菌素測驗'
  },
  {
    Id: 10,
    Checks: '頭蝨'
  },
  {
    Id: 11,
    Checks: '尿液'
  },
  {
    Id: 12,
    Checks: '心電圖'
  },
  {
    Id: 13,
    Checks: '心臟超音波'
  }
];
const SundryResultCode = ['初檢無異狀', '初檢異常', '複診無異狀', '複診異常'];
const checkTempRep: FunctionComponent<Props> = ({ 
  user,
  loading,
  catchErrorForModal,
  onGradesLoaded = () => {},
  onGradeChanged = () => {},
}) => {
  if (loading) {
    useHistory().go(0);
    return <></>;
  }
  type MetricPageData = StudentGradeNoSeat & PHISundry;
  type ExamDate = SundryExamDate; 
  const { currentSemester = '', semesters = {} } = user || {};
  const [currentSem, setCurrentSem] = useState(currentSemester);
  const [sundryId, setSundryId] = useState<number>();
  const [examDates, setExamDate] = useState<ExamDate[]>([]);
  const [showExamDate, setShowExamDate] = useState<string>();
  const [radioAction, setRadioAction] = useState<string>('All');
  const { year = -1, sem = -1 } = semesters[currentSem];
  const [students, setStudent] = useState([] as MetricPageData[]);  
  const displayHeader: (SheetHeaderDisplay<MetricPageData> & 
    HeaderDisplayKeysWithType<MetricPageData>)[] = [
    { display: '年級', property: 'grade' },
    { display: '班級', property: 'no' },
    { display: '座號', property: 'seat' },
    { display: '學生', property: 'name' },
    { display: '檢查項目', property: 'sundryId',
      onRender: (value: unknown) => {
        if(value) {
          return typeof(value) == 'number' ? checkKind[value - 1].Checks : '無檢查項目';
        }
      },
      onSheetRender: (value) => {
        return typeof(value) == 'number' ? checkKind[value - 1].Checks : (value as string);
      },
    },
    {
      property: 'examDate',
      display: '測量日',
      onRender: (v: unknown) => {
        return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : null;
      },
      onSheetRender: (v) => {
        return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : ('' as string);
      },
    },
    { display: '檢查單位', property: 'agencies' },
    { display: '結果', property: 'sundryResult',
      onRender: (value: unknown) => {
        return typeof(value) === 'number' ? SundryResultCode[value] : '無結果';
      },
      onSheetRender: (value) => {
        return typeof(value) == 'number' ? SundryResultCode[value] : (value as string);
      },
    },
    { display: '複檢診斷', property: 'followUpDescription' },
    { display: '備註', property: 'remark' },
  ];
  // 檢查日期下拉選單
  useEffect(() => {
    if (sundryId) {
      apis
      .getSundryExamDateBySundryId(year, sem, sundryId)
      .then((s) => {
        setExamDate(s);
        setShowExamDate('');
      })
      .catch(catchErrorForModal);
    }
  }, [sundryId]);

  // 臨時性檢查清單
  useEffect(() => {
    getCheckTempList();
  }, [sundryId, showExamDate, radioAction]);

  function getCheckTempList() {
    if (sundryId) {
      if(showExamDate) {
        apis.getSundryList(year, sem, sundryId, showExamDate, radioAction)
        .then((s) => {
          setStudent(s.map(({ sundry, ...m }) => ({
            ...sundry,
            ...m
          })));
        })
        .catch(catchErrorForModal);
      }
    }
  }

  return (
    <AuthedLayout>
      <BSRow className="align-items-center mb-3">
        <Col className='d-flex align-items-center'>
          檢查項目：
          <DropdownButton
              className=""
              title={sundryId ? checkKind[sundryId - 1].Checks : '檢查項目'}
              onSelect={(c) => {
                if(c) {
                  setSundryId(parseInt(c));
                }
              }}
            >
              <Dropdown.Item key={`check-${1}`} eventKey={1}>心臟病篩檢</Dropdown.Item>
              <Dropdown.Item key={`check-${2}`} eventKey={2}>胸部X光</Dropdown.Item>
              <Dropdown.Item key={`check-${3}`} eventKey={3}>肝功能</Dropdown.Item>
              <Dropdown.Item key={`check-${4}`} eventKey={4}>腎功能</Dropdown.Item>
              <Dropdown.Item key={`check-${5}`} eventKey={5}>蛔蟲</Dropdown.Item>
              <Dropdown.Item key={`check-${6}`} eventKey={6}>蟯蟲</Dropdown.Item>
              <Dropdown.Item key={`check-${7}`} eventKey={7}>A型肝炎</Dropdown.Item>
              <Dropdown.Item key={`check-${8}`} eventKey={8}>B型肝炎</Dropdown.Item>
              <Dropdown.Item key={`check-${9}`} eventKey={9}>結核菌素測驗</Dropdown.Item>
              <Dropdown.Item key={`check-${10}`} eventKey={10}>頭蝨</Dropdown.Item>
              <Dropdown.Item key={`check-${11}`} eventKey={11}>尿液</Dropdown.Item>
              <Dropdown.Item key={`check-${12}`} eventKey={12}>心電圖</Dropdown.Item>
              <Dropdown.Item key={`check-${13}`} eventKey={13}>心臟超音波</Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col className='d-flex align-items-center'>
          檢查日期：
          <DropdownButton
            className="mr-2"
            title={showExamDate || '檢查日期'}
            onSelect={(g) => {
              if(g) {
                setShowExamDate(g);
              }
            }}
          >
            {examDates.length 
              ? examDates.map((date, idx) => (
                  <Dropdown.Item key={`date-${idx}`} eventKey={date.examDate?.toFormat('yyyy/MM/dd')}>
                    {date.examDate?.toFormat('yyyy/MM/dd')}
                  </Dropdown.Item>
                ))
              : <Dropdown.Item key={`date-${0}`} eventKey={0} disabled={true}>
                  無檢查紀錄
                </Dropdown.Item>
            }
          </DropdownButton>
        </Col>
        <Col className='d-flex align-items-center'>
          <FormCheck
            className="mr-2"
            value={`Problem`}
            type="radio"
            label={`異常清單`}
            name="list-action"
            checked={radioAction === 'Problem'}
            onChange={(e) => {
              setRadioAction('Problem');
            }}
          /> 
          <FormCheck
            className="mr-2"
            value={`All`}
            type="radio"
            label={`受檢清單`}
            name="list-action"
            checked={radioAction === 'All'}
            onChange={(e) => {
              console.log(e)
              setRadioAction('All');
            }}
          />
        </Col>
        <div className='text-right'>
          <Button
            disabled={!students.length}
            variant="success"
            className="text-dark"
            onClick={() => {
              downloadDataAsExcel({
                title: `${year}學年${sem}學期_${sundryId ? checkKind[sundryId - 1].Checks : ''}檢查清單${showExamDate?.replace(/\//g, '')}`,
                values: students,
                headers: displayHeader,
                // context: SightContext,
                footer:
                  '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
              });
            }}
          >
            Excel下載
          </Button>
        </div>
      </BSRow>
      <hr />
      <BSRow>
        <EditableTableDiv
          values={students}
          headers={displayHeader}
        />
      </BSRow>
    </AuthedLayout>
  );
};



export const CheckTempRep = connector(checkTempRep);
