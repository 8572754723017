import React, { ReactNode,Fragment, FunctionComponent, useEffect, useState, useRef } from 'react';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import { Cell, Row, SheetHeaderDisplay, downloadDataAsExcel, downloadSheets, generateSheet, listToObject } from '../../../utils';
import XLSX, { Range } from 'xlsx-js-style';
import { 
    Button, 
    Row as BSRow, 
    Table,
    Dropdown,
    DropdownButton,
    FormControl,
    Col,
    InputGroup,
    Form,
    Modal,
    Container
} from 'react-bootstrap';
import { 
    AuthedLayout ,
    HeaderDisplayKeysWithType,
    InlineEditableTable,
    NumberField,
    EditableTableDiv
} from '../../../components';
import apis from '../../../apis';
import { StudentBrush } from '../../../apis/FluorApi';
import { ClassMapObj } from '../../../types';
import { toast } from 'react-toastify';
import { StudentGradeNoSeat, BrushTeeLack } from '../../../model';

const borderStyle = { color: { rgb: '000000' }, style: 'thin' } as const;
const fullBorderStyle = {
top: borderStyle,
bottom: borderStyle,
left: borderStyle,
right: borderStyle,
} as const;
const cellAlignStyle = {
horizontal: 'center',
vertical: 'center',
} as const;
const monthsObj = {
    'sem1': [
        {
        id: 1,
        text: '1月'
        },
        {
        id: 8,
        text: '8月'
        },
        {
        id: 9,
        text: '9月'
        },
        {
        id: 10,
        text: '10月'
        },
        {
        id: 11,
        text: '11月'
        },
        {
        id: 12,
        text: '12月'
        },
    ],
    'sem2': [ 
        {
        id: 2,
        text: '2月'
        },
        {
        id: 3,
        text: '3月'
        },
        {
        id: 4,
        text: '4月'
        },
        {
        id: 5,
        text: '5月'
        },
        {
        id: 6,
        text: '6月'
        },
        {
        id: 7,
        text: '7月'
        },
    ],
    'sem3': [
        {
        id: 8,
        text: '8月'
        },
        {
        id: 9,
        text: '9月'
        },
        {
        id: 10,
        text: '10月'
        },
        {
        id: 11,
        text: '11月'
        },
        {
        id: 12,
        text: '12月'
        },
        {
        id: 1,
        text: '1月'
        },
        {
        id: 2,
        text: '2月'
        },
        {
        id: 3,
        text: '3月'
        },
        {
        id: 4,
        text: '4月'
        },
        {
        id: 5,
        text: '5月'
        },
        {
        id: 6,
        text: '6月'
        },
        {
        id: 7,
        text: '7月'
        },
    ]
};

const transformTextInput = (
    property: string,
    v: unknown,
    i: number,
    e?: boolean
) => (e ? (
    <NumberField
        property="executeTimes"
        min={0}
        max={100}
    /> 
  ): (v as string));


const mapState = (state: ApplicationState) => ({ ...state.auth });
const mapDispatches = ErrorDispatches;

const connector = connect(mapState, mapDispatches);

type Props = ConnectedProps<typeof connector>;
type MetricPageData = StudentGradeNoSeat & BrushTeeLack;
const inlineBrushTableHeader: HeaderDisplayKeysWithType<StudentBrush>[] = [
    { property: 'year', display: '學年' },
    { property: 'sem', display: '學期' },
    { property: 'executeMonth', display: '月' },
    { property: 'grade', display: '年級' },
    {
        property: 'executeTimes', 
        display: '實施次數' ,
        onRender: transformTextInput.bind(null, 'executeTimes'),
    },
  ];
const brushTeeLackHeader: (SheetHeaderDisplay<MetricPageData> & 
    HeaderDisplayKeysWithType<MetricPageData>)[] = [
    { property: 'year', display: '學年' },
    { property: 'sem', display: '學期' },
    { property: 'lackMonth', display: '月' },
    { property: 'grade', display: '年級' },
    { property: 'no', display: '班級' },
    { property: 'seat', display: '座號' },
    { property: 'name', display: '學生' },
    { 
        property: 'lack',
        display: '次',
        onRender: transformTextInput.bind(null, 'lack'),
    }
  ];
  
const cleanset: FunctionComponent<Props> = ({
        user,
        catchErrorForModal,
    }) => {

    const [month, setMonth] = useState<number>();
    const [monthSem, setMonthSem] = useState<number>(1);
    const [monthText, setMonthText] = useState<string>('');
    const [classes, setClasses] = useState<ClassMapObj>({ '': '無年級資料' });
    const currentSem = user.semesters[user.currentSemester];
    const currentMonth = new Date().getMonth() + 1;
    const semMonths = (currentMonth == 1 || currentMonth == 7) ? monthsObj['sem3'] : (currentSem.sem == 2 ? monthsObj['sem2'] : monthsObj['sem1']); // 1月、7月不分學期 顯示所有月份
    const [ brushdatas , setBrushDatas] = useState<StudentBrush[]>([]);
    const studentInputRef = useRef<HTMLInputElement>(null);
    const brushLackInputRef = useRef<HTMLInputElement>(null);
    const [insertBtn, setInsertBtn] = useState<boolean>(true);
    const [sid, setSid] = useState<string>('');
    const [pid, setPid] = useState<string>('');
    const [grade, setGrade] = useState<number>();
    const [no, setNo] = useState<number>();
    const [seat, setSeat] = useState<number>();
    const [checkField, setCheckField] = useState<number>(0);
    const [hideLackInput, setHideLackInput] = useState<boolean>(true);
    const [studentsBrushLack, setStudentBrushLack] = useState([] as MetricPageData[]);  
    const [editing, setEditing] = useState(false);
    const [editingStudent, setEditingStudent] = useState<MetricPageData>();
    const [deleting, setDeleting] = useState(false);
    const [deletingStudent, setDeletingStudent] = useState<MetricPageData>();
    const unBrushInputRef = useRef<HTMLInputElement>(null);
    const contentRows : Row[] = brushdatas ? brushdatas.flatMap(
        (d) => {
            return [
                {
                  cells: [
                    {
                      value: d.year,
                      style: { border: fullBorderStyle },
                    },
                    {
                        value: d.sem,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.executeMonth,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.grade,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.executeTimes,
                        style: { border: fullBorderStyle },
                    },
                  ],
                },
              ];
        }
    ) : [];
    const cleanheader: Row[] = [
        {
            cells: [
            // {
            //   value: '',
            //   style: { border: fullBorderStyle },
            // },
            {
                value: '學年',
                style: { border: fullBorderStyle },
            },
            {
                value: '學期',
                style: { border: fullBorderStyle },
            },
            {
                value: '月',
                style: { border: fullBorderStyle },
            },
            {
                value: '年級',
                style: { border: fullBorderStyle },
            },
            {
                value: '實施日數',
                style: { border: fullBorderStyle },
            },
            ],
        },
    ];  
    const brushTeesLackHeader: Row[] = [
        {
            cells: [
            {
                value: '學年',
                style: { border: fullBorderStyle },
            },
            {
                value: '學期',
                style: { border: fullBorderStyle },
            },
            {
                value: '月',
                style: { border: fullBorderStyle },
            },
            {
                value: '年級',
                style: { border: fullBorderStyle },
            },
            {
                value: '班級',
                style: { border: fullBorderStyle },
            },
            {
                value: '座號',
                style: { border: fullBorderStyle },
            },
            {
                value: '學生',
                style: { border: fullBorderStyle },
            },
            {
                value: '次',
                style: { border: fullBorderStyle },
            },
            ],
        },
    ]; 
    const brushTeesLackContentRows : Row[] = studentsBrushLack ? studentsBrushLack.flatMap(
        (d) => {
            return [
                {
                  cells: [
                    {
                      value: d.year,
                      style: { border: fullBorderStyle },
                    },
                    {
                        value: d.sem,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.lackMonth,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.grade,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.no,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.seat,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.name,
                        style: { border: fullBorderStyle },
                    },
                    {
                        value: d.lack,
                        style: { border: fullBorderStyle },
                    },
                  ],
                },
              ];
        }
    ) : [];

    useEffect(() => {
        if(month) {
            if(month >=2 && month <=7) {
                setMonthSem(2);
            } else {
                setMonthSem(1);
            }
        }
        setHideLackInput(month ? false : true);
        getStudentBrushLack();

    }, [month]); 

    function onValueUpdate({
        ...value
    }: StudentBrush ) {
     console.log('grade' + value.grade)
     console.log('month' + value.executeMonth)
     console.log('time' + value.executeTimes)
     apis
        .updateStudentBrush(value.executeMonth , value.grade , value.executeTimes)
        .then((t) =>{
             apis
                .getStudentBrushList(value.executeMonth)
                .then((s) => {
                    setBrushDatas(s);
                });
        });
    }

    // 檢查是否可以點選新增按鈕
    function CheckInsertBtn() {
        setInsertBtn(true);
        if (brushLackInputRef.current?.value) {
            if(studentInputRef.current?.value) {
                if(month) {
                    setInsertBtn(false); 
                    CheckInput();
                }
            }
        }
    }

    function CheckInput() {
        const inputStudent = studentInputRef.current?.value;
        
        // 檢查輸入身分證、學號或班級座號
        if(inputStudent) {
          if(inputStudent.substring(0, 1) == '=') {
            setSid(inputStudent.substring(1));
            setCheckField(2);
          } else {
            switch(inputStudent.length) {
              case 5:
                setGrade(parseInt(inputStudent.substring(0, 1)));
                setNo(parseInt(inputStudent.substring(1, 3)));
                setSeat(parseInt(inputStudent.substring(3, 5)));
                setCheckField(3);
                break;
              case 6:
                // 代入年級、班級、座號
                setGrade(parseInt(inputStudent.substring(0, 2)));
                setNo(parseInt(inputStudent.substring(2, 4)));
                setSeat(parseInt(inputStudent.substring(2, 4)));
                setCheckField(3);
                break;
              case 10:
              case 11:
              case 12:
                setPid(inputStudent);
                setCheckField(1);
                break;
            }
          }
        }
    }

    function insertBrushLack() {
        if(month) {
            toast
            .promise(
            apis.createStudentBrushLack(
                currentSem.year,
                monthSem,
                sid,
                seat || 0,
                no || 0,
                grade || 0,
                pid,
                month,
                brushLackInputRef.current?.value ? parseInt(brushLackInputRef.current?.value) : 0,
                checkField
            ),
            {
                pending: '資料新增中......',
                success: '新增成功！',
            }
            )
            .then((r) => {
                // 清空學號、未實施次數
                if(studentInputRef.current) {  
                    studentInputRef.current.value = '';
                }
                if(brushLackInputRef.current) {  
                    brushLackInputRef.current.value = '';
                }
                CheckInsertBtn(); // 按鈕disabled
                getStudentBrushLack();
            })
            .catch(catchErrorForModal);
        }
    }
  
    function getStudentBrushLack() {
        if(month) {
            apis.getStudentBrushLackList(currentSem.year, monthSem, month)
            .then((s) => {
                setStudentBrushLack(s.map(({ studentBrushTee, ...m }) => ({
                ...studentBrushTee,
                ...m
                })));
            })
            .catch(catchErrorForModal);
        }
    }

    function onEditMetric(student: MetricPageData) {
        setEditing(true);
        setEditingStudent(student);
    }
    
    function onHideEditing() {
        setEditing(false);
        setEditingStudent(undefined);
    }

    function onValueUpdateBrush() {
        if(editingStudent) {
            apis
              .updateStudentBrushLack(
                editingStudent.id,
                editingStudent.year,
                editingStudent.sem,
                editingStudent.lackMonth,
                unBrushInputRef.current?.value ? parseInt(unBrushInputRef.current?.value) : 0,
                editingStudent.studentId,
              )
              .then(() => {
                getStudentBrushLack();
                onHideEditing();
              })
              .catch(catchErrorForModal);
        }
    }
  
    function onDeleteMetric(student: MetricPageData) {
        setDeleting(true);
        setDeletingStudent(student);
      }
    
      function onHideDeleting() {
        setDeleting(false);
        setDeletingStudent(undefined);
      }

    function onDeleteBrush() {
        if(deletingStudent) {
            apis
                .deleteStudentBrushLack(
                deletingStudent?.id,
                deletingStudent?.year,
                deletingStudent?.sem,
                deletingStudent?.lackMonth,
                deletingStudent?.lack,
                deletingStudent?.studentId,
                )
                .then(() => {
                    getStudentBrushLack();
                    onHideDeleting();
                })
                .catch(catchErrorForModal);
        }
    }
    return (
        <AuthedLayout>
            <BSRow className="mb-3">
                <DropdownButton
                        className="mr-2"
                        title={monthText || '請選擇'}
                        onSelect={(g) => {
                            if(g) {
                                const selectMonth = semMonths[parseInt(g)];
                                setMonth(selectMonth.id);
                                setMonthText(currentSem.year + (selectMonth.id < 8 ? 1 : 0) + '年' + selectMonth.text);
                                apis
                                .getStudentBrushList(selectMonth.id)
                                .then((s) => {
                                    if(s.length == 0)
                                    {
                                        apis
                                        .createStudentBrush(selectMonth.id)
                                        .then((s) => {
                                            apis
                                            .getStudentBrushList(selectMonth.id)
                                            .then((s) => {
                                                setBrushDatas(s);
                                            });
                                        })
                                        .catch(catchErrorForModal);
                                    }
                                    else
                                    {
                                        setBrushDatas(s);
                                    }
                                });
                            }
                        }}
                        >
                    {semMonths.map((month, idx) => {
                        let dropDownYear = currentSem.year;
                        if(month.id < 8) {
                            dropDownYear+=1
                        }
                        return (
                            <Dropdown.Item key={`month-${idx}`} eventKey={idx}>
                            {dropDownYear}年{month.text}
                        </Dropdown.Item>
                        )
                    })}
                </DropdownButton>
                {hideLackInput ? 
                    ""
                : (<>
                    <div>
                        <span>請輸入年級班級座號或身分證或學號(學號前請加=)：</span><br />
                        <span className="text-primary">例如:國小三年級1班3號，應寫作「30103」</span>
                    </div>
                    <Col xs={2} className="mr-2">
                        <FormControl 
                        type="string"
                        aria-label="studentInput"
                        aria-describedby="basic-addon1"
                        ref={studentInputRef}
                        onChange={CheckInsertBtn}
                        />
                    </Col>
                    未實施次數：
                    <Col xs={2} className="mr-2">
                        <FormControl 
                        type="number"
                        aria-label="brushLackInput"
                        aria-describedby="basic-addon1"
                        ref={brushLackInputRef}
                        onChange={CheckInsertBtn}
                    />
                    </Col>
                    <Button
                        disabled={insertBtn}
                        onClick={insertBrushLack}
                    >
                        新增
                    </Button>
                </>)}
            </BSRow>
            <BSRow className="mb-3">
                <div className="ml-auto">
                    <Button
                        disabled={!brushdatas}
                        className="mr-3"
                        onClick={() => {
                            const workSheet = generateSheet(
                                [
                                ...brushTeesLackHeader,
                                ...brushTeesLackContentRows,
                                {
                                    cells: [
                                    {
                                        value:
                                        '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                        style: { alignment: { horizontal: 'left' } },
                                    },
                                    ],
                                },
                                ],
                                {
                                    alignment: cellAlignStyle,
                                }
                            );
                            downloadSheets(`潔牙未實施清單`, { sheet: workSheet, name: 'BrushTeeLack' });
                            }}
                    >
                        Excel 下載潔牙未實施清單
                    </Button>
                    <Button
                        disabled={!brushdatas}
                        onClick={() => {
                        const workSheet = generateSheet(
                            [
                            ...cleanheader,
                            ...contentRows,
                            {
                                cells: [
                                {
                                    value:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                    style: { alignment: { horizontal: 'left' } },
                                },
                                ],
                            },
                            ],
                            {
                                alignment: cellAlignStyle,
                            }
                        );
                        downloadSheets(`潔牙實施日數`, { sheet: workSheet, name: 'BrushTee' });
                        }}
                    >
                        Excel 下載潔牙實施設定
                    </Button>
                </div>
            </BSRow>
            <InlineEditableTable
                values={brushdatas}
                headers={inlineBrushTableHeader}
                onValueUpdate={onValueUpdate}
            />
            <hr />
            <BSRow>
                <Col sm={12} className='mb-2'>潔牙未實施</Col>
                <EditableTableDiv
                    editable
                    deleteable
                    values={studentsBrushLack}
                    headers={brushTeeLackHeader}
                    onEdit={onEditMetric}
                    onDelete={onDeleteMetric}
                />
            </BSRow>
            <Modal
                show={editing}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                >
                <Modal.Header closeButton onHide={onHideEditing}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        潔牙未實施 - {editingStudent?.grade}年{editingStudent?.no}班{editingStudent?.seat}號 {editingStudent?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <BSRow className='mb-3'>
                            <Col sm={3}> 學年：</Col>
                            <Col sm={3}>{editingStudent?.year}</Col>
                            <Col sm={3}>學期：</Col>
                            <Col sm={3}>{editingStudent?.sem}</Col>
                        </BSRow>
                        <BSRow className='mb-3'>
                            <Col sm={3}> 統編：</Col>
                            <Col sm={3}>{editingStudent?.pid}</Col>
                            <Col sm={3}>學生：</Col>
                            <Col sm={3}>{editingStudent?.name}</Col>
                        </BSRow>
                        <BSRow className='mb-3'>
                            <Col sm={3}> 年級：</Col>
                            <Col sm={3}>{editingStudent?.grade}</Col>
                            <Col sm={3}>班級：</Col>
                            <Col sm={3}>{editingStudent?.no}</Col>
                        </BSRow>
                        <BSRow className='mb-3'>
                            <Col sm={3}>座號：</Col>
                            <Col sm={3}>{editingStudent?.seat}</Col>
                        </BSRow>
                        <BSRow className='mb-3'>
                            <Col sm={3}> 月：</Col>
                            <Col sm={3}>{editingStudent?.lackMonth}</Col>
                            <Col sm={3}> 次：</Col>
                            <Col sm={2}>
                                <FormControl 
                                    type="string"
                                    aria-label="unBrushInputRef"
                                    aria-describedby="basic-addon1"
                                    defaultValue={editingStudent?.lack}
                                    ref={unBrushInputRef}
                                />
                            </Col>
                        </BSRow>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={onValueUpdateBrush}>
                    儲存
                    </Button>
                    <Button type="reset" variant="secondary" onClick={onHideEditing}>
                    關閉
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={deleting}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                >
                <Modal.Header closeButton onHide={onHideDeleting}>
                    <Modal.Title id="contained-modal-title-vcenter">刪除</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <BSRow className='mb-3'>
                            <Col>您確定要刪除嗎?</Col>
                        </BSRow>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="danger" onClick={onDeleteBrush}>
                    確認
                    </Button>
                    <Button type="reset" variant="secondary" onClick={onHideDeleting}>
                    取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </AuthedLayout>
    );
};

export const CleanSet = connector(cleanset);
