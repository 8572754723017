import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Col,
  Container,
  FormControl,
  Modal,
  Row,
} from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';
import Apis from '../apis';
import {
  CheckBoxField,
  EditableTableDiv,
  HeaderDisplayKeysWithType,
  InputDropdownGroup,
  TeeSheetWithButton,
  TextField,
} from '../components';
import { ClassSem, WrapClassBaseLayout } from '../layouts';
import { SimpleStudent, PHITee } from '../model';
import { ErrorDispatches } from '../redux/Dispatches';
import { ApplicationState } from '../redux/States';
import { Nullable } from '../types';
import {
  TeeFollowUpTableHeader,
  TeeTableHeader,
  PHITeeCalculators,
} from './TableHeaders/TeeHeader';
import {
  DefaultTeeContext,
  ExtractedPHITee,
  combindFirstAndFollowUpExamination,
  extractFirstAndFollowUpExamination,
  teeTransformFirstExaminationField,
  teeTransformFollowUpExaminationField,
  teeTransformSingleExaminationField,
} from '../utils/tee';
import { I18N } from '../i18n-raw';

type TeePageData = SimpleStudent & Nullable<ExtractedPHITee>;

const firstTeeHeader: HeaderDisplayKeysWithType<TeePageData>[] = [
  { property: 'seat', display: '座號', style: { width: '2rem' } },
  { property: 'name', display: '學生', style: { width: '5rem' } },
  ...(TeeTableHeader as HeaderDisplayKeysWithType<TeePageData>[]),
];

const followUpTeeHeader: HeaderDisplayKeysWithType<TeePageData>[] = [
  { property: 'seat', display: '座號', style: { width: '2rem' } },
  { property: 'name', display: '學生', style: { width: '5rem' } },
  ...(TeeFollowUpTableHeader as HeaderDisplayKeysWithType<TeePageData>[]),
];

const mapState = (state: ApplicationState, ownProps: ClassSem) => ({
  ...ownProps,
});
const mapDispatch = { ...ErrorDispatches };
const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;

const classPHITeePage: FunctionComponent<Props> = ({
  classId,
  year,
  sem,
  catchErrorForModal,
}) => {
  const [students, setStudent] = useState([] as TeePageData[]);
  const [followUp, setFollowUp] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [inputing, setInputing] = useState(false);
  const [editingStudent, setEditingStudent] = useState<TeePageData>();

  useEffect(() => {
    refreshStudents();
  }, [classId, year, sem, inputing, toggle]);

  function refreshStudents() {
    if (classId && year && sem) {
      Apis.getClassStudentTee(classId, year, sem)
        .then((ss) =>
          setStudent(
            ss.map(({ tee, ...s }) => ({
              ...s,
              ...(tee ? extractFirstAndFollowUpExamination(tee) : {}),
            }))
          )
        )
        .catch(catchErrorForModal);
    } else {
      setStudent([]);
    }
  }

  function onHide() {
    setInputing(false);
    setEditingStudent(undefined);
  }

  function onDelete(sight: TeePageData) {
    toast
      .promise(Apis.deleteStudentTee(sight.pid, year, sem), {
        pending: '口檢資料刪除中......',
        success: '口檢資料刪除成功！',
        error: '口檢資料刪除失敗！請查看錯誤資訊。',
      })
      .then(() => setToggle(!toggle))
      .catch(catchErrorForModal);
  }

  function onEdit(student: TeePageData) {
    setInputing(true);
    setEditingStudent(student);
  }

  function onUpdateStudent(tee: PHITee) {
    if (editingStudent && editingStudent.pid) {
      toast
        .promise(Apis.InsertStudentTee(editingStudent?.pid, tee), {
          pending: '口檢資料上傳中......',
          success: '口檢資料上傳成功！',
          error: '口檢資料上傳失敗！請查看錯誤資訊。',
        })
        .then(() => onHide())
        .catch(catchErrorForModal);
    }
  }
  const rowClassName = 'align-items-center';

  function generateModalContent(values: TeePageData) {
    return followUp ? (
      <>
        <Row className={rowClassName}>
          {generateCheckedField('實施', 'tee')}
        </Row>
        <Row className={rowClassName}>
          {generateFollowUpExaminationField(
            '未治療齲齒',
            't01',
            'ret01',
            values
          )}
          {generateFollowUpExaminationField(
            '口腔衛生不良',
            't04',
            'ret04',
            values
          )}
          {generateFollowUpExaminationField('咬合不正', 't07', 'ret07', values)}
          {generateFollowUpExaminationField('牙結石', 't05', 'ret05', values)}
          {generateFollowUpExaminationField('牙齦炎', 't08', 'ret08', values)}
          {generateFollowUpExaminationField(
            '口腔黏膜異常',
            't09',
            'ret09',
            values
          )}
          {generateFollowUpExaminationField('牙周病', 't16', 'ret16', values)}
          {generateFollowUpExaminationField(
            '乳牙待拔牙',
            't17',
            'ret17',
            values
          )}
          {generateFollowUpExaminationField('待拔牙', 't03', 'ret03', values)}
          {generateFollowUpExaminationField('贅生牙', 't18', 'ret18', values)}
          {generateFollowUpExaminationField('缺牙', 't02', 'ret02', values)}
          {generateFollowUpExaminationField('阻生牙', 't19', 'ret19', values)}
          {generateFollowUpExaminationField('口腔其他', 't99', 'ret99', values)}
          <div className="d-flex align-items-center px-2 my-2">
            <span className="mx-1 rwid-500">複檢口腔其他描述:</span>
            <span>
              <TextField property="reT99state" className="rwid-1400" />
            </span>
          </div>
        </Row>
        <Row noGutters>
          <span className="mx-1 rwid-500">複檢口檢表:</span>
          <span className="rwid-1400">
            <TeeSheetWithButton
              property="reTSheet"
              // specialTooth={specialTee}
              // sheetMap={valueMap}
              // specialMap={specialValueMap}
            />
          </span>
        </Row>
      </>
    ) : (
      <>
        <Row className={rowClassName}>
          {generateCheckedField('實施', 'tee')}
        </Row>
        <Row className={rowClassName}>
          {generateFirstExaminationField('未治療齲齒', 't01', values)}
          {generateSingleExaminationField('已治療齲齒', 't11', values)}
          {generateFirstExaminationField('口腔衛生不良', 't04', values)}
          {generateSingleExaminationField(
            <React.Fragment>
              上顎恆牙
              <br />
              第一大臼齒齲齒經驗
            </React.Fragment>,
            't12',
            values
          )}
          {generateSingleExaminationField(
            <React.Fragment>
              下顎恆牙
              <br />
              第一大臼齒齲齒經驗
            </React.Fragment>,
            't13',
            values
          )}
          {generateSingleExaminationField(
            <React.Fragment>
              恆牙臼齒
              <br />
              窩溝封填
            </React.Fragment>,
            't15',
            values
          )}
          {generateFirstExaminationField('咬合不正', 't07', values)}
          {generateFirstExaminationField('牙結石', 't05', values)}
          {generateFirstExaminationField('牙齦炎', 't08', values)}
          {generateFirstExaminationField('口腔黏膜異常', 't09', values)}
          {generateFirstExaminationField('牙周病', 't16', values)}
          {generateFirstExaminationField('乳牙待拔牙', 't17', values)}
          {generateFirstExaminationField('待拔牙', 't03', values)}
          {generateFirstExaminationField('贅生牙', 't18', values)}
          {generateFirstExaminationField('缺牙', 't02', values)}
          {generateFirstExaminationField('阻生牙', 't19', values)}
          {generateFirstExaminationField('口腔其他', 't99', values)}
          <div className="d-flex align-items-center px-2 my-2">
            <span className="mx-1 rwid-500">口腔其他描述:</span>
            <span>
              <TextField property="t99state" className="rwid-1400" />
            </span>
          </div>
        </Row>
        <Row noGutters>
          <span className="mx-1 rwid-500">口檢表:</span>
          <span className="rwid-1400">
            <TeeSheetWithButton
            // specialTooth={specialTee}
            // sheetMap={valueMap}
            // specialMap={specialValueMap}
            />
          </span>
        </Row>
      </>
    );
  }
  return (
    <>
      <Row className="mb-2">
        <Col
          sm={2}
          as={Button}
          disabled={!followUp}
          onClick={() => setFollowUp(false)}
          className="mr-2"
        >
          初檢
        </Col>
        <Col
          sm={2}
          as={Button}
          disabled={followUp}
          onClick={() => setFollowUp(true)}
        >
          複檢
        </Col>
      </Row>
      <Row>
        <EditableTableDiv
          editable
          deleteable
          scrollable={true}
          headers={followUp ? followUpTeeHeader : firstTeeHeader}
          values={students}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Row>
      <Modal
        show={inputing}
        size="lg"
        aria-labelledby="class-tee-page-modal"
        backdrop="static"
        dialogClassName="modal-dialog-full"
        centered
      >
        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title id="class-tee-page-modal">
            口檢數據 -{' '}
            <strong>
              {editingStudent?.seat} {editingStudent?.name}
            </strong>{' '}
          </Modal.Title>
          <Col
            sm={2}
            as={Button}
            disabled={!followUp}
            onClick={() => setFollowUp(false)}
            className="mx-2"
          >
            初檢
          </Col>
          <Col
            sm={2}
            as={Button}
            disabled={followUp}
            onClick={() => setFollowUp(true)}
          >
            複檢
          </Col>
        </Modal.Header>
        <Form
          initialValues={editingStudent}
          decorators={PHITeeCalculators}
          onSubmit={(value: TeePageData) => {
            const data: PHITee = {
              ...combindFirstAndFollowUpExamination(value),
              id: 0,
              sem,
              year,
              yearClassId: 0,
            };
            onUpdateStudent(data);
          }}
          subscription={{ submitting: true, pristine: true }}
          render={(prop) => {
            const { handleSubmit } = prop;
            return (
              <>
                <Modal.Body className="text-center">
                  <Container>
                    {generateModalContent(prop.form.getState().values)}
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" onClick={handleSubmit}>
                    儲存
                  </Button>
                  <Button type="reset" variant="secondary" onClick={onHide}>
                    關閉
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        />
      </Modal>
    </>
  );
};

function generateFirstExaminationField(
  display: string | ReactElement,
  property: keyof TeePageData,
  allValues: TeePageData
): ReactElement {
  return (
    <div className="d-flex align-items-center px-2 my-2">
      <span className="mx-1 rwid-500">{display}:</span>
      <span className="rwid-375">
        {teeTransformFirstExaminationField(property, undefined, 0, true, {
          context: DefaultTeeContext,
          allValues,
        })}
      </span>
    </div>
  );
}

function generateSingleExaminationField(
  display: string | ReactElement,
  property: keyof TeePageData,
  allValues: TeePageData
): ReactElement {
  return (
    <div className="d-flex align-items-center px-2 my-2">
      <span className="mx-1 rwid-500">{display}:</span>
      <span className="rwid-375">
        {teeTransformSingleExaminationField(property, undefined, 0, true, {
          context: DefaultTeeContext,
          allValues,
        })}
      </span>
    </div>
  );
}

function generateFollowUpExaminationField(
  display: string | ReactElement,
  firstProperty: keyof TeePageData,
  property: keyof TeePageData,
  allValues: TeePageData
): ReactElement {
  return (
    <div className="d-flex align-items-center px-2 my-2">
      <span className="mx-1 rwid-500">{display}:</span>
      <span className="rwid-375">
        {teeTransformFollowUpExaminationField(
          firstProperty,
          property,
          undefined,
          0,
          true,
          {
            context: DefaultTeeContext,
            allValues,
          }
        )}
      </span>
    </div>
  );
}

function generateCheckedField(
  display: string,
  property: keyof TeePageData
): ReactElement {
  return (
    <div className="d-flex align-items-center px-2 my-2">
      <span className="mx-1 rwid-500">{display}:</span>
      <span className="rwid-375">
        <CheckBoxField property={property} />
      </span>
    </div>
  );
}

export const ClassPHITeePage = connector(WrapClassBaseLayout(classPHITeePage));
