import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
  AuthedLayout,
  HeaderDisplayKeysWithType,
  SheetTemplate,
} from '../../../components';
import { useSemGrade } from '../../../hook';
import { Sight, StudentGradeNoSeat } from '../../../model';
import apis from '../../../apis';
import {
  DefaultSightManageMap,
  SIGHT_MANAGE_MAP,
  SightContext,
} from '../../TableHeaders/SightHeader';
import { DateTime } from 'luxon';
import { SheetHeaderDisplay } from '../../../utils';

type PageData = StudentGradeNoSeat & Sight;

function boolRender(v: unknown) {
  return v ? '是' : '否';
}
const manageIDMap: Record<string, string> = {
  '1': '長效散瞳劑\n(阿托平 Atropine)',
  '2': '短效散瞳劑',
  '3': '其他藥物',
  '4': '配鏡矯治',
  '5': '更換鏡片',
  '6': '遮眼治療',
  '7': '配戴隱形眼鏡',
  '8': '角膜塑型片',
  '9': '視力保健衛教',
  '10': '其他',
  '11': '定期檢查',
};

const tableHeader: (SheetHeaderDisplay<PageData> &
  HeaderDisplayKeysWithType<PageData>)[] = [
  { display: '年級', property: 'grade' },
  { display: '班級', property: 'no' },
  { display: '座號', property: 'seat' },
  { display: '學生', property: 'name' },
  {
    display: '性別',
    property: 'sex',
    onRender: (v) => (v === '1' ? '男' : '女'),
  },
  { display: '散瞳治療', property: 'isDilated', onRender: boolRender },
  {
    display: '裸視右',
    property: 'sight0R',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
    onSheetRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    }
  },
  {
    display: '裸視左',
    property: 'sight0L',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
    onSheetRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    }
  },
  {
    display: '戴鏡右',
    property: 'sightR',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
    onSheetRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    }
  },
  {
    display: '戴鏡左',
    property: 'sightL',
    onRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    },
    onSheetRender: (value: unknown) => {
      if (typeof value === 'number') {
        return value % 1 === 0 ? value.toFixed(1) : value.toString();
      }
      return value as string;
    }
  },
  { display: '散瞳', property: 'isDilating', onRender: boolRender },
  { display: '近視右', property: 'eNearR' },
  { display: '近視左', property: 'eNearL' },
  { display: '遠視右', property: 'eFarR' },
  { display: '遠視左', property: 'eFarL' },
  { display: '散光右', property: 'eSanR' },
  { display: '散光左', property: 'eSanL' },
  { display: '近視', property: 'eNear', onRender: boolRender },
  { display: '遠視', property: 'eFar', onRender: boolRender },
  { display: '散光', property: 'eSan', onRender: boolRender },
  { display: '弱視', property: 'eWeak', onRender: boolRender },
  { display: '其他', property: 'eSight99', onRender: boolRender },
  { display: '複檢無異狀', property: 'noProblem', onRender: boolRender },
  { display: '備註', property: 'eSight99State' },
  {
    display: '處置代號',
    property: 'manageID',
    onRender: (v) => (Array.isArray(v) ? v.join(',') : v),
  },
 {
      display: '醫師建議處置',
      property: 'manageID',
      onRender: (v) => {
        if (Array.isArray(v)) {
          return v.map(id => manageIDMap[id] || '').join(', ');
        }
        return '';
      },
      onSheetRender: (v) => {
        if (Array.isArray(v)) {
          return v.map(id => manageIDMap[id] || '').join(', ');
        }
        return '';
      },
    },
  {
    display: '定期檢查', property: 'periodical',
    onRender: (v) => {
      return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
    },
  },
];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const sightSchool: FunctionComponent<Props> = ({
  user,
  catchErrorForModal,
}) => {
  const { yearSem, element } = useSemGrade();
  const [students, setStudents] = useState<PageData[]>([]);
  const { downloadButton, sheetTable } = SheetTemplate({
    title: `${yearSem?.year}學年 第${yearSem?.sem}學期全校視力`,
    values: students,
    headers: tableHeader,
    context: DefaultSightManageMap,
    btnname: '全校視力',
  });

  useEffect(() => {
    if (yearSem) {
      apis
        .getSightSchool(yearSem.year, yearSem.sem)
        .then((r) =>
          setStudents(r.map(({ sight, ...s }) => ({ ...sight, ...s })))
        )
        .catch(catchErrorForModal);
    }
  }, [yearSem?.year, yearSem?.sem]);

  return (
    <AuthedLayout>
      <Row className="justify-content-between">
        <span className="mr-3">{element}</span>
        {downloadButton}
      </Row>
      <hr />
      <Row hidden>{sheetTable}</Row>
    </AuthedLayout>
  );
};

export const SightSchool = connector(sightSchool);
