import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
    InputDropdownGroupField,
    TextField,
} from '../../../components';
import { useClassGrade, useSemGrade } from '../../../hook';
import { PHIWH, StudentGradeNoSeatBirth, WH } from '../../../model';
import apis from '../../../apis';
import { SightContext } from '../../TableHeaders/SightHeader';
import { downloadDataAsExcel, SheetHeaderDisplay } from '../../../utils';
import { whbmiDiag } from '../../../utils/sight';
import { Nullable } from '../../../types';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { string } from 'zod';
import { semesterRange } from '../../../utils/date';
import { I18N } from '../../../i18n-raw';
type PageData = StudentGradeNoSeatBirth & Nullable<PHIWH> & { bmidiag: string };

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '年級', property: 'grade' },
        { display: '班級', property: 'no' },
        { display: '學生', property: 'name' },
        { display: '座號', property: 'seat' },
        { display: '身高', property: 'height' },
        { display: '體重', property: 'weight' },
        {
            display: '測量日',
            property: 'examDate',
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : ('' as string);
            },
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd') : v;
            },
        },
        { display: '體位', property: 'bmidiag' },
        { display: '身高不足標準', property: 'fitHeight' },
        {
            display: 'BMI', property: 'bmi',
            onRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return Math.round(value * 10) / 10; // 四捨五入到小數點後一位
                }
                return value;
            },
            onSheetRender: (value: unknown) => {
                if (typeof value === 'number') {
                    return Math.round(value * 10) / 10; // 四捨五入到小數點後一位
                }
                return value as string;
            },
        },
        {
            display: '實歲',
            property: 'birth',
            onRender: (v) => {
                return calculateAge(v);
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? calculateAge(v) : '';
            },
        },
        {
            display: '診斷',
            property: 'diagId',
            onRender: (v: number) => {
                return I18N.WHBelowGHD[v as keyof typeof I18N.WHBelowGHD] ?? "";
            },
            onSheetRender: (v: string | number | DateTime | null | undefined, idx: number, context: { context: Record<string, unknown>; allValues: PageData }) => {
                if (typeof v === 'number') {
                    return I18N.WHBelowGHD[v as keyof typeof I18N.WHBelowGHD] ?? "";
                }
                return '';
            }
        },
        {
            display: '其他診斷',
            property: 'diag',
        },
        {
            display: '醫院',
            property: 'hospital',
        },

    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const whBelowGHD: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { currentSemester = '', semesters = {} } = user || {};
    const [currentSem, setCurrentSem] = useState(currentSemester);
    const { year = -1, sem = -1 } = semesters[currentSem];
    const { yearSem, element: semGradeElement } = useSemGrade();
    const [students, setStudents] = useState<PageData[]>([]);
    const [inputing, setInputing] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [editingStudent, setEditingStudent] = useState<PageData>();
    useEffect(() => {
        if (yearSem) {
            apis
                .getWHBelowGHD(yearSem.year, yearSem.sem)
                .then((r) =>
                    setStudents(
                        r.map(({ sight, wh, ...s }) => ({
                            ...sight,
                            ...wh,
                            ...s,
                            bmidiag: whbmiDiag(wh?.bmiCode?.toString()),
                        }))
                    )
                )
                .catch(catchErrorForModal);
        }
    }, [yearSem?.year, yearSem?.sem, inputing]);

    function onHide() {
        setInputing(false);
        setEditingStudent(undefined);
    }

    function onEditMetric(metric: PageData) {
        setInputing(true);
        setEditingStudent(metric);
    }

    // function onDelete(metric: PageData) {
    //     toast
    //         .promise(apis.deleteStudentWH(metric.pid, year, sem), {
    //             pending: '資料刪除中......',
    //             success: '資料刪除成功！',
    //             error: '資料刪除失敗！請查看錯誤資訊。',
    //         })
    //         .then(() => setToggle(!toggle))
    //         .catch(catchErrorForModal);
    // }

    async function onUpdateStudentMetric(metric: PHIWH) {
        if (editingStudent && editingStudent.pid) {
            toast
                .promise(apis.InsertStudentBodyMetrix(editingStudent?.pid, metric), {
                    pending: '資料上傳中......',
                    success: '上傳成功！',
                    error: '上傳失敗！請查看錯誤資訊。',
                })
                .then(() => {
                    onHide();
                })
                .catch(catchErrorForModal);
        }
    }


    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        className="text-dark mr-3"
                        disabled={!students.length}
                        onClick={() => {
                            // let pids = students.filter((s) => s.selected).map((s) => s.pid);
                            const pids = students.map((s) => s.pid);

                            if (yearSem) {
                                toast
                                    .promise(
                                        apis.downloadWHBelowGHDPDF(
                                            pids,
                                            yearSem.year,
                                            yearSem.sem,
                                        ),
                                        {
                                            pending: '下載中...',
                                            success: '下載成功！',
                                            error: '下載失敗！請查看錯誤資訊。',
                                        }
                                    )
                                    .then((blob) => {
                                        const objlink = window.URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.href = objlink;
                                        a.setAttribute(
                                            'download',
                                            `${yearSem.year}學年第${yearSem.sem}學期身高不足學童名冊.pdf`
                                        );
                                        document.body.appendChild(a);
                                        a.click();

                                        a.parentNode?.removeChild(a);
                                    })
                                    .catch(catchErrorForModal);
                            }
                        }
                        }

                    >
                        通知單
                    </Button>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_身高不足學童名冊`,
                                values: students,
                                headers: tableHeader,
                                context: SightContext,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    editable
                    // deleteable
                    headers={tableHeader}
                    values={students}
                    context={SightContext}
                    onEdit={onEditMetric}
                    // onDelete={onDelete}
                />
            </Row>
            <Modal
                show={inputing}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                dialogClassName="modal-dialog-full"
                centered
            >
                <Modal.Header closeButton onHide={onHide}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        身高不足學童 -{' '}
                        <strong>
                            {editingStudent?.seat} {editingStudent?.name}
                        </strong>
                    </Modal.Title>
                </Modal.Header>
                <Form
                    initialValues={editingStudent}
                    onSubmit={(value) => {
                        if (yearSem) {
                            const data = {
                                id: 0,
                                sem: yearSem.sem,
                                year: yearSem.year,
                                height: editingStudent?.height,
                                weight: editingStudent?.weight,
                                examDate: editingStudent?.examDate,
                                hospital: value.hospital,
                                diag: value.diag,
                                diagID: value.diagId,
                                yearClassId: editingStudent?.yearClassId ?? 0,
                            };
                            onUpdateStudentMetric(data);
                        }
                    }}
                    subscription={{ submitting: true, pristine: true }}
                    render={(prop) => {
                        const { handleSubmit } = prop;
                        const [fromDate, toDate] = semesterRange(year, sem);
                        return (
                            <React.Fragment>
                                <Modal.Body className="text-center">
                                    <Container>
                                        <Row>
                                            {/* <Col sm="8">
                                                <EditableTableDiv
                                                    values={[editingStudent?.grade, editingStudent?.no] || []}
                                                    headers={tableHeader}
                                                />
                                            </Col> */}
                                            <Col sm="4">
                                                <Row className="pb-3 justify-content-center align-items-center">
                                                    <Col sm="3">診斷代號:</Col>
                                                    <Col sm="8">
                                                        <InputDropdownGroupField
                                                            content={I18N.WHBelowGHD}
                                                            resultClassName="wid-160"
                                                            property="diagId"
                                                            selectShow="value"
                                                            resultShow="value"
                                                            type="number"
                                                            needGroup={false}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="pb-3 justify-content-center align-items-center">
                                                    <Col sm="3">其他診斷：</Col>
                                                    <Col sm="8">
                                                        <TextField
                                                            property="diag" />
                                                    </Col>
                                                </Row>

                                                <Row className="pb-3 justify-content-center align-items-center">
                                                    <Col sm="3">醫院：</Col>
                                                    <Col sm="8">
                                                        <TextField
                                                            property="hospital" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="submit" onClick={handleSubmit}>
                                        儲存
                                    </Button>
                                    <Button type="reset" variant="secondary" onClick={onHide}>
                                        關閉
                                    </Button>
                                </Modal.Footer>
                            </React.Fragment>
                        );
                    }}
                />
            </Modal>
        </AuthedLayout>
    );
};

function calculateAge(birthDate: DateTime): number {
    const birthDateObj = new Date(
        birthDate.year,
        birthDate.month - 1,
        birthDate.day
    );

    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
    ) {
        age--;
    }

    return age;
}

export const WHBelowGHD = connector(whBelowGHD);