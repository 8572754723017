import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../redux/States';
import { ErrorDispatches } from '../../../redux/Dispatches';
import { ConnectedProps, connect } from 'react-redux';
import {
    AuthedLayout,
    EditableTableDiv,
    HeaderDisplayKeysWithType,
} from '../../../components';
import { useInjuryDate, useMonth, useSemGrade } from '../../../hook';
import { InjuryMomentEnum, InjuryPlaceEnum, StudentGradeNoSeat, StudentInjury } from '../../../model';
import apis from '../../../apis';
import { downloadDataAsExcel, serializeInjury, SheetHeaderDisplay } from '../../../utils';
import { DateTime } from 'luxon';
import { I18N } from '../../../i18n-raw';
type PageData = StudentInjury & StudentGradeNoSeat;

const tableHeader: (SheetHeaderDisplay<PageData> &
    HeaderDisplayKeysWithType<PageData>)[] = [
        { display: '年', property: 'grade', style: { width: "7%" } },
        { display: '班', property: 'no', style: { width: "7%" } },
        { display: '座號', property: 'seat', style: { width: "7%" } },
        { display: '姓名', property: 'name', style: { width: "7%" } },
        {
            display: '性別', property: 'sex',
            onRender: (v) => (v === '1' ? '男' : '女'),
            onSheetRender: (v) => (v === '1' ? '男' : '女'), style: { width: "7%" }
        },
        {
            display: '時段',
            property: 'momentEnum',
            onRender: (v) => {
                return typeof v === 'number' ? I18N.InjuryMoment[v as InjuryMomentEnum] : '';
            },
            onSheetRender: (v) => {
                return typeof v === 'number' ? I18N.InjuryMoment[v as InjuryMomentEnum] : '';
            },
        },
        {
            display: '地點',
            property: 'placeEnum',
            onRender: (v) => {
                return typeof v === 'number' ? I18N.InjuryPlace[v as InjuryPlaceEnum] : '';
            },
            onSheetRender: (v) => {
                return typeof v === 'number' ? I18N.InjuryPlace[v as InjuryPlaceEnum] : '';
            },
        },
        {
            display: '日期時間',
            property: 'date',
            onRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : null;
            },
            onSheetRender: (v) => {
                return v instanceof DateTime ? v.toFormat('yyyy/MM/dd hh:mm') : "";
            },
        },
        {
            display: '情形',
            property: 'memos',
            onRender: (v, i, e, c) => {
                const { allValues } = c;
                return serializeInjury(allValues);
            },
        },
    ];

const mapState = (app: ApplicationState) => ({ ...app.auth });
const mapDispatch = ErrorDispatches;

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const injuryAccListSortByDate: FunctionComponent<Props> = ({
    user,
    catchErrorForModal,
}) => {
    const { yearSem, element: semGradeElement } = useSemGrade();
    const { selectedInjuryDate, element: injuryDateelement } = useInjuryDate({
        yearSem,
    });
    const [students, setStudents] = useState<PageData[]>([]);

    useEffect(() => {
        if (yearSem && selectedInjuryDate) {
            apis
                .getInjuryAccListSortByDate(yearSem.year, yearSem.sem, selectedInjuryDate.date)
                .then(r => {
                    setStudents(r.map(({ studentInjury, ...s }) => ({
                        ...studentInjury,
                        ...s
                    })));
                })
                .catch(catchErrorForModal);
        }
    }, [selectedInjuryDate, yearSem?.year, yearSem?.sem]);

    return (
        <AuthedLayout>
            <Row className="justify-content-between">
                <Col>
                    <Row>
                        <Col xs={3} className="mr-3">
                            {semGradeElement}
                        </Col>
                        <Col xs={3} className="mr-3">
                            {injuryDateelement}
                        </Col>
                    </Row>
                </Col>
                <div>
                    <Button
                        disabled={!yearSem}
                        variant="info"
                        className="text-dark mr-3"
                        onClick={() => {
                            if (!yearSem) return;
                            apis
                                .getInjuryAccListSortByDate(yearSem.year, yearSem.sem)
                                .then((r) => {
                                    const students = r.map(({ studentInjury, ...s }) => ({
                                        ...studentInjury,
                                        ...s,
                                    }));
                                    downloadDataAsExcel({
                                        title: `${yearSem?.year}學年${yearSem?.sem}學期_傷病日誌(分日期依時間排序)`,
                                        values: students,
                                        headers: tableHeader,
                                        groupBy: (v) => `${v.date?.year}-${v.date?.month}-${v.date?.day}`,
                                        footer:
                                            '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                                    });
                                })
                                .catch(catchErrorForModal);
                        }}
                    >
                        所有日期 Excel 下載
                    </Button>
                    <Button
                        disabled={!students.length}
                        variant="success"
                        className="text-dark"
                        onClick={() => {
                            downloadDataAsExcel({
                                title: `${yearSem?.year}學年${yearSem?.sem}學期_傷病日誌(分日期依時間排序)`,
                                values: students,
                                headers: tableHeader,
                                groupBy: (v) => `${selectedInjuryDate?.date}`,
                                footer:
                                    '承辦人:　　　　組長:　　　　　主任:　　　　　　　校長:　　　　　　　　',
                            });
                        }}
                    >
                        Excel 下載
                    </Button>
                </div>
            </Row>
            <hr />
            <Row>
                <EditableTableDiv
                    headers={tableHeader}
                    values={students}
                />
            </Row>
        </AuthedLayout>
    );
};

export const InjuryAccListSortByDate = connector(injuryAccListSortByDate);